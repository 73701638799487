import React from 'react'
import HeaderOne from '../Header/HeaderOne'
import BreadcrumbForSEOmarket from '../Breadcrumb/BreadcrumbForSEOmarket'
import ReviewOne from '../Reviews/ReviewOne'
import FooterOne from '../Footer/FooterOne'

const SEOmarket = ({pageName}) => {
  return (
  <>
        <HeaderOne imageData={"/img/aaban-text-logo.png"}  backgroundColor="#8835dc"/>
        <BreadcrumbForSEOmarket pageName={pageName}/>
        <ReviewOne/>
        <FooterOne/>
  </>
  )
}

export default SEOmarket