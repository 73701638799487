import React, { useEffect, useState } from "react";
import jsonData from "../../data/db.json";

const ProcessCards = ({ pageName }) => {
  const [processCards, setProcessCards] = useState([]);

  useEffect(() => {
    if (jsonData[pageName] && jsonData[pageName]["process-cards"]) {
      setProcessCards(jsonData[pageName]["process-cards"]);
    }
  }, [pageName]);

  return (
    <div className="row">
      {processCards.map((card, index) => (
        <div className="col-lg-6 col-md-6 col-sm-12 mb-4" key={index}>
          <div className=" p-3">
            <div className="d-flex align-items-center mb-3">
              <img
                src={`/img/${card.image}`}
                style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
                alt="icon"
              />
              <h5 className="card-title" style={{ fontSize: "18px", margin: 0 }}>
                {card.heading}
              </h5>
            </div>
            <p className="card-text">{card.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProcessCards;
