import React, { useState } from "react";
import App from "../../App.css";

const Banner4 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [profileShowData, setProfileShowData] = useState(true);
  const [paymentShowData, setPaymentShowData] = useState(false);
  const [addressShowData, setAddressShowData] = useState(false);

  const profileToggle = () => {
    setProfileShowData(!profileShowData);
    setPaymentShowData(false);
    setAddressShowData(false);
  };

  const paymentToggle = () => {
    setPaymentShowData(!paymentShowData);
    setProfileShowData(false);
    setAddressShowData(false);
  };

  const addressToggle = () => {
    setAddressShowData(!addressShowData);
    setProfileShowData(false);
    setPaymentShowData(false);
  };
  const styles = {
    profilePopup: {
      backgroundColor: profileShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    paymentPopup: {
      backgroundColor: paymentShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    addressPopup: {
      backgroundColor: addressShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`profilePopup ${profileShowData ? 'active' : ''}`} onClick={profileToggle} style={styles.profilePopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {profileShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Profile Screen</span>
                  </div>
                  {profileShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>A sidebar enables app navigation and provides quick access to top-level collections of content in your app. The term sidebar
                        refers to a list of top-level app areas and collections, almost always displayed in the primary pane of a split view.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`paymentPopup ${paymentShowData ? 'active' : ''}`} onClick={paymentToggle} style={styles.paymentPopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{paymentShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Payment Method</span>
                  </div>
                  {paymentShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>In this screen, customers can add their payment methods with their details for ordering food. It can be either Paypal, Cash On De-livery (COD), etc</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`addressPopup ${addressShowData ? 'active' : ''}`} onClick={addressToggle} style={styles.addressPopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{addressShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Address Bood</span>
                  </div>
                  {addressShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>In address book screen, customers can add & save their address-es for future perspective. After swiping left, customers will get 2 options for edit and delete the address. When customer will click on the Add address, he will go to the below screen</p><br />
                      <p style={{ color: 'black' }}>  Here, he can enter his delivery location & save it as home, work or others. To set delivery location, even users can hold the audio icon & start recording & save their location.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/customImg-10.webp' alt='image' />
                  <img className='col-lg-4' src='/img/customImg-11.webp' alt='image' />
                  <img className='col-lg-4' src='/img/customImg-12.png' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}
      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div
                className="col-lg-6 col-md-12 px-lg-5 px-sm-0"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`profilePopup ${
                        profileShowData ? "active" : ""
                      }`}
                      onClick={profileToggle}
                      style={styles.profilePopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {profileShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Profile Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(profileShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        A sidebar enables app navigation and provides quick
                        access to top-level collections of content in your app.
                        The term sidebar refers to a list of top-level app areas
                        and collections, almost always displayed in the primary
                        pane of a split view.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`paymentPopup ${
                        paymentShowData ? "active" : ""
                      }`}
                      onClick={paymentToggle}
                      style={styles.paymentPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {paymentShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Payment Method
                      </span>
                    </div>
                      <div
                        className="content mt-2 mb-2"
                        style={styles.content(paymentShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          In this screen, customers can add their payment
                          methods with their details for ordering food. It can
                          be either Paypal, Cash On Delivery (COD), etc.
                        </p>
                      </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`addressPopup ${
                        addressShowData ? "active" : ""
                      }`}
                      onClick={addressToggle}
                      style={styles.addressPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {addressShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Address Book
                      </span>
                    </div>
                      <div className="content mt-2 mb-2" 
                      style={styles.content(addressShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          In address book screen, customers can add & save their
                          addresses for future perspective. After swiping left,
                          customers will get 2 options for edit and delete the
                          address. When a customer clicks on the Add address,
                          they will go to the below screen.
                          <br />
                          <p
                            style={{
                              color: "black",
                              fontWeight: "450",
                              fontSize: "1.2rem",
                            }}
                          >
                            Here, they can enter their delivery location & save
                            it as home, work, or others. To set delivery
                            location, users can hold the audio icon & start
                            recording & save their location.
                          </p>
                        </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-10.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-11.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-12.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner4;
