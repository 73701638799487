import React, { useState } from "react";
import App from "../../App.css";

const Banner6 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [homeShowData, setHomeShowData] = useState(true);
  const [accOrderShowData, setAccOrderShowData] = useState(false);
  const [preOrderShowData, setPreOrderShowData] = useState(false);

  const homeToggle = () => {
    setHomeShowData(!homeShowData);
    setAccOrderShowData(false);
    setPreOrderShowData(false);
  };

  const accOrderToggle = () => {
    setAccOrderShowData(!accOrderShowData);
    setHomeShowData(false);
    setPreOrderShowData(false);
  };

  const preOrderToggle = () => {
    setPreOrderShowData(!preOrderShowData);
    setHomeShowData(false);
    setAccOrderShowData(false);
  };
  const styles = {
    homePopup: {
      backgroundColor: homeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    accOrderPopup: {
      backgroundColor: accOrderShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    preOrderPopup: {
      backgroundColor: preOrderShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey'  >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`homePopup ${homeShowData ? 'active' : ''}`} onClick={homeToggle} style={styles.homePopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {homeShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Home Screen</span>
                  </div>
                  {homeShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>This is the main screen around which the entire app revolves. A toggle switch is a digital on/off switch. Toggle switches are best used for changing the state of system functionalities and preferences. Here, when we will on the toggle switch then we will consider online & all types of orders & data will display. When we will off the toggle button then we will consider ofﬂine & noth-ing will display on the screen.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`accOrderPopup ${accOrderShowData ? 'active' : ''}`} onClick={accOrderToggle} style={styles.accOrderPopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{accOrderShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Accpted Orders</span>
                  </div>
                  {accOrderShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>This screen will appear when clicking on accepted orders icon on the main screen. All the accepted orders will list here & 2 options will be given to the merchant.</p>
                        
                      <p style={{ color: 'black' }}>  Prepared: when click on prepared button, a pop-up window will appear & will ask “are you sure you want to prepare this order”. Click on Yes or No. Once click on Yes button then the order will start to prepare.</p>
                      <p style={{ color: 'black' }}>  Cancel: when click on Cancel button, a pop-up window will appear to cancel the order then click on Yes or No button accord-ingly.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`preOrderPopup ${preOrderShowData ? 'active' : ''}`} onClick={preOrderToggle} style={styles.preOrderPopup}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{preOrderShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Prepared Orders</span>
                  </div>
                  {preOrderShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>This screen will appear when clicking on prepared orders icon on the main screen. All the prepared orders will list here & Ready for pickup option will be given to the merchant.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/customImg-16.webp' alt='image' />
                  <img className='col-lg-4' src='/img/customImg-17.webp' alt='image' />
                  <img className='col-lg-4' src='/img/customImg-18.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ****** Changed one *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="m-3 align-items-center"
                      style={{
                        borderBottom: "0.5px solid #D3D3D3",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`homePopup ${homeShowData ? "active" : ""}`}
                        onClick={homeToggle}
                        style={styles.homePopup}
                      >
                        <button
                          style={{
                            backgroundColor: "#318CE7",
                            color: "white",
                            fontSize: 17,
                            height: 30,
                            width: 30,
                            border: 0,
                            margin: 5,
                          }}
                        >
                          {homeShowData ? "-" : "+"}
                        </button>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            marginLeft: "12px",
                          }}
                        >
                          Home Screen
                        </span>
                      </div>
                      <div
                        className="content mt-2 mb-2"
                        style={styles.content(homeShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          This is the main screen around which the entire app
                          revolves. A toggle switch is a digital on/off switch.
                          Toggle switches are best used for changing the state
                          of system functionalities and preferences. Here, when
                          we will on the toggle switch then we will consider
                          online & all types of orders & data will display. When
                          we will off the toggle button then we will consider
                          offline & nothing will display on the screen.
                        </p>
                      </div>
                    </div>
                    <div
                      className="m-3 align-items-center"
                      style={{
                        borderBottom: "0.5px solid #D3D3D3",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`accOrderPopup ${
                          accOrderShowData ? "active" : ""
                        }`}
                        onClick={accOrderToggle}
                        style={styles.accOrderPopup}
                      >
                        <button
                          style={{
                            backgroundColor: "#318CE7",
                            color: "white",
                            fontSize: 17,
                            height: 30,
                            width: 30,
                            border: 0,
                            margin: 5,
                          }}
                        >
                          {accOrderShowData ? "-" : "+"}
                        </button>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            marginLeft: "12px",
                          }}
                        >
                          Accepted Orders
                        </span>
                      </div>
                      <div
                        className="content mt-2 mb-2"
                        style={styles.content(accOrderShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          This screen will appear when clicking on accepted
                          orders icon on the main screen. All the accepted
                          orders will list here & 2 options will be given to the
                          merchant.
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          Prepared: when click on prepared button, a pop-up
                          window will appear & will ask “are you sure you want
                          to prepare this order”. Click on Yes or No. Once click
                          on Yes button then the order will start to prepare.
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          Cancel: when click on Cancel button, a pop-up window
                          will appear to cancel the order then click on Yes or
                          No button accordingly.
                        </p>
                      </div>
                    </div>
                    <div
                      className="m-3 align-items-center"
                      style={{
                        borderBottom: "0.5px solid #D3D3D3",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`preOrderPopup ${
                          preOrderShowData ? "active" : ""
                        }`}
                        onClick={preOrderToggle}
                        style={styles.preOrderPopup}
                      >
                        <button
                          style={{
                            backgroundColor: "#318CE7",
                            color: "white",
                            fontSize: 17,
                            height: 30,
                            width: 30,
                            border: 0,
                            margin: 5,
                          }}
                        >
                          {preOrderShowData ? "-" : "+"}
                        </button>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            marginLeft: "12px",
                          }}
                        >
                          Prepared Orders
                        </span>
                      </div>
                      {preOrderShowData && (
                        <div
                          className="content mt-2 mb-2"
                          style={styles.content(preOrderShowData)}
                        >
                          <p
                            style={{
                              color: "black",
                              fontWeight: "450",
                              fontSize: "1.2rem",
                            }}
                          >
                            This screen will appear when clicking on prepared
                            orders icon on the main screen. All the prepared
                            orders will list here & Ready for pickup option will
                            be given to the merchant.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-16.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-17.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-18.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner6;
