import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneServiceSection";

const ServiceSeven = () => {
    const [serviceData, setServiceData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}`)
            .then(res => {
                const modifiedServiceData = res.data.serviceData.map((item, idx) => ({
                    ...item,
                    customTitle: customTitles[idx],
                    customText: customTexts[idx],
                    customBtnText: customBtnTexts[idx],
                    href: href[idx]
                }));
                setServiceData(modifiedServiceData);
            })
            .catch(err => console.log(err));
    }, []);

    // Locally defined arrays for custom titles, texts, and button texts
    const customTitles = [
        "PPC Services",
        "Youtube SEO Services",
        "Local SEO",
        "Mobile App Development",
        "Website Redesign Service",
        "Linkedin Advertising Services"
    ];
    
    const customTexts = [
        "Aaban Solutions had made it a point to offer some of the finest and reliable PPC services.",
        "Optimize your YouTube channel to rank higher in search results, increase visibility, and grow your audience",
        "Your local presence includes all of the information available to prospective customers online.",
        "Develop bespoke mobile apps to elevate user engagement and boost brand visibility.",
        "Enhance your website's performance, user experience, and visual appeal with our website redesign service.",
        "Utilize the power of Linkedin advertising to reach your target audience, generate leads, and boost conversions."
    ];
    
    const customBtnTexts = [
        "Learn more",
        "Learn more",
        "Learn more",
        "Learn more",
        "Learn more",
        "Learn more"
    ];

    const href = [
        "/digital-marketing/pay-per-click-ppc",
        "/digital-marketing/youtube-seo-services",
        "/digital-marketing/local-seo-services",
        "/digital-marketing/mobile-app-development",
        "/digital-marketing/website-redesign-service",
        "/digital-marketing/linkedin-advertising-services"
    ]

    return (
        <section id="service" className="section service-area bg-grey ptb_150">
            <div className="container" >
                <div className="row">
                    {serviceData.map((item, idx) => {
                        return (
                            <div key={`so_${idx}`} className="col-12 col-md-6 col-lg-4">
                                {/* Single Service */}
                                <div className="single-service text-center p-4">
                                    <span className={item.iconClass} style={{fontSize: '30px', color: 'blue'}} />
                                    <h3 className="my-3">{item.customTitle}</h3>
                                    <p>{item.customText}</p>
                                    <a className="service-btn mt-3" href={item.href}>{item.customBtnText}</a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default ServiceSeven;
