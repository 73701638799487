import React, { useState } from "react";
import App from "../../App.css";

const Banner17 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [chatShowData, setChatShowData] = useState(true);
  const [chatDetailShowData, setChatDetailShowData] = useState(false);
  const [logoutShowData, setLogoutShowData] = useState(false);

  const chatToggle = () => {
    setChatShowData(!chatShowData);
    setChatDetailShowData(false);
    setLogoutShowData(false);
  };

  const detailToggle = () => {
    setChatDetailShowData(!chatDetailShowData);
    setChatShowData(false);
    setLogoutShowData(false);
  };
  const logoutToggle = () => {
    setLogoutShowData(!logoutShowData);
    setChatShowData(false);
    setChatDetailShowData(false);
  };
  const styles = {
    chatPopup: {
      backgroundColor: chatShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    detailPopup: {
      backgroundColor: chatDetailShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    logoutPopup: {
      backgroundColor: logoutShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    })
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5 pb-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-5'>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`chatPopup ${chatShowData ? 'active' : ''}`} onClick={chatToggle} style={styles.chatPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {chatShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Chat</span>
                  </div>
                  {chatShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In package history screen, all the packages will display here whether it is ready for pickup, accepted, payment pending, com-
                        pleted, cancelled, return etc.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`detailPopup ${chatDetailShowData ? 'active' : ''}`} onClick={detailToggle} style={styles.detailPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{chatDetailShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Chat Detail</span>
                  </div>
                  {chatDetailShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Chat refers to the process of communicating, interacting and/or exchanging messages over the Internet. It involves two or more individuals that communicate through a chat-enabled service or software. Customers can ask their query or concern from the admin.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`logoutPopup ${logoutShowData ? 'active' : ''}`} onClick={logoutToggle} style={styles.logoutPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{logoutShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Logout</span>
                  </div>
                  {logoutShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Logging out means ending access to an app or a website. Logging out informs the app or website that the current user wishes to end the login session. Log out is also known as log off, sign off or sign out.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-6' src='/img/realEstateImg-44.webp' alt='image' />
                  <img className='col-lg-6' src='/img/realEstateImg-45.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** changed one ***** */}
      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-4 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-2 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`chatPopup ${chatShowData ? "active" : ""}`}
                      onClick={chatToggle}
                      style={styles.chatPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {chatShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Chat</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(chatShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In package history screen, all the packages will
                          display here whether it is ready for pickup, accepted,
                          payment pending, completed, cancelled, return etc.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-2 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`detailPopup ${
                        chatDetailShowData ? "active" : ""
                      }`}
                      onClick={detailToggle}
                      style={styles.detailPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {chatDetailShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Chat Detail</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(chatDetailShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Chat refers to the process of communicating,
                          interacting and/or exchanging messages over the
                          Internet. It involves two or more individuals that
                          communicate through a chat-enabled service or
                          software. Customers can ask their query or concern
                          from the admin.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-2 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`logoutPopup ${
                        logoutShowData ? "active" : ""
                      }`}
                      onClick={logoutToggle}
                      style={styles.logoutPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {logoutShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Logout</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(logoutShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Logging out means ending access to an app or a
                          website. Logging out informs the app or website that
                          the current user wishes to end the login session. Log
                          out is also known as log off, sign off or sign out.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-44.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-45.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner17;
