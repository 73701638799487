import React, { useState } from 'react'
import ReviewOne from '../Reviews/ReviewOne'
import BreadcrumbFour from '../Breadcrumb/BreadcrumbFour'
import FooterOne from '../Footer/FooterOne'
import HeaderOne from '../Header/HeaderOne'
import Modal from './Modal'; 


const Blog = ({pageName}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    description: '',
    image: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFormData({ ...formData, image: imageFile });
  };

  const handleSubmit = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <HeaderOne imageData="/img/aaban-text-logo.png" />
      <BreadcrumbFour path="Blog" title="Blog" />
      <section className='container my-5'>
        <div className=' text-center'>
          No blog present.
        </div>
        <div className=' text-center'>
          <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(true)}>Add new blog</button>
        </div>
      </section>
      <ReviewOne />
      <FooterOne />

      {/* Modal */}
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <h4 className='text-center'>Add New Blog</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Title</label>
              <input type="text" className="form-control" name="title" value={formData.title} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Author</label>
              <input type="text" className="form-control" name="author" value={formData.author} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea className="form-control" name="description" value={formData.description} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Image</label>
              <input type="file" className="form-control-file" accept="image/*" onChange={handleImageChange} />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </Modal>
      )}
    </>
  )
}

export default Blog