import React, { useEffect, useState } from "react";

const initData = {
  btnText: "View More",
};

const data = [
  {
    id: 1,
    image: "img/Clue_Courses.png",
    title: "Digital Marketing",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 2,
    image: "img/a2z.png",
    title: "App Development",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 3,
    image: "img/halal.png",
    title: "Data Analysis",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 4,
    image: "img/celebrity.png",
    title: "Content Management",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 5,
    image: "img/monster-biryani.png",
    title: "SEO Marketing",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 6,
    image: "img/needskart.png",
    title: "Marketing Strategy",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
  {
    id: 7,
    image: "img/zealpaint.png",
    title: "Data Analysis",
    text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
  },
];

const PortfolioGrid = () => {
  const [initDataState, setInitDataState] = useState({});
  const [dataState, setDataState] = useState([]);

  useEffect(() => {
    setInitDataState(initData);
    setDataState(data);
  }, []);

  return (
    // <section
    //   id="portfolio"
    //   className="portfolio-area bg-gray overflow-hidden ptb_100"
    // >
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       {dataState.map((item, idx) => (
    //         <div key={`pth_${idx}`} className="col-12 col-sm-6 col-lg-4">
    //           <div className="single-case-studies">
    //             <div className="card shadow">
    //               <a href="/#">
    //                 <img src={item.image} alt="" className="card-img-top" />
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //     <div className="row justify-content-center mt-5">
    //       <a href="/#" className="btn btn-bordered ">
    //         {initDataState.btnText}
    //       </a>
    //     </div>
    //   </div>
    // </section>

    // ***** changed one *****

    <section
      id="portfolio"
      className="portfolio-area bg-gray overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row justify-content-center">
          {dataState.map((item, idx) => (
            <div key={`pth_${idx}`} className="col-12 col-sm-6 col-lg-3">
              <div className="single-case-studies">
                <div className="card shadow">
                  <a href="/#">
                    <img src={item.image} alt="" className="card-img-top" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row justify-content-center mt-5">
          <a href="/#" className="btn btn-bordered ">
            {initDataState.btnText}
          </a>
        </div>
      </div>
    </section>
  );
};

export default PortfolioGrid;
