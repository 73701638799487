import React, { useState } from "react";
import App from "../../App.css";

const Banner13 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [loginShowData, setLoginShowData] = useState(true);
  const [homeShowData, setHomeShowData] = useState(false);

  const loginToggle = () => {
    setLoginShowData(!loginShowData);
    setHomeShowData(false);
  };

  const homeToggle = () => {
    setHomeShowData(!homeShowData);
    setLoginShowData(false);
  };
  const styles = {
    loginPopup: {
      backgroundColor: loginShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    homePopup: {
      backgroundColor: homeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <h2>ADMIN MODULE</h2>
          <div className='container  align-items-center justify-content-center d-flex mt-5'>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`loginPopup ${loginShowData ? 'active' : ''}`} onClick={loginToggle} style={styles.loginPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {loginShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Login Screen</span>
                  </div>
                  {loginShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>A login screen is a web page or an entry page to a web panel that requires user identification and authentication, by entering a username and password combination. The login process is the most essential feature for any system/application as it provides access to an entire website/application.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`homePopup ${homeShowData ? 'active' : ''}`} onClick={homeToggle} style={styles.homePopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{homeShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Home Page</span>
                  </div>
                  {homeShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>The dashboard is the front page of the Administration UI. It provides the display of information which is actually more detailed than the regular user would need. In the dashboard, the admin can view total properties, users, messages & careers.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-6' src='/img/realEstateImg-36.webp' alt='image' />
                  <img className='col-lg-6' src='/img/realEstateImg-37.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section
        className="bg-grey d-flex justify-content-center"
      >
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <h2>ADMIN MODULE</h2>
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${loginShowData ? "active" : ""}`}
                      onClick={loginToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {loginShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Login Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(loginShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          A login screen is a web page or an entry page to a web
                          panel that requires user identification and
                          authentication, by entering a username and password
                          combination. The login process is the most essential
                          feature for any system/application as it provides
                          access to an entire website/application.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`homePopup ${homeShowData ? "active" : ""}`}
                      onClick={homeToggle}
                      style={styles.homePopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {homeShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Home Page
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(homeShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          The dashboard is the front page of the Administration
                          UI. It provides the display of information which is
                          actually more detailed than the regular user would
                          need. In the dashboard, the admin can view total
                          properties, users, messages & careers.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-36.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-37.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner13;
