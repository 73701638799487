import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Form = (props) => {

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_1a4w7eh', 'template_m1f2pdj', formRef.current, 'mCgf6UvurMaE03W_M')
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your Query Send Successfully, Thank You For Contacting us!',
          showConfirmButton: true,
        });
        formRef.current.reset();
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.text
        });
      });
  };

  return (
    <>
      <div
        className="col-12 col-lg-6 pt-4 p-lg-4  "
        style={{ border: "3px solid #6610f2" }}
      >
        <div className="contact-box ">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="user_name"
                    placeholder="Name"
                    required="required"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    placeholder="Email"
                    required
                  />
                </div>

              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="col-12 form-style">
                <button
                  type="submit"
                  className="btn btn-bordered active btn-block mt-3"
                >
                  <span className="text-white pr-3">
                    SUBMIT
                  </span>
                </button>
              </div>
            </div>
          </form>
          <p className="form-message" />
        </div>
      </div>
    </>
  )
}

export default Form;