import React from 'react';
import ReviewOne from '../Reviews/ReviewOne';
import data from '../../data/db.json';


const Industries = ({ pageName }) => {
    const { Industries } = data[pageName];
    return (
        <div className='container'>
            <h3 className='text-center' style={{ fontWeight: 800 }}>{Industries?.heading}</h3>
            <div className="d-flex justify-content-center align-items-center text-center">
                <div
                    style={{
                        borderBottom: "5px solid #faa738",
                        width: "5%",
                    }}
                ></div>
            </div>
            <div className='row d-flex justify-content-center align-items-center text-center'>
                {Industries?.data?.map((item, index) => (
                    <div key={index} className=" d-flex ">
                        <div className='card-body industry-card m-3' style={{ width: '10rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <img src={`/img/${item?.image}`} alt="Image" style={{ height: '3rem', width: 'auto' }} />
                            </div>
                            <div className='mt-2'>
                                <h4 style={{ fontWeight: 700, fontFamily: 'Raleway', argin: '0px', fontSize: '1rem', color: '#F9A920' }}>{item?.industry}</h4>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex-column d-flex justify-content-center align-items-center text-center'>
                <div className='p-3 mt-3 badge' style={{ backgroundColor: '#F9A920', borderRadius: '15px' }}>
                    {Industries?.linkButton}
                </div>
            </div>
        </div>
    );
};

export default Industries;
