import React from 'react';


const BreadcrumbForSEOmarket = () => {
    
    
    return (
        <section className="section  d-flex align-items-center" style={{backgroundColor: "black"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className=" text-center">
                            <h2 className="text-white text-uppercase mb-5"></h2>     
                        </div>
                    </div>
                </div>
                </div>
            </section>
        );
    }


export default BreadcrumbForSEOmarket;