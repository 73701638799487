import React, { useState } from "react";
import App from "../../App.css";

const Banner5 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [splashShowData, setSplashShowData] = useState(true);
  const [loginShowData, setLoginShowData] = useState(false);
  const [registrationdShowData, setRegistrationdShowData] = useState(false);

  const splashToggle = () => {
    setSplashShowData(!splashShowData);
    setLoginShowData(false);
    setRegistrationdShowData(false);
  };

  const loginToggle = () => {
    setLoginShowData(!loginShowData);
    setSplashShowData(false);
    setRegistrationdShowData(false);
  };

  const registrationToggle = () => {
    setRegistrationdShowData(!registrationdShowData);
    setSplashShowData(false);
    setLoginShowData(false);
  };
  const styles = {
    splashPopup: {
      backgroundColor: splashShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: loginShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    registrationPopup: {
      backgroundColor: registrationdShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <a
            href="#"
            style={{
              padding: "9px 35px",
              backgroundColor: "#FFA700",
              fontSize: 10,
              alignItems: "center",
            }}
            className="m-5 d-flex"
          >
            <i
              class="fa-arrow-alt-circle-right fas button-icon-left mr-3 "
              aria-hidden="true"
            ></i>
            <h5>GET IN TOUCH</h5>
          </a>

          <h2>MERCHANT MODULE</h2>
          <div className="container  align-items-center justify-content-center d-flex mt-5">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-13.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-14.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-15.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`splashPopup ${splashShowData ? "active" : ""}`}
                    onClick={splashToggle}
                    style={styles.splashPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {splashShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Splash Screen</span>
                  </div>
                  {splashShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        The ﬁrst screen of the application will be the splash
                        screen. It will be visible for 3-4 seconds. Splash
                        screens typically serve to en-hance the look and feel of
                        an application or website, hence they are often visually
                        appealing. They may also have animations, graphics, and
                        sound.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`loginPopup ${loginShowData ? "active" : ""}`}
                    onClick={loginToggle}
                    style={styles.loginPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {loginShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Login Screen</span>
                  </div>
                  {loginShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In login screen, merchant will enter the following
                        information:
                      </p>
                      <p style={{ color: "black" }}> – Email ID</p>
                      <p style={{ color: "black" }}> – Password</p>
                      <p style={{ color: "black" }}>
                        {" "}
                        Then click on Login button.
                      </p>
                      <p style={{ color: "black" }}>
                        {" "}
                        The user’s entered credential -Email address/Username,
                        Pass-word is valid and correct or not. User will not get
                        access to the re-stricted page until he/she entered a
                        valid email and password.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`registrationPopup ${
                      registrationdShowData ? "active" : ""
                    }`}
                    onClick={registrationToggle}
                    style={styles.registrationPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {registrationdShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      Registration Screen
                    </span>
                  </div>
                  {registrationdShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In registration screen, the new merchant will enter his
                        information like
                      </p>
                      <br />
                      <p style={{ color: "black" }}>
                        {" "}
                        1. Basic info: This section will include the following
                        details
                      </p>
                      <br />

                      <p style={{ color: "black" }}>
                        {" "}
                        a. Owner name b. Email ID c. Contact number with country
                        code d. Date of birth e. Password f. Conﬁrm pass word g.
                        Upload
                      </p>
                      <br />

                      <p style={{ color: "black" }}>
                        {" "}
                        company or veriﬁcation documents (if necessary)
                      </p>
                      <br />

                      <p style={{ color: "black" }}>
                        {" "}
                        2. Restaurant info: This section will include the
                        following detail
                      </p>
                      <br />

                      <p style={{ color: "black" }}>
                        {" "}
                        a. Restaurant name b. Restaurant location c. Select
                        cuisines (drop-down) e. Select payment method d. Minimum
                        order
                      </p>
                      <br />

                      <p style={{ color: "black" }}>
                        {" "}
                        3. Services date & time: In this section, the merchant
                        will select his restaurant service days & opening &
                        closing hours
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}
      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <a
            href="#"
            style={{
              padding: "9px 35px",
              backgroundColor: "#FFA700",
              fontSize: 10,
              alignItems: "center",
            }}
            className="m-5 d-flex"
          >
            <i
              className="fa-arrow-alt-circle-right fas button-icon-left mr-3"
              aria-hidden="true"
            ></i>
            <h5>GET IN TOUCH</h5>
          </a>

          <h2 style={{ textAlign: "center" }}>MERCHANT MODULE</h2>
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-6 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-13.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-14.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-15.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 px-lg-5 px-sm-0"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`splashPopup ${
                        splashShowData ? "active" : ""
                      }`}
                      onClick={splashToggle}
                      style={styles.splashPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {splashShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Splash Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(splashShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        The ﬁrst screen of the application will be the splash
                        screen. It will be visible for 3-4 seconds. Splash
                        screens typically serve to en-hance the look and feel of
                        an application or website, hence they are often visually
                        appealing. They may also have animations, graphics, and
                        sound.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${loginShowData ? "active" : ""}`}
                      onClick={loginToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {loginShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Login Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(loginShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In login screen, merchant will enter the following
                        information:
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        {" "}
                        – Email ID
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        {" "}
                        – Password
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        Then click on Login button.
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        The user’s entered credential -Email address/Username,
                        Pass-word is valid and correct or not. User will not get
                        access to the re-stricted page until he/she entered a
                        valid email and password.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`registrationPopup ${
                        registrationdShowData ? "active" : ""
                      }`}
                      onClick={registrationToggle}
                      style={styles.registrationPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {registrationdShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Registration Screen
                      </span>
                    </div>
                      <div
                        className="content mt-2 mb-2"
                        style={styles.content(registrationdShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          In registration screen, the new merchant will enter
                          his information like
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          1. Basic info: This section will include the following
                          details
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          a. Owner name b. Email ID c. Contact number with
                          country code d. Date of birth e. Password f. Conﬁrm
                          pass word g. Upload
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          company or veriﬁcation documents (if necessary)
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          2. Restaurant info: This section will include the
                          following detail
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          a. Restaurant name b. Restaurant location c. Select
                          cuisines (drop-down) e. Select payment method d.
                          Minimum order
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          3. Services date & time: In this section, the merchant
                          will select his restaurant service days & opening &
                          closing hours
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner5;
