import React, { useState, useEffect } from "react";
import { FaHandPointUp, FaCog, FaHotel, FaChartBar } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";

const ServiceCard = ({ icon, title, description, href }) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes iconHoverAnimation {
        0% { transform: translateX(0% ); }
        50%{transform: translateX(100%); 
        }
        65%{transform: translateX(-100%);
           opacity:0
        }
        100% { transform: translateX(0%);
           opacity:1
          
        }
      }
    `;
    document.head.appendChild(style);
  }, []);

  const iconWithAnimation = React.cloneElement(icon, {
    style: {
      animation: hover ? "iconHoverAnimation 0.3s forwards" : "none",
    },
  });

  return (
    <div className="col-md-6 col-lg-3 mt-4 d-flex justify-content-center  ">
      <div className="card  " style={{ height: "22rem", width: "20rem" }}>
        <div className="card-body d-flex flex-column justify-content-center align-items-center shadow bg-body rounded px-2">
          {icon && (
            <div
              className="mt-4"
              style={{ height: "1.5rem" }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="icon">{iconWithAnimation}</span>
            </div>
          )}
          <Link
            to={href}
            className="text-black my-3 pt-2"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
              color: hover ? "#ffa737" : "black",
              transition: "color 0.2s",
            }}
          >
            <strong>{title}</strong>
          </Link>
          <p className="mb-3" style={{ fontWeight: "600" }}>
            {description}
          </p>
          <div
            className="d-flex align-items-center justify-content-center text-center"
            style={{ color: "#ffa737", cursor: "pointer" }}
          >
            <Link
              to={href}
              className="m-0"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              style={{
                color: "#ffa737",
              }}
            >
              <strong>Know More</strong>
            </Link>
            <Link to={href}>
              <MdArrowForwardIos />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotchServicesCards = () => {
  const services = [
    {
      icon: <FaHandPointUp size={50} color="#faa738" />,
      title: "PPC Services",
      description:
        "Aaban Solutions had made it a point to offer some of the finest and reliable PPC services.",
      href: "/digital-marketing/pay-per-click-ppc",
    },
    {
      icon: <FaCog size={50} color="#faa738" />,
      title: "Youtube SEO Services",
      description:
        "Optimize your YouTube channel to rank higher in search results, increase visibility, and grow your audience",
      href: "/digital-marketing/youtube-seo-services",
    },
    {
      icon: <FaHotel size={50} color="#faa738" />,
      title: "Local SEO",
      description:
        "Your local presence includes all of the information available to prospective customers online.",
      href: "/digital-marketing/local-seo-services",
    },
    {
      icon: <FaChartBar size={50} color="#faa738" />,
      title: "Mobile App Development",
      description:
        "Create custom mobile applications tailored to your business needs, enhancing user engagement and brand visibility.",
      href: "/digital-marketing/mobile-app-development",
    },
  ];

  return (
    <div className="row services-card-style ">
      {services.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
    </div>
  );
};

export default NotchServicesCards;
