import React from "react";
import HeaderOne from "../Header/HeaderOne";
import BreadcrumbThree from "../Breadcrumb/BreadcrumbThree";
import FooterOne from "../Footer/FooterOne";

const TermsOfService = () => {
  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbThree
        path={"TERMS OF SERVICE"}
        title={"TERMS OF SERVICE"}
        image={"/img/pexels-pixabay-258160.jpg"}
      />

      <div style={{ borderBottom: "1px solid grey" }}>
        <section className="container my-5">
          <div className="my-2">
            <h3 className="mt-4">Terms Of Service</h3>
            <p
              className="mt-3"
              style={{
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: 600,
                color: "black",
              }}
            >
              No Chargeback or Dispute would be entitled for the service taken.
              Aaban Digital Solutions Private Limited is not liable to pay for
              any chargeback by customers card or issuer bank
            </p>
            <p className="mt-3">
              Your access to Sky Walk Digital Solution is subject exclusively to
              below-written terms and conditions. You won’t use the website for
              any purpose that is unlawful or prohibited by these terms &
              conditions.
            </p>
            <p className="mt-3">
              Using the website, you’re completely accepting the terms,
              conditions, and disclaimers in this notice. If you don’t adhere to
              these terms and conditions, you must immediately stop using the
              website.
            </p>
            <p className="mt-3">
              Using this website, you agree to be bound by all of the statements
              set forth along with the terms stated in “Terms of Service” and
              “Privacy Policy.”
            </p>
            <p className="mt-3">
              As and when used, the term “You” applies to both an individual and
              any organization, accessing websites to avail services rendered
              through this website.
            </p>
          </div>

          <div className="my-2">
            <h3 className="mt-4">Payment Terms</h3>
            <p className="mt-3">
              Subject to special terms agreed in writing/ published on the
              company’s website, the company shall invoice the client for the
              net sum due to the services.
            </p>
            <p className="mt-3">
              The time of payment of the price shall be of the essence of the
              contract. Payment receipts will be issued as soon as the payment
              will be received.
            </p>
            <p className="mt-3">
              For any business loss or damage sustained by the client whether
              caused by the negligence of the company, employees or otherwise
              which arise out of or in connection with the services rendered
              shall be compensated.
            </p>
            <p className="mt-3">
              The client shall ensure that, except to the extent that
              instructions as to the use of the are carried out according to the
              directions given by the company or any component governmental or
              regulatory authority.
            </p>
          </div>

          <div className="my-2">
            <h3 className="mt-4">Return and Refund Policy</h3>
            <p className="mt-3">
              If, for any reason, You are not completely satisfied with a
              purchase We invite You to review our policy on refunds and
              returns.
            </p>
            <p className="mt-3">
              The following terms are applicable for any products that You
              purchased with Us.
            </p>
          </div>

          <div className="my-2">
            <h3 className="mt-4">Your Order Cancellation Rights</h3>
            <p className="mt-3">
              You are entitled to cancel Your Order within 7 days without giving
              any reason for doing so.
            </p>
            <p className="mt-3">
              The deadline for cancelling an Order is 7 days from the date on
              which You received the Goods or on which a third party you have
              appointed, who is not the carrier, takes possession of the product
              delivered.
            </p>
            <p className="mt-3">
              In order to exercise Your right of cancellation, You must inform
              Us of your decision by means of a clear statement. You can inform
              us of your decision by:
            </p>
            <p className="mt-3">
              By email: <a> info@aabansolutions.com</a>
            </p>
            <p className="mt-3">
              We will reimburse You no later than 14 days from the day on which
              We receive the returned Goods. We will use the same means of
              payment as You used for the Order, and You will not incur any fees
              for such reimbursement.
            </p>
          </div>

          <div className='my-2'>
            <h3 className='mt-4'>Cancellation & Refund Policy</h3>
            <p className='mt-3' style={{ fontSize: "20px", lineHeight: "32px", fontWeight: 600, color: "black" }}>No Chargeback or Dispute would be entitled for the service taken. Aaban Solution Digital Private Limited is not liable to pay for any chargeback by customers card or issuer bank</p>
            <p className='mt-3'>n line with providing our customers with the best quality and client experience, we have established a considerate cancellation & refund policy.</p>
            <p className='mt-3'>We plan each project by dividing work into milestones.</p>
            <p className='mt-3'>All projects begin with a complete discovery/analysis and creation of a scope of work document. This is done to make sure that both parties have complete understanding of the work required and thus eliminate the potential for project cancellations, reversals, or disputes.</p>
            <p className='mt-3'>As there is time spent to achieve every milestone and complete every module, a refund is not be possible for work already completed.</p>
            <p className='mt-3'>In the event of a project terminated on a mutual basis, the client has control of all completed work and any payment for further development will become void. No previous payment or deposit would be refunded.</p>
            <p className='mt-3'>No payment will be refunded once the client approves mock-up designs and we move into the development phase of the project.</p>
            <p className='mt-3'>There are no partial refunds for projects mid way through a milestone phase.</p>
            <p className='mt-3'>No cancellations are entertained for services that our marketing team has offered on special occasions. These are limited time offers and cannot be cancelled.</p>
            <p className='mt-3'>SEO and digital marketing packages are not refundable, but the client may cancel with a notice written 30 days in advance.</p>
            <p className='mt-3'>We do not offer refunds on deposits or payments for projects that are abandoned or lay dormant for more than 30 days.</p>
          </div>


          <div className="my-2">
            <h3 className="mt-4">Contact Us</h3>
            <p className="mt-3">
              If you have any questions about our Returns and Refunds Policy,
              please contact us: By email:
              <strong> info@aabansolutions.com</strong>
            </p>
          </div>
        </section>
      </div>
      <FooterOne />
    </>
  );
};

export default TermsOfService;
