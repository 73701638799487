import React, { useEffect, useState } from 'react';
import jsonData from "../../data/db.json";

const QuestionSection = ({ pageName }) => {
    const [qaPairs, setQAPairs] = useState([]);

    useEffect(() => {
        if (jsonData[pageName] && jsonData[pageName]["question&answer"]) {
            setQAPairs(jsonData[pageName]["question&answer"]);
        }
    }, [pageName]);

    return (
        <div className='container text-center mt-3 mb-3'>
            {qaPairs.map((pair, index) => (
                <div className='mb-5' key={index}>
                    <h3>{pair.question}</h3>
                    <div className='d-flex justify-content-center my-3'>
                        <hr style={{ border: "2px solid #faa738", width: "10%" }} />
                    </div>
                    <p>{pair.answer}</p>
                </div>
            ))}
        </div>
    );
};

export default QuestionSection;
