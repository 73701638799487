import React from "react";
import themeOneContentSection from "../../data/db.json";
import {
  FaBusinessTime,
  FaBullhorn,
  FaProductHunt,
  FaHeadset,
} from "react-icons/fa";

const ContentOne = () => {
  const data = themeOneContentSection;
  const contentData = data?.themeOneContentSection;

  return (
    <section
      className="section content-area bg-grey"
      style={{
        // marginTop: 10,
        position: "relative",
        // background: "linear-gradient(to right, #09599e, #78d6e9)",
        backgroundColor: "rgb(5, 22, 67)",
      }}
    >
      {/* Shape Top */}
      <div className="shape shape-top">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          fill="#FFFFFF"
        >
          <path
            className="shape-fill"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          />
        </svg> */}

        {/* ****** chnaged one ****** */}

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          fill="#FFF"
          style={{ width: "100%", height: "100px" }}
        >
          <path
            className="shape-fill"
            d="M0,1 Q150,80 300,30 T600,30 T900,30 Q950,10 1000,1 L1000,0 L0,0 Z"
          />
        </svg> */}
      </div>
      <div className="container" style={{ position: "relative" }}>
        <div className="row justify-content-between">
          <div
            className="col-12 col-lg-6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="content-inner text-center">
              <div
                className="section-heading text-center"
                style={{ marginBottom: "-3rem", color: "white" }}
              >
                <div className="text-center">
                  <h2 className="mt-5 text-white">{contentData.heading}</h2>
                  <p style={{ color: "white" }}>
                    Welcome to our website. We specialize in providing solutions
                    that help businesses thrive and succeed. Whether you're a
                    small startup or a large corporation, we have the expertise
                    and tools to support your growth journey.
                  </p>
                  {/* <p
                    className="mt-4 text-white"
                    style={{
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Our services include:
                  </p>
                  <ul
                    className="mt-2"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                    }}
                  >
                    <li>• Business consulting</li>
                    <li>• Marketing strategies</li>
                    <li>• Product development</li>
                    <li>• Customer support</li>
                  </ul> */}
                  <div
                    style={{
                      marginTop: "10px",
                      // backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "10px",
                      // boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      maxWidth: "700px",
                      margin: "auto",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#fff",
                        textAlign: "center",
                        marginBottom: "5px",
                        // paddingBottom: "15px",
                      }}
                    >
                      Our Services Include:
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          width: "50%",
                          // height: "2px",
                          border: "1px solid white",
                          textAlign: "center",
                          marginBottom: "1rem",
                        }}
                      ></p>
                    </div>
                    <ul
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "500",
                        color: "#34495e",
                        lineHeight: "1.8",
                        paddingLeft: "0",
                        listStyleType: "none",
                      }}
                    >
                      <li
                        style={{
                          marginBottom: "10px",
                          padding: "5px",
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaBusinessTime
                          style={{ marginRight: "10px", color: "#2c3e50" }}
                        />
                        <span>Business Consulting</span>
                      </li>
                      <li
                        style={{
                          marginBottom: "10px",
                          padding: "5px",
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaBullhorn
                          style={{ marginRight: "10px", color: "#2c3e50" }}
                        />
                        <span>Marketing Strategies</span>
                      </li>
                      <li
                        style={{
                          marginBottom: "10px",
                          padding: "5px",
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaProductHunt
                          style={{ marginRight: "10px", color: "#2c3e50" }}
                        />
                        <span>Product Development</span>
                      </li>
                      <li
                        style={{
                          marginBottom: "10px",
                          padding: "5px",
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaHeadset
                          style={{ marginRight: "10px", color: "#2c3e50" }}
                        />
                        <span>Customer Support</span>
                      </li>
                    </ul>
                  </div>
                  <p className="mt-3 text-white">
                    Contact us today to learn more about how we can help your
                    business reach its full potential.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="service-thumb mx-auto pt-4 pt-lg-0">
              <img
                src={data.contentThumb}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div
              className="service-thumb mx-auto pt-4 pt-lg-0"
              style={{ marginTop: "90px", paddingBottom: "30px" }}
            >
              <img src="img/right-cornerimage.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="shape shape-bottom">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          fill="#FFFFFF"
        >
          <path
            className="shape-fill"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          />
        </svg> */}

        {/* ****  chnage done ********* */}

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          fill="#FFF"
        >
          <path
            className="shape-fill"
            // d="M0,1 Q150, 300,30 T600,30 T900,30 Q950,10 1000,1 L1000,0 L0,0 Z"   // straight one without waves
            // d="M0,1 Q150,80 300,30 T600,30 T900,30 Q950,10 1000,1 L1000,0 L0,0 Z"
            d="M0,1 Q150,80 300,30 T600,30 T900,30 Q950,10 1000,1 L1000,0 L0,0 Z"
          />
        </svg> */}
      </div>
    </section>
  );
};

export default ContentOne;
