import React from 'react';
import data from '../../data/db.json';

const HorBussinessWork = ({ pageName }) => {
    const { HowBussinessWork, list } = data[pageName];

    return (
        <>
            <section className="bg-grey my-5">
                <div className="container  py-4 blackColor">
                    {HowBussinessWork?.map((item, key) => {
                        return (
                            <>
                                <h4  style={{fontWeight:800}}>{item?.heading}</h4>
                                <p className="my-3">
                                    {item?.desc1}
                                </p>
                                <div style={{marginLeft:'3%'}}>
                                <ul>
                                    {item?.list?.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                </div>
                                <p className="my-3">{item?.desc2}</p>
                            </>
                        )
                    })}
                </div>
            </section>
        </>
    );
};

export default HorBussinessWork;
