import React from "react";
import HeaderOne from "../components/Header/HeaderOne";
import ReviewOne from "../components/Reviews/ReviewOne";
import PortfolioGrid from "../components/PortfolioGrid/PortfolioGrid";
import FooterOne from "../components/Footer/FooterOne";
import BreadcrumbThree from "../components/Breadcrumb/BreadcrumbThree";

const Portfolio = () => {
  return (
    <div>
      <div className="main overflow-hidden">
        <HeaderOne imageData={"/img/aaban-text-logo.png"} />
        <BreadcrumbThree
          heading={"Our Portfolio"}
          path={"Portfolio"}
          title={"Portfolio Grid"}
          image={"/img/aboutUs-wall.jpg"}
        />
        <PortfolioGrid />
        <ReviewOne />
        <FooterOne />
      </div>
    </div>
  );
};

export default Portfolio;
