import React from "react";
import Map from "./Map";
import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhone,
  FaPhoneAlt,
} from "react-icons/fa";

const MapSection = () => {
  return (
    <>
      {/* <div className="container justify-content-around d-flex">
                <div className="d-flex mr-5">
                  <Map />
                </div>
                <div className="d-flex my-5 align-items-center">
                  <div className="d-flex flex-column align-items-center mb-3">
                    <FaMapMarkerAlt size={35} />
                    <p className="ml-2">1747 W Pratt Blvd, 2 Chicago, IL 60626, United States</p>
                  </div>
                  <div className="d-flex flex-column mx-4 align-items-center mb-3">
                    <FaPhone size={35} />
                    <p className="ml-2">+(1) (312)-598-5838</p>
                  </div>
                  <div className="d-flex flex-column align-items-center mb-3">
                    <FaEnvelope size={35} />
                    <p className="ml-2">info@aabansolutions.com</p>
                  </div>
                </div>
              </div> */}

      <div className="container justify-content-around d-flex row">
        <div className=" d-flex align-item-center justify-content-center  p-0 col-lg-5">
          <iframe
            title="Google Map"
            width="500"
            height="250"
            frameBorder="0"
            style={{ border: 0 }}
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.681818793766!2d77.08056131544699!3d28.498462682490537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e5a7d2c1235%3A0x3c55e8553a4955c6!2sPlot%20No.%20249%2C%20M%2CUdyog%20Vihar%20Phase%20IV%2C%20Gurugram%2C%20Haryana%20122016%2C%20India!5e0!3m2!1sen!2sbd!4v1646610377889!5m2!1sen!2sbd"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.776689817865!2d-87.67745922351112!3d42.00506785745452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd1b9d707f42d%3A0x93a597e7aaca075e!2s1747%20W%20Pratt%20Blvd%2C%20Chicago%2C%20IL%2060626%2C%20USA!5e0!3m2!1sen!2sbd!4v1716439520362!5m2!1sen!2sbd"
            allowFullScreen
          ></iframe>
        </div>
        <div className="container d-flex my-0 align-items-center justify-content-center row col-lg-7 p-0">
          <div className="d-flex flex-column align-items-center mt-2  col-lg-5 col-sm-12">
            <FaMapMarkerAlt size={35} />

            <a
              href="https://www.google.com/maps/place/1519+W+Warren+Blvd,+Chicago,+IL+60607,+USA/@41.882128,-87.666125,15z/data=!4m6!3m5!1s0x880e2d248df4328b:0xf79be3888998fc0b!8m2!3d41.8821278!4d-87.6661248!16s%2Fg%2F11c256jm0w?hl=en&entry=ttu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="pt-1" style={{ color: "#35b2fe" }}>
                1519 W Warren Blvd, Chicago, IL 60607, USA
              </p>
            </a>
          </div>
          <div className="d-flex flex-column mx-4 align-items-center mb-4 col-lg-3 col-sm-12">
            <FaPhoneAlt size={35} />
            <a href="tel:+13125985838">
              <p
                className="ml-0"
                style={{ color: "#35b2fe" }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#8830df";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "#35b2fe";
                }}
              >
                +(1)312-598-5838
              </p>
            </a>
          </div>
          <div className="d-flex flex-column align-items-center mb-4  col-lg-3 col-sm-12 ">
            <FaEnvelope size={35} />
            <a
              href="mailto:info@aabansolutions.com"
              style={{
                textAlign: "center",
                textDecoration: "none",
                color: "#35b2fe",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#8830df";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#35b2fe";
              }}
            >
              info@aabansolutions.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapSection;
