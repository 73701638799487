import React, { useState } from "react";
import data from "../../data/db.json";

const OurReach = ({ pageName }) => {
  const { OurReach, list } = data[pageName];
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <>
      {/* <section className="bg-grey py-5" >
                <div className="text-center">
                    <h3>{OurReach?.heading}</h3>

                    <div className="d-flex justify-content-center align-items-center text-center ">
                        <div
                            style={{
                                borderBottom: "3px solid #faa738",
                                width: "5%",
                                height: "10px",
                            }}
                        ></div>
                    </div>
                    <p>
                        <strong>
                            {OurReach?.desc}
                        </strong>
                    </p>
                </div>
                <div class="container mb-3">
                    <div class="row">
                        {OurReach?.cardData?.map((item, index) => {
                            return (
                                <>
                                    <div class="col-md-4 mb-4" >
                                        <div class="card">
                                            <img
                                                src={`/img/${item?.image}`}
                                                class="card-img-top"
                                                alt="Image 1"
                                            />
                                            <div class="card-body d-flex justify-content-center align-items-center">
                                                <p style={{ fontWeight: "700" }}>{item?.detail}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section> */}

      {/* ***** changed one *****  */}
      <section className="bg-grey py-5">
        <div className="text-center">
          <h3>{OurReach?.heading}</h3>
          <div className="d-flex justify-content-center align-items-center text-center">
            <div
              style={{
                borderBottom: "3px solid #faa738",
                width: "5%",
                height: "10px",
              }}
            ></div>
          </div>
          <p style={{ fontSize: "1.3rem" }}>
            <strong>{OurReach?.desc}</strong>
          </p>
        </div>
        <div className="container mb-3">
          <div className="row">
            {OurReach?.cardData?.map((item, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div className="card" style={{ overflow: "hidden" }}>
                  <img
                    src={`/img/${item?.image}`}
                    className="card-img-top"
                    alt={` ${index + 1}`}
                    style={{
                      transition: "transform 0.3s ease",
                      transform:
                        hoverIndex === index ? "scale(1.1)" : "scale(1)",
                    }}
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                  />
                  <div className="card-body d-flex justify-content-center align-items-center">
                    <p style={{ fontWeight: 700 }}>{item?.detail}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurReach;
