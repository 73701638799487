import React, { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa6";
import HeaderOne from "../components/Header/HeaderOne";
import BreadcrumbThree from "../components/Breadcrumb/BreadcrumbThree";
import Youtube from "../VideoComponent/Youtube";
import FooterOne from "../components/Footer/FooterOne";

const clientReviews = [
  {
    id: 1,
    name: "Carry",
    review:
      "I would really like to say a special thank you to Aaban Solutions for handling 8 different sites we currently have running, and every site is ranking top 3 organically.",
    image: "/img/pexels-pixabay-415829.jpg",
  },
  {
    id: 2,
    name: "John",
    review:
      "Aaban Solutions has exceeded my expectations. They provided excellent service and helped me achieve significant results in a short amount of time.",
    image: "/img/pexels-andrew-dick-697509.jpg",
  },
  {
    id: 3,
    name: "Emma",
    review:
      "Working with Aaban Solutions has been a pleasure. Their expertise and dedication to our project have made a significant impact on our business.",
    image: "/img/pexels-marc-lloyd-1462980.jpg",
  },
];

const Testimonial = () => {
  const [youtubelink, setYoutubelink] = useState("");

  const handleVideoClick = (link) => {
    setYoutubelink(link);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % clientReviews.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <>
      <div>
        <div className="main overflow-hidden">
          <HeaderOne imageData={"/img/aaban-text-logo.png"} />
          <BreadcrumbThree
            path={"Testimonial"}
            title={"Testimonial"}
            desc={"What our client is saying about us!!"}
            image={"/img/pexels-andrea-piacquadio-840996.jpg"}
          />

          <section className="container my-4">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <h2 className="h2-heading-style">What Our Clients Say</h2>
              <img
                src={clientReviews[currentIndex].image}
                alt="Profile image"
                style={{
                  maxWidth: "4rem",
                  maxHeight: "4rem",
                  borderRadius: "50%",
                }}
              />
              <p>{clientReviews[currentIndex].review}</p>
              <p>
                <strong>{clientReviews[currentIndex].name}</strong>
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center text-center ">
              <div className="dot-container">
                {clientReviews.map((review, index) => (
                  <span
                    key={review.id}
                    className={`dot ${currentIndex === index ? "active" : ""}`}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div>
            </div>
          </section>

          <div className="text-center">
            {youtubelink ? (
              <Youtube youtubeLink={youtubelink} width="560" height="315" />
            ) : (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/QsY8fnvMn6c"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-sm-6 col-lg-8">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 sm-video-grid youtube-grid-style">
                  {/* Iterate over videos */}
                  {[...Array(6)].map((_, idx) => (
                    <div className="col mb-4" key={idx}>
                      <div
                        className="d-flex flex-column align-items-center justify-content-center video-card"
                        onClick={() =>
                          handleVideoClick(
                            "https://www.youtube.com/embed/tgbNymZ7vqY"
                          )
                        }
                      >
                        <div
                          className="d-flex flex-column align-items-center justify-content-center video-card"
                          onClick={() =>
                            handleVideoClick(
                              "https://www.youtube.com/embed/tgbNymZ7vqY"
                            )
                          }
                        >
                          <img
                            src="/img/pexels-pixabay-267350.jpg"
                            alt="Thumbnail"
                            className="video-thumbnail"
                          />
                          <p className="p-2 text-center video-title">
                            Lorem ipsum dolor, sit amet consectetur
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </div>
      </div>
    </>
  );
};

export default Testimonial;
