import React, { useState } from "react";
import App from "../../App.css";

const Banner7 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [contactShowData, setContactShowData] = useState(true);
  const [sideMenuShowData, setSideMenuShowData] = useState(false);
  const [messageShowData, setMessageShowData] = useState(false);

  const ContactToggle = () => {
    setContactShowData(!contactShowData);
    setSideMenuShowData(false);
    setMessageShowData(false);
  };

  const sideMenuToggle = () => {
    setSideMenuShowData(!sideMenuShowData);
    setContactShowData(false);
    setMessageShowData(false);
  };

  const messageToggle = () => {
    setMessageShowData(!messageShowData);
    setContactShowData(false);
    setSideMenuShowData(false);
  };
  const styles = {
    contactPopup: {
      backgroundColor: contactShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    sideMenPopup: {
      backgroundColor: sideMenuShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    messagePopup: {
      backgroundColor: messageShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey'>
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-19.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-20.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-21.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`contactPopup ${contactShowData ? 'active' : ''}`} onClick={ContactToggle} style={styles.contactPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {contactShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Contact Us</span>
                  </div>
                  {contactShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Contact us page is often the go-to for a new visitor on a mission. It’s where they go when they have a question and truly want to speak to an individual at your organization. They exist to serve the user with the purpose of providing them with information on how they can get in touch with you.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`sideMenPopup ${sideMenuShowData ? 'active' : ''}`} onClick={sideMenuToggle} style={styles.sideMenPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{sideMenuShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Side Menu Bar</span>
                  </div>
                  {sideMenuShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Sidebar menus have been used as a directory for Related Pages to a Service offering, navigation items to a speciﬁc service or topic and even just as Links the visitor may be interested in.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`messagePopup ${messageShowData ? 'active' : ''}`} onClick={messageToggle} style={styles.messagePopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{messageShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Message</span>
                  </div>
                  {messageShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Messaging allows you to communicate with the admin through chat. It enables you to send and receive messages. Chatting apps make it easier, simpler and faster to connect with everyone and it is also easy to use.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="  align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-19.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-20.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-21.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`contactPopup ${
                        contactShowData ? "active" : ""
                      }`}
                      onClick={ContactToggle}
                      style={styles.contactPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {contactShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Contact Us</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(contactShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Contact us page is often the go-to for a new visitor
                          on a mission. It’s where they go when they have a
                          question and truly want to speak to an individual at
                          your organization. They exist to serve the user with
                          the purpose of providing them with information on how
                          they can get in touch with you.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`sideMenPopup ${
                        sideMenuShowData ? "active" : ""
                      }`}
                      onClick={sideMenuToggle}
                      style={styles.sideMenPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {sideMenuShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Side Menu Bar</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(sideMenuShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Sidebar menus have been used as a directory for
                          Related Pages to a Service offering, navigation items
                          to a speciﬁc service or topic and even just as Links
                          the visitor may be interested in.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`messagePopup ${
                        messageShowData ? "active" : ""
                      }`}
                      onClick={messageToggle}
                      style={styles.messagePopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {messageShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Message</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(messageShowData)}
                      >
                        <p>
                          Messaging allows you to communicate with the admin
                          through chat. It enables you to send and receive
                          messages. Chatting apps make it easier, simpler and
                          faster to connect with everyone and it is also easy to
                          use.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner7;
