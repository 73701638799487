import React from "react";

import { IoLocationSharp, IoMailOpen } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import HeaderOne from "../components/Header/HeaderOne";
import BreadcrumbFour from "../components/Breadcrumb/BreadcrumbFour";
import Form from "../components/formSection.js/Form";
import ReviewOne from "../components/Reviews/ReviewOne";
import FooterOne from "../components/Footer/FooterOne";

const Contactus = () => {
  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbFour path={"Contact Us"} title={"Contact Us"} />
      <section className="bg-grey">
        <div className="container p-5">
          <div className="row justify-content-between ">
            <div className="col-12 col-lg-5 contact-style px-0 px-lg-3 ">
              <div>
                <div
                  className="px-lg-4 px-sm-0 "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    border: "3px solid rgb(102, 16, 242)",
                    // padding: "20px",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "15px" }}
                  >
                    <h4 className="">USA</h4>
                    <img
                      style={{ width: "45px", height: "25px" }}
                      src="./img/USA.png"
                      alt="img"
                    />
                  </div>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IoLocationSharp
                      size={20}
                      style={{
                        marginRight: "5px",
                        minWidth: "25px",
                        minHeight: "25px",
                      }}
                    />{" "}
                    ADDRESS(USA) :- 1519 W Warren Blvd, Chicago, IL 60607, USA
                  </span>
                  <a href="tel:+13125985838">
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#ffa737",
                      }}
                    >
                      <FaPhoneAlt
                        size={20}
                        style={{ marginRight: "10px", paddingLeft: "2px" }}
                        color="black"
                      />{" "}
                      +1-312-5985838 (US)
                    </span>
                  </a>
                  <a href="mailto:info@aabansolutions.com">
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#ffa737",
                      }}
                    >
                      <IoMailOpen
                        size={20}
                        style={{
                          marginRight: "10px",
                          minWidth: "20px",
                          minHeight: "20px",
                          paddingLeft: "2px",
                        }}
                        color="black"
                      />{" "}
                      info@aabansolutions.com
                    </span>
                  </a>
                </div>
              </div>

              {/* <div className="mt-4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    border: "3px solid rgb(102, 16, 242)",
                    padding: "20px"
                  }}
                >
                <div className="d-flex align-items-center" style={{gap: "15px"}}>
                  <h4 className="">INDIA</h4> 
                  <img style={{width: "45px", height: "25px"}} src="./img/india.png" />
                  </div>
                <span style={{ display: "flex", alignItems: "center" }}>
                        <IoLocationSharp size={20} style={{ marginRight: "5px" }} />{" "}
                        ADDRESS(IN) :- Plot No. 249, M,Udyog Vihar Phase IV, Gurgaon, Haryana 122016, India
                </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#ffa737",
                    }}
                  >
                    <FaPhoneAlt
                      size={20}
                      style={{ marginRight: "5px" }}
                      color="black"
                    />{" "}
                    +91 90453 15147 (IN)
                    </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#ffa737",
                    }}
                  >
                    <IoMailOpen
                      size={20}
                      style={{ marginRight: "5px" }}
                      color="black"
                    />{" "}
                    info@aabansolutions.com
                  </span>
                </div>
              </div> */}
            </div>
            <Form />
          </div>
        </div>
      </section>
      <section>
        <iframe
          title="Google Map"
          // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.681818793766!2d77.08056131544699!3d28.498462682490537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e5a7d2c1235%3A0x3c55e8553a4955c6!2sPlot%20No.%20249%2C%20M%2CUdyog%20Vihar%20Phase%20IV%2C%20Gurugram%2C%20Haryana%20122016%2C%20India!5e0!3m2!1sen!2sbd!4v1646610377889!5m2!1sen!2sbd"

          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.4986609751804!2d-87.67073822281895!3d41.88213170161749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2d248df4328b%3A0xf79be3888998fc0b!2s1519%20W%20Warren%20Blvd%2C%20Chicago%2C%20IL%2060607%2C%20USA!5e0!3m2!1sen!2sbd!4v1717133229792!5m2!1sen!2sbd"
          allowFullScreen
          style={{ width: "100%", height: "500px", border: "none" }}
        ></iframe>
      </section>
      <ReviewOne />
      <FooterOne />
    </>
  );
};

export default Contactus;
