import React, { useState } from "react";
import App from "../../App.css";

const Banner2 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [otpShowData, setOtpShowData] = useState(true);
  const [registrationShowData, setRegistrationShowData] = useState(false);
  const [locationShowData, setLocationShowData] = useState(false);

  const otpToggle = () => {
    setOtpShowData(!otpShowData);
    setRegistrationShowData(false);
    setLocationShowData(false);
  };

  const registrationToggle = () => {
    setRegistrationShowData(!registrationShowData);
    setOtpShowData(false);
    setLocationShowData(false);
  };

  const locationToggle = () => {
    setLocationShowData(!locationShowData);
    setOtpShowData(false);
    setRegistrationShowData(false);
  };
  const styles = {
    otpPopup: {
      backgroundColor: otpShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    registrationPopup: {
      backgroundColor: registrationShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    locationPopup: {
      backgroundColor: locationShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s ",
    }),
  };
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <div className="container  align-items-center justify-content-center d-flex mt-3">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`otpPopup ${otpShowData ? "active" : ""}`}
                    onClick={otpToggle}
                    style={styles.otpPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {otpShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      OTP Verify Screen
                    </span>
                  </div>
                  {otpShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In verify OTP screen, the user will enter the OTP sent
                        to his regis-tered mobile number for veriﬁcation. Once
                        enter the received OTP for veriﬁcation, then the pop-up
                        will generate that your pro-ﬁle is created successfully.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`registrationPopup ${
                      registrationShowData ? "active" : ""
                    }`}
                    onClick={registrationToggle}
                    style={styles.registrationPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {registrationShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      Registration Screen
                    </span>
                  </div>
                  {registrationShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In registration screen, the new cus-tomer or user will
                        enter his details like:
                        <p style={{ color: "black" }}> 1. Name</p>
                        <p style={{ color: "black" }}> 2. Email address</p>
                        <p style={{ color: "black" }}>
                          {" "}
                          3. Contact number with country code.
                        </p>
                        <p style={{ color: "black" }}> 4. Password</p>
                        <p style={{ color: "black" }}> 5. Conﬁrm password</p>
                        <p style={{ color: "black" }}>
                          {" "}
                          6. Agree to terms and conditions (checkbox).
                        </p>
                        Then click on Create account button.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`locationPopup ${
                      locationShowData ? "active" : ""
                    }`}
                    onClick={locationToggle}
                    style={styles.locationPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {locationShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Turn On Location</span>
                  </div>
                  {locationShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        By clicking on Turn on GPS button, users can turn on GPS
                        to ﬁnd out better restaurant suggestions as per our
                        location.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-4.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-5.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-6.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  */}

      {/* Changed One  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div
                className="col-lg-6 col-md-12 px-lg-5 px-sm-0"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`otpPopup ${otpShowData ? "active" : ""}`}
                      onClick={otpToggle}
                      style={styles.otpPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {otpShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        OTP Verify Screen
                      </span>
                    </div>

                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(otpShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In verify OTP screen, the user will enter the OTP sent
                        to his registered mobile number for verification. Once
                        enter the received OTP for verification, then the pop-up
                        will generate that your profile is created successfully.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`registrationPopup ${
                        registrationShowData ? "active" : ""
                      }`}
                      onClick={registrationToggle}
                      style={styles.registrationPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {registrationShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Registration Screen
                      </span>
                    </div>

                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(registrationShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In registration screen, the new customer or user will
                        enter his details like:
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          1. Name
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          2. Email address
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          3. Contact number with country code
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          4. Password
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          5. Confirm password
                        </p>
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          6. Agree to terms and conditions (checkbox)
                        </p>
                        Then click on Create account button.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`locationPopup ${
                        locationShowData ? "active" : ""
                      }`}
                      onClick={locationToggle}
                      style={styles.locationPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {locationShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Turn On Location
                      </span>
                    </div>

                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(locationShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        By clicking on Turn on GPS button, users can turn on GPS
                        to find out better restaurant suggestions as per our
                        location.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-4.png"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-5.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-6.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner2;
