
import React, { useState } from 'react';
import App from '../../App.css';
import dbdata from "../../data/db.json";

const Banner8 = ({ pageName }) => {
  const dataSection = dbdata[pageName];

  return (
    <>
      {dataSection?.banner8?.map((banner, index) => (
        <Banner key={index} banner={banner} />
      ))}
    </>
  );
};

const Banner = ({ banner }) => {
  const { Imagepath, data, orderStyle, getInTouch, heading } = banner;

  const [showData, setShowData] = useState(Array(data.length).fill(false));

  const toggleData = (index) => {
    setShowData(prevState => {
      const updatedState = [...prevState.map(() => false)];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };


  return (
    <section className='bg-grey'>
      <div className={`container flex-column d-flex justify-content-center align-items-center pt-5 pb-5`}>
        {getInTouch && (
          <div className='d-flex justify-content-center align-items-center'>
            <a href='#' style={{ padding: '9px 35px', backgroundColor: '#FFA700', fontSize: 10, alignItems: 'center' }} className='m-5 d-flex'>
              <i class="fa-arrow-alt-circle-right fas button-icon-left mr-3 " aria-hidden="true"></i>
              <h5>GET IN TOUCH</h5>
            </a>
          </div>
        )}
        
        {heading && <h2 className='text-center'>{heading}</h2>}
        <div className='container  align-items-center justify-content-center d-flex mt-5'>
          <div className={`d-flex row ${orderStyle ? 'flex-row-reverse' : ''}`}>
            <div className="col-lg-8 col-md-12">
              <div className='image-container'>
                {Imagepath.map((item, index) => (
                  <img className='col-lg-6' src={`/img/${item.Image}`} alt='image' key={index} />
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              {data.map((item, index) => (
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }} key={index}>
                  <div className="otpPopup" onClick={() => toggleData(index)} style={{ backgroundColor: showData[index] ? '#6CB4EE' : null, padding: 5 }}>
                    <button style={{ backgroundColor: '#318CE7', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{showData[index] ? '-' : '+'}</button>
                    <span style={{ fontWeight: 'bold' }}>{item.title}</span>
                  </div>
                  {showData[index] && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: 'black' }}>{item.desc}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner8;
