import React, { useEffect, useState } from "react";
import ModalMenu from "../Modal/ModalMenu";
import { navigationMenu } from "../../data/navigationMenu";

const HeaderOne = (props) => {
    const { backgroundColor = "", imageData = "", style = {} } = props;
    const [isOpen, setIsOpen] = useState(false);

  

    return (
        <header id="header">
            {/* Navbar */}
            <nav
                data-aos="zoom-out"
                data-aos-delay={800}
                className="navbar navbar-expand"
                style={{ ...style, backgroundColor }}
            >
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand navbar-Custom-style" href="/">
                        <img
                            className="navbar-brand-regular"
                            src={props.imageData}
                            alt="brand-logo"
                            style={{ height: 43, width: 150 }}
                        />
                        <img
                            className="navbar-brand-sticky"
                            src="/img/imgpsh_fullsize_anim.png"
                            alt="sticky brand-logo sticky-header-style"
                            style={{ height: 43, width: 150, color: "#6610f2" }}
                        />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items">
                    <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        {navigationMenu?.map((val, i) => (
                            <li className="nav-item dropdown" key={i}>
                                <a className="nav-link" href="/#">
                                    {val?.name}
                                    {val?.menu && <i className="fas fa-angle-down ml-1" />}
                                </a>
                                {val?.menu && (
                                    <ul className="dropdown-menu">
                                        {val?.menu?.map((vall, ind) => (
                                            <li className="nav-item dropdown" key={ind}>
                                                <a className="nav-link" href="/#">
                                                    {vall.menuName}
                                                    {vall.subMenu && (
                                                        <i className="fas fa-angle-right ml-1" />
                                                    )}
                                                </a>
                                                {vall?.subMenu ? (
                                                    <ul className="dropdown-menu">
                                                        {vall?.subMenu?.map((value, index) => (
                                                            <li className="nav-item dropdown" key={index}>
                                                                <a className="nav-link" href={value?.link}>
                                                                    {value?.subMenuName}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : null}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/#">Pricing<i className="fas fa-angle-down ml-1" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item dropdown">
                                    <a href="/#" className="nav-link">SEO Packages</a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><a href="/pricing/seo-packages/pocket-friendly-seo-packages" className="nav-link">Pocket-Friendly SEO Packages</a></li>
                                        <li className="nav-item"><a href="/pricing/seo-pricing" className="nav-link">Standard SEO Packages</a></li>
                                        <li className="nav-item"><a href="/pricing/seo-packages/corporate-seo-packages" className="nav-link">Corporate SEO Packages </a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link" href="/pricing/smo-pricing">SMO Packages</a>
                                </li>
                                <li className="nav-item"><a href="/pricing/ppc-packages" className="nav-link">PPC Packages</a></li>
                                <li className="nav-item"><a href="/pricing/amazon-ads-services" className="nav-link">Amazon Ads</a></li>
                                <li className="nav-item"><a href="/pricing/web-packages" className="nav-link">Web Packages</a></li>

                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                             <a href="/aaban-portfolio" className="nav-link">Portfolio</a>
                            <ul className="dropdown-menu">
                                <li className="nav-item dropdown">
                                    <a href="/#" className="nav-link">App</a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><a href="/food-delivery-application" className="nav-link">Food Delivery</a></li>
                                        <li className="nav-item"><a href="/real-estate-application" className="nav-link">Real Estate</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><a href="/aaban-portfolio/seo-case-studies" className="nav-link">SEO</a></li>
                                <li className="nav-item"><a href="/" className="nav-link">Website </a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="/about-us" className="nav-link">About Us</a>
                        </li>
                        <li className="nav-item">
                            {/* <a href="/testimonial" className="nav-link">Testimonial</a> */}
                        </li>
                        <li className="nav-item">
                            <a href="/blog" className="nav-link">Blog</a>
                        </li>
                        <li className="nav-item">
                            <a href="/contact" className="nav-link">Contact Us</a>
                        </li>
                        <li className="nav-item">
                            <a href="/free-consultation" className="nav-link btn btn-bordered-grey ">Free Consultation</a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item" onClick={() => setIsOpen(!isOpen)}>
                            <a
                                href="/#"
                                className="nav-link"
                                data-toggle="modal"
                                data-target="#menu"
                            >
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>

                    {isOpen && <ModalMenu closeModal={() => setIsOpen(false)} />}
                </div>
            </nav>
        </header>
    );
};

export default HeaderOne;
