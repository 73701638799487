import React, { useState } from "react";
import App from "../../App.css";

const Banner16 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [propShowData, setPropShowData] = useState(true);
  const [addPropShowData, setAddPropShowData] = useState(false);

  const propToggle = () => {
    setPropShowData(!propShowData);
    setAddPropShowData(false);
  };

  const addPropToggle = () => {
    setAddPropShowData(!addPropShowData);
    setPropShowData(false);
  };
  const styles = {
    propPopup: {
      backgroundColor: propShowData ? "#6CB4EE" : null,
      padding: 5,
    },

    addPropPopup: {
      backgroundColor: addPropShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    })
  };
  return (
    <>
      {/* <section className='bg-grey'>
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-5'>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-6' src='/img/realEstateImg-42.webp' alt='image' />
                  <img className='col-lg-6' src='/img/realEstateImg-43.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`propPopup ${propShowData ? 'active' : ''}`} onClick={propToggle} style={styles.propPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {propShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>User Profile</span>
                  </div>
                  {propShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In user profile, admin can view users contact details & can view their listed properties, number of likes & bookmarks saved.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`addPropPopup ${addPropShowData ? 'active' : ''}`} onClick={addPropToggle} style={styles.addPropPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{addPropShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Add Users</span>
                  </div>
                  {addPropShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Admin have the permission to add new users along with their basic details</p>
                      <p>• Full name</p>
                      <p>• Date of birth</p>
                      <p>• Email address</p>
                      <p>• Contact number</p>
                      <p>• Password & confirm password</p>
                      <p>Then click on Submit button to save the details.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** changed one  *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-8 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-42.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-43.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`propPopup ${propShowData ? "active" : ""}`}
                    onClick={propToggle}
                    style={styles.propPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {propShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>User Profile</span>
                  </div>
                    <div className="content mt-2 mb-2 backColor"
                    style={styles.content(propShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        In user profile, admin can view users contact details &
                        can view their listed properties, number of likes &
                        bookmarks saved.
                      </p>
                    </div>
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`addPropPopup ${
                      addPropShowData ? "active" : ""
                    }`}
                    onClick={addPropToggle}
                    style={styles.addPropPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {addPropShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Add Users</span>
                  </div>
                    <div className="content mt-2 mb-2 backColor"
                    style={styles.content(addPropShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        Admin have the permission to add new users along with
                        their basic details
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>• Full name</p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>• Date of birth</p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>• Email address</p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>• Contact number</p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>• Password & confirm password</p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>Then click on Submit button to save the details.</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner16;
