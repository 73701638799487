import React, { useEffect, useState } from "react";
import jsonData from "../../data/db.json";

const WebCard = ({ pageName }) => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    if (jsonData[pageName] && jsonData[pageName]["cardsData"]) {
      setCardsData(jsonData[pageName]["cardsData"]);
    }
  }, [pageName]);

  return (
    <>
      {cardsData.map((card, index) => (
        <div className="col-lg-6 col-md-6 col-sm-12 web-card-style " key={index}>
        <div className="card my-3 news-card-style web-card-height-style" style={{ height: "250px" }}>
          <h2 className="header-section">{card.cardheader}</h2>
          <div className="card-body text-center">
            <p>{card.carddisc}</p>
          </div>
        </div>
      </div>
      ))}
    </>
  );
};

export default WebCard;
