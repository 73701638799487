import React, { useEffect, useState } from 'react';
import jsonData from "../../data/db.json"
import { useNavigate } from 'react-router-dom';

const BreadcrumbSection = ({ pageName }) => {
    const navigation = useNavigate()
    const [header,setHeader] = useState('')
    const [path,setPath] = useState('')
    const [disc,setDisc] = useState('')
    const [button,setButton] = useState('')

    useEffect(() => {
        setHeader(jsonData[pageName].pakageHeading)
        setPath(jsonData[pageName].path)
        setDisc(jsonData[pageName].disc)
        setButton(jsonData[pageName].button)
      }, [pageName]);

      const isImage = "/img/pexels-finance.jpg";

    return (
        <section className="section breadcrumb-area  d-flex align-items-center" style={{backgroundImage: `url(${isImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
        }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content text-center">
                            <h3 className="text-white text-uppercase mb-3" style={{fontSize: "3rem"}}>{header}</h3>
                            {disc && <p className='text-white my-2'>{disc}</p>}
                            {path && <p className='text-white'>
                                <span style={{cursor: "pointer"}} onClick={() => navigation("/")}>Home / </span><span style={{cursor: "pointer"}} onClick={() => navigation("/pricing/seo-pricing")}>SEO Pricing and Plans</span><span>  /  {path}</span>
                            </p>}
                            {button && <button className='btn mt-3'>{button}</button>}
                        </div>
                    </div>
                </div>
                </div>
            </section>
        );
    }


export default BreadcrumbSection;