import React, { useState } from "react";
import App from "../../App.css";

const Banner3 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [splashShowData, setSplashShowData] = useState(true);
  const [welcomeShowData, setWelcomeShowData] = useState(false);
  const [signUpShowData, setSignUpShowData] = useState(false);

  const splashToggle = () => {
    setSplashShowData(!splashShowData);
    setWelcomeShowData(false);
    setSignUpShowData(false);
  };

  const welcomeToggle = () => {
    setWelcomeShowData(!welcomeShowData);
    setSplashShowData(false);
    setSignUpShowData(false);
  };

  const signupToggle = () => {
    setSignUpShowData(!signUpShowData);
    setSplashShowData(false);
    setWelcomeShowData(false);
  };
  const styles = {
    splashPopup: {
      backgroundColor: splashShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: welcomeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    fpwordPopup: {
      backgroundColor: signUpShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <div className="container  align-items-center justify-content-center d-flex mt-3">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-7.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-8.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-9.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`splashPopup ${splashShowData ? "active" : ""}`}
                    onClick={splashToggle}
                    style={styles.splashPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {splashShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      OTP Verification Screen
                    </span>
                  </div>
                  {splashShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        In the OTP verification screen, a 6 digit code will be
                        sent to your registered mobile number. Once enter the
                        received OTP for veriﬁcation, then the pop-up will
                        generate that your pro- ﬁle is created successfully.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`loginPopup ${welcomeShowData ? "active" : ""}`}
                    onClick={welcomeToggle}
                    style={styles.loginPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {welcomeShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      Reset Your Password
                    </span>
                  </div>
                  {welcomeShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        Reset password is the action of invalidating the current
                        password for an account on a website or an app and then
                        creating a new one. A password may be reset using the
                        settings of the app or service.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`fpwordPopup ${signUpShowData ? "active" : ""}`}
                    onClick={signupToggle}
                    style={styles.fpwordPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {signUpShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      What Do You Want Screen
                    </span>
                  </div>
                  {signUpShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        The home screen is the main screen of an app. It is the
                        screen where users interact with the most & contains all
                        of the options for an app. Home screens are designed
                        depending on the type of a product and its purpose.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="  align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-7.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-8.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-9.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`splashPopup ${
                        splashShowData ? "active" : ""
                      }`}
                      onClick={splashToggle}
                      style={styles.splashPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {" "}
                        {splashShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        OTP Verification Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(splashShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In the OTP verification screen, a 6 digit code will be
                          sent to your registered mobile number. Once enter the
                          received OTP for veriﬁcation, then the pop-up will
                          generate that your pro- ﬁle is created successfully.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${
                        welcomeShowData ? "active" : ""
                      }`}
                      onClick={welcomeToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {welcomeShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Reset Your Password
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(welcomeShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Reset password is the action of invalidating the
                          current password for an account on a website or an app
                          and then creating a new one. A password may be reset
                          using the settings of the app or service.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`fpwordPopup ${
                        signUpShowData ? "active" : ""
                      }`}
                      onClick={signupToggle}
                      style={styles.fpwordPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {signUpShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        What Do You Want Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(signUpShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          The home screen is the main screen of an app. It is
                          the screen where users interact with the most &
                          contains all of the options for an app. Home screens
                          are designed depending on the type of a product and
                          its purpose.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner3;
