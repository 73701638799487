import React, { useState } from "react";
import App from "../../App.css";

const Banner2 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [phoneVeriShowData, setPhoneVeriShowData] = useState(true);
  const [loginShowData, setLoginShowData] = useState(false);
  const [forgotPassShowData, setForgotPassShowData] = useState(false);

  const phoneVeriToggle = () => {
    setPhoneVeriShowData(!phoneVeriShowData);
    setLoginShowData(false);
    setForgotPassShowData(false);
  };

  const loginToggle = () => {
    setLoginShowData(!loginShowData);
    setPhoneVeriShowData(false);
    setForgotPassShowData(false);
  };

  const forgotPass = () => {
    setForgotPassShowData(!forgotPassShowData);
    setPhoneVeriShowData(false);
    setLoginShowData(false);
  };
  const styles = {
    phoneVeriPopup: {
      backgroundColor: phoneVeriShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: loginShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    forgotPassPopup: {
      backgroundColor: forgotPassShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey'>
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`phoneVeriPopup ${phoneVeriShowData ? 'active' : ''}`} onClick={phoneVeriToggle} style={styles.phoneVeriPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {phoneVeriShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Phone Verification Screen</span>
                  </div>
                  {phoneVeriShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Upon clicking the phone verification button, you will receive a four-digit code to your registered mobile device. To proceed, enter the received code and click the submit button. Even users can verify via email address, they will receive an OTP on their registered email address.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`loginPopup ${loginShowData ? 'active' : ''}`} onClick={loginToggle} style={styles.loginPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{loginShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Login Screen</span>
                  </div>
                  {loginShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In the login screen, customers will enter the account information:<p />

                        <p>• Email ID</p>
                        <p>• Password</p>
                        <p>Then click on the Login button.
                          The user’s entered credential – Email address/Username, Password is valid and correct or not. Users will not get access to the restricted page until he/she entered a valid email and password.
                          If the customer forgot their password, in that case, the customer can reset their current password & set a new password.</p>
                      </p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`forgotPassPopup ${forgotPassShowData ? 'active' : ''}`} onClick={forgotPass} style={styles.forgotPassPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{forgotPassShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Forgot Password Screen</span>
                  </div>
                  {forgotPassShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In forgot password screen, if the customer forgot his password so in that case he can reset the current password & set a new one. He only needs to enter his registered email address & will receive OTP. After entering the received OTP, the user can reset his current password & conﬁrm a new password.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-4.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-5.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-6.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`phoneVeriPopup ${
                        phoneVeriShowData ? "active" : ""
                      }`}
                      onClick={phoneVeriToggle}
                      style={styles.phoneVeriPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {phoneVeriShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Phone Verification Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2"
                      style={styles.content(phoneVeriShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Upon clicking the phone verification button, you will
                          receive a four-digit code to your registered mobile
                          device. To proceed, enter the received code and click
                          the submit button. Even users can verify via email
                          address, they will receive an OTP on their registered
                          email address.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${loginShowData ? "active" : ""}`}
                      onClick={loginToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {loginShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Login Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2" 
                      style={styles.content(loginShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In the login screen, customers will enter the account
                          information:
                        </p>
                        <ul>
                          <li style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                            Email ID
                          </li>
                          <li style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                            Password
                          </li>
                        </ul>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Then click on the Login button. The user’s entered
                          credential – Email address/Username, Password is valid
                          and correct or not. Users will not get access to the
                          restricted page until he/she entered a valid email and
                          password. If the customer forgot their password, in
                          that case, the customer can reset their current
                          password & set a new password.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`forgotPassPopup ${
                        forgotPassShowData ? "active" : ""
                      }`}
                      onClick={forgotPass}
                      style={styles.forgotPassPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {forgotPassShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Forgot Password Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2"
                      style={styles.content(forgotPassShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In forgot password screen, if the customer forgot his
                          password so in that case he can reset the current
                          password & set a new one. He only needs to enter his
                          registered email address & will receive OTP. After
                          entering the received OTP, the user can reset his
                          current password & confirm a new password.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-4.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-5.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-6.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner2;
