import React, { useEffect } from "react";
import ScrollupSection from "../Scrollup/Scrollup";
import Sidebar from "./Sidebar";
import Single from "./Single";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const BlogRightSidebar = ({ pageName }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    // <div className="blog blog-right  ">
    //   <ScrollupSection />
    //   <div className="main overflow-hidden">
    //     <section id="blog" className="section blog-area pt_100 " >
    //       <div className="container px-0">
    //         <div className="row">
    //           <div className="col-12 col-lg-9">
    //             <Single pageName={pageName} />
    //           </div>
    //           <div className="col-12 col-lg-3" >
    //             <Sidebar pageName={pageName} />
    //           </div>
    //         </div>
    //         <div className="container   m-0 p-0 single-map-section row">
    //           <div className=" d-flex align-item-center justify-content-center  p-0 col-lg-5 ">
    //             <iframe
    //               title="Google Map"
    //               width="500"
    //               height="250"
    //               frameBorder="0"
    //               style={{ border: 0 }}
    //               // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.681818793766!2d77.08056131544699!3d28.498462682490537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e5a7d2c1235%3A0x3c55e8553a4955c6!2sPlot%20No.%20249%2C%20M%2CUdyog%20Vihar%20Phase%20IV%2C%20Gurugram%2C%20Haryana%20122016%2C%20India!5e0!3m2!1sen!2sbd!4v1646610377889!5m2!1sen!2sbd"

    //               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.4986609751804!2d-87.67073822281895!3d41.88213170161749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2d248df4328b%3A0xf79be3888998fc0b!2s1519%20W%20Warren%20Blvd%2C%20Chicago%2C%20IL%2060607%2C%20USA!5e0!3m2!1sen!2sbd!4v1717133229792!5m2!1sen!2sbd"
    //               allowFullScreen
    //             ></iframe>
    //           </div>
    //           <div className="container d-flex my-0 align-items-center justify-content-center row col-lg-7 p-0">
    //             <div className="d-flex flex-column align-items-center mt-2 mb-4 col-lg-5 col-sm-12">
    //               <FaMapMarkerAlt size={35} />
    //               <p className="pt-1">
    //               1519 W Warren Blvd, Chicago, IL 60607, USA
    //               </p>
    //             </div>
    //             <div className="d-flex flex-column mx-4 align-items-center mb-4 col-lg-3 col-sm-12">
    //               <FaPhoneAlt size={35} />
    //               <p className="ml-0">+(1)312-598-5838</p>
    //             </div>
    //             <div className="d-flex flex-column align-items-center mb-4  col-lg-3 col-sm-12 ">
    //               <FaEnvelope size={35} />
    //               <p className="ml-0">info@aabansolutions.com</p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </div>

    // ***** Changed one *****

    <div className="blog blog-right  px-lg-5 px-sm-0">
      <ScrollupSection />
      <div className="main overflow-hidden">
        <section id="blog" className="section blog-area pt_100">
          {/* Removed container class */}
          <div className="px-3" style={{ paddingLeft: "0", paddingRight: "0" }}>
            <div className="row">
              <div className="col-12 col-lg-9">
                <Single pageName={pageName} />
              </div>
              <div className="col-12 col-lg-3">
                <Sidebar pageName={pageName} />
              </div>
            </div>
            <div
              className="container-fluid m-0 p-3  single-map-section row"
              style={{
                paddingLeft: "0",
                paddingRight: "0",
              }}
            >
              <div className="d-flex align-item-center justify-content-center p-0 col-lg-5">
                <iframe
                  title="Google Map"
                  width="100%" // Make the iframe responsive
                  height="250"
                  frameBorder="0"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.4986609751804!2d-87.67073822281895!3d41.88213170161749!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2d248df4328b%3A0xf79be3888998fc0b!2s1519%20W%20Warren%20Blvd%2C%20Chicago%2C%20IL%2060607%2C%20USA!5e0!3m2!1sen!2sbd!4v1717133229792!5m2!1sen!2sbd"
                  allowFullScreen
                ></iframe>
              </div>
              <div
                className="container d-flex my-0 align-items-center justify-content-center row col-lg-7 p-0"
                style={{
                  paddingLeft: "0",
                  paddingRight: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex flex-column align-items-center mt-md-2  col-lg-4 col-sm-12 "
                  style={{ gap: "10px" }}
                >
                  <a
                    href="https://www.google.com/maps/place/1519+W+Warren+Blvd,+Chicago,+IL+60607,+USA/@41.882128,-87.666125,15z/data=!4m6!3m5!1s0x880e2d248df4328b:0xf79be3888998fc0b!8m2!3d41.8821278!4d-87.6661248!16s%2Fg%2F11c256jm0w?hl=en&entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit" }}
                  >
                    <FaMapMarkerAlt
                      size={60}
                      style={{
                        border: "3.5px solid #35b2fe",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.google.com/maps/place/1519+W+Warren+Blvd,+Chicago,+IL+60607,+USA/@41.882128,-87.666125,15z/data=!4m6!3m5!1s0x880e2d248df4328b:0xf79be3888998fc0b!8m2!3d41.8821278!4d-87.6661248!16s%2Fg%2F11c256jm0w?hl=en&entry=ttu"
                    target="_blank"
                    style={{
                      textAlign: "center",
                      fontSize: "1.1rem",
                      textDecoration: "none",
                      color: "#35b2fe",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#8830df";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#35b2fe";
                    }}
                  >
                    1519 W Warren Blvd, Chicago, IL 60607, USA
                  </a>
                  {/* <p
                    className="pt-1"
                    style={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "450",
                    }}
                  >
                    1519 W Warren Blvd, Chicago, IL 60607, USA
                  </p> */}
                </div>
                <div
                  className="d-flex flex-column mx-4 align-items-center mb-lg-3  mb-sm-0 col-lg-4 col-sm-12"
                  style={{ gap: "15px" }}
                >
                  <a href="tel:+13125985838" style={{ color: "inherit" }}>
                    <FaPhoneAlt
                      size={60}
                      style={{
                        border: "3.5px solid #35b2fe",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    />
                  </a>

                  <a
                    href="tel:+13125985838"
                    style={{
                      textAlign: "center",
                      fontSize: "1rem",
                      textDecoration: "none",
                      color: "#35b2fe",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#8830df";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#35b2fe";
                    }}
                  >
                    +(1)312-598-5838
                  </a>
                </div>
                <div
                  className="d-flex flex-column align-items-center col-lg-3 col-sm-12 mb-lg-3 mb-sm-0"
                  style={{ gap: "15px" }}
                >
                  <a
                    href="mailto:info@aabansolutions.com"
                    style={{ color: "inherit" }}
                  >
                    <FaEnvelope
                      size={60}
                      style={{
                        border: "3.5px solid #35b2fe",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    />
                  </a>

                  <a
                    href="mailto:info@aabansolutions.com"
                    style={{
                      textAlign: "center",
                      fontSize: "1.1rem",
                      textDecoration: "none",
                      color: "#35b2fe",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#8830df";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#35b2fe";
                    }}
                  >
                    info@aabansolutions.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BlogRightSidebar;
