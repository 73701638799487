import React, { useState, useEffect } from 'react';

const initData = {
    heading: "Looking for the best digital agency & marketing solution?",
    headingText_1: "Drive targeted traffic and increase conversions with expert digital marketing solutions.",
    btnText: "Contact Us"
};

const Cta = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(initData);
    }, []);

    return (
        <section className="section ptb_100" style={{backgroundImage: `url("/img/pexels-tara-winstead-8386440.jpg")`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                        {/* Section Heading */}
                        <div className="section-heading text-center m-0">
                            <h2 className="text-white">{initData.heading}</h2>
                            <p className="text-white d-none d-sm-block mt-4">{initData.headingText_1}</p>
                            <a href="/contact" className="btn btn-bordered-white mt-4">{initData.btnText}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Cta;
