import React, { useState, useEffect } from "react";
import HeaderOne from "../Header/HeaderOne";
import { navigationMenu } from "../../data/navigationMenu";
import "../../App.css";

const initData = {
  title: "Menu",
  iconClass: "far fa-times-circle icon-close",
};

const ModalMenu = ({ closeModal }) => {
  const [data, setData] = useState({});



  useEffect(() => {
    setData(initData);
  }, []);

  return (
    <div id="menu" className="modal fade p-0">
      <div className="modal-dialog dialog-animated">
        <div className="modal-content h-100">
          <div className="modal-header" data-dismiss="modal">
            <h5>{data.title}</h5> <i className={data.iconClass} onClick={closeModal} />
          </div>
          <div className="menu modal-body">
            <div className="row w-100">
              <div className="items p-0 col-12 text-center">
                <ul className="navbar-nav items">
                  {navigationMenu?.map((val, i) => (
                    <li className="nav-item dropdown" key={i}>
                      <a className="nav-link" href="/#">
                        {val?.name}
                        {val?.menu && <i className="fas fa-angle-down ml-1" />}
                      </a>
                      {val?.menu && (
                        <ul className="dropdown-menu">
                          {val?.menu?.map((vall, ind) => (
                            <li className="nav-item dropdown" key={ind}>
                              <a className="nav-link" href="/#">
                                {vall.menuName}
                                {vall.subMenu && (
                                  <i className="fas fa-angle-right ml-1" />
                                )}
                              </a>
                              {vall?.subMenu ? (
                                <ul className="dropdown-menu">
                                  {vall?.subMenu?.map((value, index) => (
                                    <li className="nav-item dropdown" key={index}>
                                      <a className="nav-link" href={value?.link}>
                                        {value?.subMenuName}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/#">Pricing<i className="fas fa-angle-down ml-1" /></a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a href="/pricing/seo-pricing" className="nav-link">SEO Packages</a>
                        <ul className="dropdown-menu">
                          <li className="nav-item"><a href="/pricing/seo-packages/pocket-friendly-seo-packages" className="nav-link">Pocket-Friendly SEO Packages</a></li>
                          <li className="nav-item"><a href="/pricing/seo-pricing" className="nav-link">Standard SEO Packages</a></li>
                          <li className="nav-item"><a href="/pricing/seo-packages/corporate-seo-packages" className="nav-link">Corporate SEO Packages </a></li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <a className="nav-link" href="/#">SMO Packages</a>
                      </li>
                      <li className="nav-item"><a href="/pricing/ppc-packages" className="nav-link">PPC Packages</a></li>
                      <li className="nav-item"><a href="/pricing/amazon-ads-services" className="nav-link">Amazon Ads</a></li>
                      <li className="nav-item"><a href="/pricing/web-packages" className="nav-link">Web Packages</a></li>

                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a href="/#" className="nav-link">App <i className="fas fa-angle-down ml-1" /></a>
                    <ul className="dropdown-menu">
                      <li className="nav-item"><a href="/food-delivery-application" className="nav-link">Food Delivery</a></li>
                      <li className="nav-item"><a href="/real-estate-application" className="nav-link">Real Estate</a></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a href="/aaban-portfolio" className="nav-link">Portfolio</a>
                  </li>
                  <li className="nav-item">
                    <a href="/about-us" className="nav-link">About Us</a>
                  </li>
                  <li className="nav-item">
                    <a href="/testimonial" className="nav-link">Testimonial</a>
                  </li>
                  <li className="nav-item">
                    <a href="/blog" className="nav-link">Blog</a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact" className="nav-link">Contact Us</a>
                  </li>
                  <li className="nav-item">
                    <a href="/free-consultation" className="nav-link btn btn-bordered-grey px-3 ">Free Consultation</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMenu;
