import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BreadcrumbThree = (props) => {
    const {  path, title, desc, image, children } = props;
    const navigation = useNavigate()

    const sectionStyle = image ? {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    } : {};

    const isImage = image ? "isImage-style" : "";

    return (
        <section className={`section breadcrumb-area overlay-dark ${isImage} d-flex align-items-center`} style={sectionStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        
                            {children ? 
                                children
                                : (
                                <>
                                <div className=" text-center">
                                    <h2 className="text-white text-uppercase mb-3">{title}</h2>
                                    <h4 className="text-white text-uppercase mb-3">{desc}</h4>
                                    <p className='text-white'>
                                        <span style={{cursor: "pointer"}} onClick={() => navigation("/")}>Home</span><span> / {path}</span>
                                    </p> 
                                    </div>
                                </>
                            )}
                       
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BreadcrumbThree;
