import React from "react";
import data from "../../data/db.json";

const DevelopmentServices = ({ pageName }) => {
  const { BussinessCards } = data[pageName];

  return (
    <div className="container mt-5 mb-5 flex-column d-flex justify-content-center align-items-center text-center">
      <div className="container text-center mt-5">
        <div className="">
          <h3 className="m-2" style={{color: "rgb(3, 169, 244)"}}>{BussinessCards[0]?.heading}</h3>
          <div className="d-flex justify-content-center ">
            <hr style={{ border: "2px solid #faa738", width: "10%" }} />
          </div>
        </div>
      </div>
      <div className="row">
        {BussinessCards[1]?.list.map((item, index) => (
          <div
            key={index}
            className="col-lg-4 col-md-12 d-flex justify-content-center mt-3 mb-3"
          >
            <div
              className="card"
              style={{ borderTop: "10px solid #25ABFC" }}
            >
              <div className="card-body align-items-center text-center shadow">
                <img src={`/img/${item?.image}`} alt="Image" />
                <p style={{ fontWeight: "bold" }}>{item?.heading}</p>
                <p>{item?.desc}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevelopmentServices;
