import React from 'react'

const Youtube = (props) => {
    const {height, width, youtubeLink} = props;
    return (
        <div>
            <iframe
                width={width}
                height={height}
                src={`${youtubeLink}?autoplay=1`}
                allow="autoplay"
            ></iframe>
        </div>
    )
}

export default Youtube