import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Form from '../formSection.js/Form';
import { FaMobileAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";



const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneContactSection";

const ContactOne = () => {
    const [data, setData] = useState({});
    const [contactData, setContactData] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}`)
            .then(res => {
                setData(res.data);
                setContactData(res.data.contactData);
            })
            .catch(err => console.log(err));
    }, []);

    return (
        <section id="contact" className="contact-area ptb_100">
        <div className="container">
            <h2 className='text-center'>Contact Us</h2> {/* Heading for the section */}
            <div className="row justify-content-between align-items-center">
                <div className="col-12 col-lg-5">
                    {/* Contact Info */}
                    <div className="contact-us">
                        <ul>
                            {/* Contact Info */}
                            <li className="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3">
                                <span><FaMobileAlt size={45} /></span>
                                <a className="d-block my-2" href="/#">
                                    <h3>(872) 278-4146</h3>
                                </a>
                                <p>call now!</p>
                            </li>
                            <li className="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3">
                                <span><IoMdMail size={45} /></span>
                                <a className="d-block my-2" href="/#">
                                    <h3>info@aabansolutions.com</h3>
                                </a>
                                <p>contact now!</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-lg-7">
                    {/* Contact Form */}
                    <div className="contact-box text-center">
                        <form id="contact-form" method="POST" action="/php/mail.php">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="user_name" placeholder="Name" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="user_email" required placeholder="Email"  />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="col-12 form-style">
                                    <button type="submit" className="btn btn-bordered active btn-block mt-3"><span className="text-white pr-3">SUBMIT</span></button>
                                </div>
                            </div>
                        </form>
                        <p className="form-message" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    );
}

export default ContactOne;
