import React, { useState } from "react";
import App from "../../App.css";

const Banner6 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [homeShowData, setHomeShowData] = useState(true);
  const [PropertyShowData, setPropertyShowData] = useState(false);
  const [filterShowData, setfilterShowData] = useState(false);

  const homeToggle = () => {
    setHomeShowData(!homeShowData);
    setPropertyShowData(false);
    setfilterShowData(false);
  };

  const propertyToggle = () => {
    setPropertyShowData(!PropertyShowData);
    setHomeShowData(false);
    setfilterShowData(false);
  };

  const forgotPassToggle = () => {
    setfilterShowData(!filterShowData);
    setHomeShowData(false);
    setPropertyShowData(false);
  };
  const styles = {
    phoneVeriPopup: {
      backgroundColor: homeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: PropertyShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    forgotPassPopup: {
      backgroundColor: filterShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`phoneVeriPopup ${homeShowData ? 'active' : ''}`} onClick={homeToggle} style={styles.phoneVeriPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {homeShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Furnished Property</span>
                  </div>
                  {homeShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In the case of furnished property, need to fill in the below details:</p>
                      <p>1. Number of bedrooms/bathrooms/beds</p>
                      <p>2. Amenities</p>
                      <p>3. Preferred tenants</p>
                      <p>4. Furniture</p>
                      <p>5. Equipment</p>
                      <p>6. Services</p>
                      <p>7. Short description</p>
                      <p>8. Budget</p>
                      <p>9. Proceed CTA</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`loginPopup ${PropertyShowData ? 'active' : ''}`} onClick={propertyToggle} style={styles.loginPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{PropertyShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Services</span>
                  </div>
                  {PropertyShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p >Service page is a fundamental part of any company app. They may serve many functions & services. They present a prom to get in touch with the company, make it possible for them to make a purchase, as well as allow the company to collect the customer’s data.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`forgotPassPopup ${filterShowData ? 'active' : ''}`} onClick={forgotPassToggle} style={styles.forgotPassPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{filterShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Careers</span>
                  </div>
                  {filterShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p >A career screen, also called a career page/job board, is resource employers and job seekers use. It’s designed to helo an active candidate pool.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-16.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-17.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-18.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** changed one ***** */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`phoneVeriPopup ${
                        homeShowData ? "active" : ""
                      }`}
                      onClick={homeToggle}
                      style={styles.phoneVeriPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {homeShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Home Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(homeShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        In this screen, users can post & view their property
                        listings for selling & renting purposes based on their
                        location. Users can review the property details by
                        clicking on any one property. Users can mark any
                        property as a bookmark by clicking on the bookmark icon.
                        If agents want to post their property, they can click on
                        the button ‘Post your property’ & fill out the required
                        details.
                      </p>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${
                        PropertyShowData ? "active" : ""
                      }`}
                      onClick={propertyToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {PropertyShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Property Detail Page
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(PropertyShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        Following are the property details that users can see:
                      </p>
                      <ul>
                        <li>Property images (slider images)</li>
                        <li>Name of the property</li>
                        <li>Location</li>
                        <li>Property function</li>
                        <ul>
                          <li>Area</li>
                          <li>Type of rooms/bathrooms</li>
                          <li>Types of Furnishing</li>
                        </ul>
                        <li>Highlights</li>
                        <li>Property map</li>
                        <li>Send a message/call</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`forgotPassPopup ${
                        filterShowData ? "active" : ""
                      }`}
                      onClick={forgotPassToggle}
                      style={styles.forgotPassPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {filterShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Filter
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(filterShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        a. Buy
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        b. Rent
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        c. Commercial
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        d. Select city (drop-down)
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        e. Search option (Search up to 3 localities or
                        landmarks)
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        f. Rent range (price slider)
                      </p>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        g. Property type
                      </p>
                      <ul>
                        <li>House</li>
                        <li>Apartment</li>
                        <li>Showroom, etc.</li>
                      </ul>
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        h. Search CTA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-10.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-11.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-12.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner6;
