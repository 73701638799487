import React, { useState } from "react";
import App from "../../App.css";

const Banner10 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [helpShowData, setHelpShowData] = useState(true);
  const [settingShowData, setSettingShowData] = useState(false);
  const [profileShowData, setProfileShowData] = useState(false);

  const helpToggle = () => {
    setHelpShowData(!helpShowData);
    setSettingShowData(false);
    setProfileShowData(false);
  };

  const settingToggle = () => {
    setSettingShowData(!settingShowData);
    setHelpShowData(false);
    setProfileShowData(false);
  };

  const profileToggle = () => {
    setProfileShowData(!profileShowData);
    setHelpShowData(false);
    setSettingShowData(false);
  };
  const styles = {
    helpPopup: {
      backgroundColor: helpShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    settingPopup: {
      backgroundColor: settingShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    profilePopup: {
      backgroundColor: profileShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`helpPopup ${helpShowData ? 'active' : ''}`} onClick={helpToggle} style={styles.helpPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {helpShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Help</span>
                  </div>
                  {helpShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Help screen provides answers to common questions about your app. Users can submit comments, report bugs, and ask questions that are not already answered. To provide help for urgent issues, such as payments and other issues, place a Help icon or menu in the app bar.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`settingPopup ${settingShowData ? 'active' : ''}`} onClick={settingToggle} style={styles.settingPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{settingShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Settings</span>
                  </div>
                  {settingShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In many apps, we have seen the Settings screen which is most common in most of the apps. This settings screen is used to manage the preferences of the users. The ideal groups could be profile, change password, clear history, clear favourites, terms & conditions, privacy policy & push notifications.</p>
                    </div>
                  )}
                </div>
                <div className="m-3 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`profilePopup ${profileShowData ? 'active' : ''}`} onClick={profileToggle} style={styles.profilePopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{profileShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Profile</span>
                  </div>
                  {profileShowData && (
                    <div className="content mt-2 mb-2 backColor" >
                      <p>In this profile section, users will fill their basic details:</p>
                      <p>1. Full name</p>
                      <p>2. Email address</p>
                      <p>3. Date of birth</p>
                      <p>4. Mobile number with country code</p>
                      <p>Then click on submit button.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-28.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-29.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-30.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`helpPopup ${helpShowData ? "active" : ""}`}
                      onClick={helpToggle}
                      style={styles.helpPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {helpShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Help
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(helpShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Help screen provides answers to common questions about
                          your app. Users can submit comments, report bugs, and
                          ask questions that are not already answered. To
                          provide help for urgent issues, such as payments and
                          other issues, place a Help icon or menu in the app
                          bar.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`settingPopup ${
                        settingShowData ? "active" : ""
                      }`}
                      onClick={settingToggle}
                      style={styles.settingPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {settingShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Settings
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(settingShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In many apps, we have seen the Settings screen which
                          is most common in most of the apps. This settings
                          screen is used to manage the preferences of the users.
                          The ideal groups could be profile, change password,
                          clear history, clear favourites, terms & conditions,
                          privacy policy & push notifications.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`profilePopup ${
                        profileShowData ? "active" : ""
                      }`}
                      onClick={profileToggle}
                      style={styles.profilePopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {profileShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Profile
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(profileShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In this profile section, users will fill their basic
                          details:
                        </p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>1. Full name</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>2. Email address</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>3. Date of birth</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>4. Mobile number with country code</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>Then click on submit button.</p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-28.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-29.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-30.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner10;
