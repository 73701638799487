import React, { useState } from "react";
import App from "../../App.css";

const Banner8 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [homeShowData, setHomeShowData] = useState(true);
  const [PropertyShowData, setPropertyShowData] = useState(false);
  const [filterShowData, setfilterShowData] = useState(false);

  const homeToggle = () => {
    setHomeShowData(!homeShowData);
    setPropertyShowData(false);
    setfilterShowData(false);
  };

  const propertyToggle = () => {
    setPropertyShowData(!PropertyShowData);
    setHomeShowData(false);
    setfilterShowData(false);
  };

  const forgotPassToggle = () => {
    setfilterShowData(!filterShowData);
    setHomeShowData(false);
    setPropertyShowData(false);
  };
  const styles = {
    phoneVeriPopup: {
      backgroundColor: homeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: PropertyShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    forgotPassPopup: {
      backgroundColor: filterShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <div className="container  align-items-center justify-content-center d-flex mt-3">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`phoneVeriPopup ${homeShowData ? "active" : ""}`}
                    onClick={homeToggle}
                    style={styles.phoneVeriPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {homeShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Property For Buy</span>
                  </div>
                  {homeShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        There will be a list of all the properties for sale that
                        are available for purchase.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`loginPopup ${PropertyShowData ? "active" : ""}`}
                    onClick={propertyToggle}
                    style={styles.loginPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {PropertyShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>
                      Property For Rent
                    </span>
                  </div>
                  {PropertyShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        There will be a list of all the properties that are
                        available for renting purpose.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-3 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`forgotPassPopup ${
                      filterShowData ? "active" : ""
                    }`}
                    onClick={forgotPassToggle}
                    style={styles.forgotPassPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#FFAD01",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {filterShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>New Projects</span>
                  </div>
                  {filterShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>
                        Property owners have the permission to add new
                        properties along with their information.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-22.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-23.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-24.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`phoneVeriPopup ${
                        homeShowData ? "active" : ""
                      }`}
                      onClick={homeToggle}
                      style={styles.phoneVeriPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {homeShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Property For Buy
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(homeShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          There will be a list of all the properties for sale
                          that are available for purchase.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${
                        PropertyShowData ? "active" : ""
                      }`}
                      onClick={propertyToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {PropertyShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Property For Rent
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(PropertyShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          There will be a list of all the properties that are
                          available for renting purpose.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`forgotPassPopup ${
                        filterShowData ? "active" : ""
                      }`}
                      onClick={forgotPassToggle}
                      style={styles.forgotPassPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {filterShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        New Projects
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(filterShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Property owners have the permission to add new
                          properties along with their information.
                        </p>
                      </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-22.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-23.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-24.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner8;
