import React from "react";
import NotchServicesCards from "../Card/NotchServicesCards";

const NotchServices = () => {
  return (
    <section
      className="container-fluid py-5  "
      style={{
        backgroundColor: "#051643",
      }}
    >
      <div className=" text-center">
        <h3 className="text-white pb-3">See Our Top Notch Services</h3>
        <p
          className="text-white"
          style={{ fontWeight: "550", fontSize: "1.1rem" }}
        >
          The best in class services to fulfill all your digital needs
        </p>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className=" d-flex justify-content-center">
              <NotchServicesCards />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotchServices;
