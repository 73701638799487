import React from 'react';
import ReviewOne from '../Reviews/ReviewOne';

const HappyClient = ({ pageName }) => {

    return (
        <div>
            <h3 className='mt-3 text-center ' style={{ color: '#4BAEF6', fontWeight: 'bold'}}>Let’s hear what our happy clients say!</h3>
            <ReviewOne />
        </div>
    );
};

export default HappyClient;
