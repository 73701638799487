import React from "react";
import data from "../../data/db.json";

const StrategicProcess = ({ pageName }) => {
  const { StrategicProcess, list } = data[pageName];
  return (
    <>
      <section className="">
        <div className="container  mt-3">
          <h3
            className="text-center"
            style={{ color: "#4BAEF6", fontWeight: "bold" }}
          >
            {StrategicProcess?.heading}
          </h3>
          {StrategicProcess?.data?.map((item, key) => {
            return (
              <div key={key} className="container blackColor">
                <h4 style={{ fontWeight: "bold" }}>{item?.heading}</h4>
                <p className="my-3">{item?.desc}</p>
                {key !== StrategicProcess.length - 7 && <hr />}
              </div>
            );
          })}
          <div className="flex-column d-flex justify-content-center align-items-center ">
            <div
              className="p-3  badge"
              style={{ backgroundColor: "#F9A920", borderRadius: "15px" }}
            >
              {StrategicProcess?.linkButton}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StrategicProcess;
