import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

const NewsCard = (props) => {
    const { image, question, date, disc } = props;
    const imagePath = `/img/${image}.jpg`;
    
    return (
        <div className="card  news-card-style" style={{ width: "25rem" }}>
            <img src={imagePath} className="card-img-top" alt="..." style={{ height: "10rem" }} />
            <div className="card-body text-center">
                <p className="card-text" style={{fontSize: 20, color: "black"}}>{question}</p>
                <p className="py-3" style={{fontSize: 15}}>February 22nd, 2024</p>
                <p>{disc}</p>
            </div>
        </div>
    );          
};

export default NewsCard;
