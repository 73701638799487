import React from 'react';
import data from '../../data/db.json';

const BussinessCards = ({ pageName }) => {
    const { BussinessCards } = data[pageName];
    
    return (
        <div className="container mt-5 mb-5 flex-column d-flex justify-content-center align-items-center text-center">
            {BussinessCards?.data?.map((item, index) => {
                return (
                    <>
                        <h3 className='m-2'  style={{fontWeight:800}}>{item?.heading}</h3>
                        <p>{item?.desc}</p>
                        {item?.desc2 && <p className='pt-2'>{item?.desc2}</p>}
                        <div className="col-lg-12  pr-lg-5" key={index}>
                            <div className="blog blog-right">
                                <div className="main overflow-hidden">
                                    <div className="row">
                                        {item?.list?.map((list, index) => (
                                            <div key={index} className="col-lg-4 col-md-12 d-flex justify-content-center mt-3 mb-3">
                                                <div className='card' style={{borderTop:'10px solid #25ABFC'}}>
                                                    <div className='card-body align-items-center text-center shadow'>
                                                        <img src={`/img/${list?.image}`} alt="Image" />
                                                        <p style={{ fontWeight: 'bold' }}>{list?.heading}</p>
                                                        <p>{list?.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
            <div className='p-3 badge' style={{backgroundColor:'#F9A920',borderRadius:'15px',margin:10}}>{BussinessCards?.linkButton}</div>
        </div>
    );
};

export default BussinessCards;
