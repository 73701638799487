import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import jsonData from "../../data/db.json";

const MarketFAQ = ({ pageName }) => {
  // const questionData = jsonData[pageName]["customer-questions"];

  // const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(-1);

  // const toggleAnswerVisibility = (index) => {
  //   setVisibleAnswerIndex(index === visibleAnswerIndex ? -1 : index);
  // };

  const questionData = jsonData[pageName]["customer-questions"];

  const [visibleAnswerIndex, setVisibleAnswerIndex] = useState(0);
  const [maxHeight, setMaxHeight] = useState({});

  const answerRefs = useRef([]);

  const toggleAnswerVisibility = (index) => {
    setVisibleAnswerIndex(index === visibleAnswerIndex ? -1 : index);
  };

  useEffect(() => {
    if (visibleAnswerIndex !== -1) {
      const height = answerRefs.current[visibleAnswerIndex]?.scrollHeight;
      setMaxHeight((prevState) => ({
        ...prevState,
        [visibleAnswerIndex]: height,
      }));
    }
  }, [visibleAnswerIndex]);

  return (
    // <div className='bg-grey' >
    // <section className='container mt-5 p-4' >
    // <h3 className='mb-5' style={{color: "#444444"}}>Web Development Services FAQ</h3>
    // {questionData.map((item, index) => (
    //   <div key={index} style={{ marginBottom: "10px" }}>
    //     <div className='d-flex align-items-center p-3' style={{ border: "1px solid #eeeeee", borderRadius: "10px", backgroundColor: "#eeeeee", color: "#444", fontWeight: "900", lineHeight: "28px", gap: "5px" }} onClick={() => toggleAnswerVisibility(index)}>
    //       {visibleAnswerIndex === index ? <FaMinus /> : <FaPlus />}
    //       <p>{item.question}</p>
    //     </div>
    //     {visibleAnswerIndex === index && (
    //       <div className='d-flex align-items-center p-3' style={{ border: "1px solid #eeeeee", borderRadius: "10px" }}>
    //         <p>{item.answer}</p>
    //       </div>
    //     )}
    //   </div>
    // ))}
    // </section>
    // </div>

    // ****** Chnaged One ******

    <div className="bg-grey">
      <section className="container mt-5 p-4">
        <h3 className="mb-5" style={{ color: "#444444" }}>
          Web Development Services FAQ
        </h3>
        {questionData.map((item, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <div
              className="d-flex align-items-center p-3"
              style={{
                border: "1px solid #eeeeee",
                borderRadius: "10px",
                backgroundColor: "#eeeeee",
                color: "#444",
                fontWeight: "900",
                lineHeight: "28px",
                gap: "5px",
                cursor: "pointer",
              }}
              onClick={() => toggleAnswerVisibility(index)}
            >
              {visibleAnswerIndex === index ? <FaMinus /> : <FaPlus />}
              <p>{item.question}</p>
            </div>
            <div
              ref={(el) => (answerRefs.current[index] = el)}
              style={{
                maxHeight:
                  visibleAnswerIndex === index ? `${maxHeight[index]}px` : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease-out",
                border: "1px solid #eeeeee",
                borderRadius: "10px",
              }}
            >
              <div className="d-flex align-items-center p-3">
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default MarketFAQ;
