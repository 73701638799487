import React, { useState } from "react";
import App from "../../App.css";

const Banner1 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [splashShowData, setSplashShowData] = useState(true);
  const [welcomeShowData, setWelcomeShowData] = useState(false);
  const [signUpShowData, setSignUpShowData] = useState(false);

  const splashToggle = () => {
    setSplashShowData(!splashShowData);
    setWelcomeShowData(false);
    setSignUpShowData(false);
  };

  const welcomeToggle = () => {
    setWelcomeShowData(!welcomeShowData);
    setSplashShowData(false);
    setSignUpShowData(false);
  };

  const signupToggle = () => {
    setSignUpShowData(!signUpShowData);
    setSplashShowData(false);
    setWelcomeShowData(false);
  };
  const styles = {
    splashPopup: {
      backgroundColor: splashShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: welcomeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    fpwordPopup: {
      backgroundColor: signUpShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <h2>CUSTOMER MODULE</h2>
          <div className='container  align-items-center justify-content-center d-flex mt-5'>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-1.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-2.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-3.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`splashPopup ${splashShowData ? 'active' : ''}`} onClick={splashToggle} style={styles.splashPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {splashShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Splash Screen</span>
                  </div>
                  {splashShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>A splash screen will appear on the first screen of the application. It will be visible for 3-4 seconds. A splash screen is usually used to enhance the look and feel of an application or website. It’s an attractive way to add some excitement to the user’s experience. It is possible to include animation, graphics, and sound in them as well.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`loginPopup ${welcomeShowData ? 'active' : ''}`} onClick={welcomeToggle} style={styles.loginPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{welcomeShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Welcome Screen</span>
                  </div>
                  {welcomeShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li>• New user can create an account.</li>
                        <li>• If already a user, then enter your credentials.</li>
                        <li>• Guest user can skip registration.</li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`fpwordPopup ${signUpShowData ? 'active' : ''}`} onClick={signupToggle} style={styles.fpwordPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{signUpShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Sign Up Screen</span>
                  </div>
                  {signUpShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>As part of the signup process, users need to enter their personal details such as their Full Name, their Date of Birth, their Email Address, their Mobile Number, and the password, and accept the Terms and Conditions (click the checkbox). In order to verify your submission, click on the submit button.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <h2 style={{ textAlign: "center" }}>CUSTOMER MODULE</h2>
          <div
            className="  align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-1.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-2.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-3.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`splashPopup ${
                        splashShowData ? "active" : ""
                      }`}
                      onClick={splashToggle}
                      style={styles.splashPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {" "}
                        {splashShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Splash Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2 backColor"
                      style={styles.content(splashShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        A splash screen will appear on the first screen of the
                        application. It will be visible for 3-4 seconds. A
                        splash screen is usually used to enhance the look and
                        feel of an application or website. It’s an attractive
                        way to add some excitement to the user’s experience. It
                        is possible to include animation, graphics, and sound in
                        them as well.
                      </p>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${
                        welcomeShowData ? "active" : ""
                      }`}
                      onClick={welcomeToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {welcomeShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Welcome Screen
                      </span>
                    </div>
                      <div
                        className="content mt-2 mb-2 backColor"
                        style={styles.content(welcomeShowData)}
                      >
                        <ul
                          style={{ listStyleType: "disc", paddingLeft: "20px" }}
                        >
                          <li style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                            • New user can create an account.
                          </li>
                          <li style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                            • If already a user, then enter your credentials.
                          </li>
                          <li style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                            • Guest user can skip registration.
                          </li>
                        </ul>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`fpwordPopup ${
                        signUpShowData ? "active" : ""
                      }`}
                      onClick={signupToggle}
                      style={styles.fpwordPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {signUpShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        Sign Up Screen
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(signUpShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          As part of the signup process, users need to enter
                          their personal details such as their Full Name, their
                          Date of Birth, their Email Address, their Mobile
                          Number, and the password, and accept the Terms and
                          Conditions (click the checkbox). In order to verify
                          your submission, click on the submit button.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner1;
