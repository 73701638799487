import React from "react";
import HeaderOne from "../Header/HeaderOne";
import SEOCaseBreadcrumb from "../Breadcrumb/BreadcrumbForSEOmarket";
import FooterOne from "../Footer/FooterOne";

const SeoCaseStudies = () => {
  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <SEOCaseBreadcrumb />
      {/* <section className=''>
        <div className='py-5 text-center' style={{backgroundColor: "rgb(36 92 243)"}}>
          <h3 className='text-white'> SEO Case Studies</h3>
        </div>

        <div className='container py-4'>
            <div className=' text-center mt-4 '>
                <h3>1. Cosmetic and Plastic Surgery</h3>
                <p>Before taking SEO Services from Aaban Solutions, Organic traffic on my website was 25,785/Month in Jul'19, After taking SEO Services from Aaban Solutions. Traffic is increasing on my website each month, Now in Jul'21 Organic traffic is 46,550/Month. Traffic has increased by 80.53%. </p>
            </div>
            <div className='d-flex justify-content-center align-items-center col-md-12 mt-4' >
                <div className='col-md-6'>
                    <p><strong>Overall Traffic:</strong></p>
                    <img src='/img/sales-performance-dashboard.png'  alt='overall traffic' />
                </div>
                <div className='col-md-6'>
                    <p><strong>Overall Traffic:</strong></p>
                    <img src='/img/sales-performance-dashboard.png'  alt='overall traffic' />
                </div>
            </div>
        </div>

        <div className='bg-grey py-4'>
        <div className='container'>
            <div className=' text-center mt-4 '>
                <h3>2. Limo Service San Diego</h3>
                <p>Before taking SEO Services from Aaban Solutions, Organic traffic on my website was 588/Month in Jul'20, After taking SEO Services from Aaban Solutions. Traffic is increasing on my website each month, Now in Jul'21 Organic traffic is 1,380/Month. Traffic has increased by 134.69%.</p>
            </div>
            <div className='d-flex col-md-12 mt-4'>
                <div className='col-md-6'>
                    <p><strong>Overall Traffic:</strong></p>
                    <img src='/img/sales-performance-dashboard.png'  alt='overall traffic' />
                </div>
                <div className='col-md-6'>
                    <p><strong>Overall Traffic:</strong></p>
                    <img src='/img/sales-performance-dashboard.png'  alt='overall traffic' />
                </div>
            </div>
        </div>
        </div>
    </section> */}

      {/* ***** Changed one ***** */}
      <section className="">
        <div
          className="py-5 text-center"
          style={{ backgroundColor: "rgb(36 92 243)" }}
        >
          <h3 className="text-white">SEO Case Studies</h3>
        </div>

        <div className="container-fluid py-4" >
          <div className="text-center mt-4">
            <h3>1. Cosmetic and Plastic Surgery</h3>
            <p style={{fontSize:"1.2rem"}}>
              Before taking SEO Services from Aaban Solutions, Organic traffic
              on my website was 25,785/Month in Jul'19. After taking SEO
              Services from Aaban Solutions, traffic is increasing on my website
              each month. Now in Jul'21, organic traffic is 46,550/Month.
              Traffic has increased by 80.53%.
            </p>
          </div>
          <div
            className="row justify-content-center align-items-center mt-4 "
          >
            <div
              className="col-12 col-md-6 mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>
                <strong>Overall Traffic:</strong>
              </p>
              <img
                src="/img/sales-performance-dashboard.png"
                alt="overall traffic"
                className="img-fluid"
                style={{ width: "90%" }}
              />
            </div>
            <div
              className="col-12 col-md-6 mb-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ fontSize: "1.4rem" }}>
                <strong>Overall Traffic:</strong>
              </p>
              <img
                src="/img/sales-performance-dashboard.png"
                alt="overall traffic"
                className="img-fluid"
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </div>

        <div className="bg-grey py-4">
          <div className="container-fluid">
            <div className="text-center mt-4">
              <h3>2. Limo Service San Diego</h3>
              <p style={{fontSize:"1.2rem"}}>
                Before taking SEO Services from Aaban Solutions, organic traffic
                on my website was 588/Month in Jul'20. After taking SEO Services
                from Aaban Solutions, traffic is increasing on my website each
                month. Now in Jul'21, organic traffic is 1,380/Month. Traffic
                has increased by 134.69%.
              </p>
            </div>
            <div className="row mt-4">
              <div
                className="col-12 col-md-6 mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p style={{ fontSize: "1.4rem" }}>
                  <strong>Overall Traffic:</strong>
                </p>
                <img
                  src="/img/sales-performance-dashboard.png"
                  alt="overall traffic"
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
              <div
                className="col-12 col-md-6 mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p style={{ fontSize: "1.4rem" }}>
                  <strong>Overall Traffic:</strong>
                </p>
                <img
                  src="/img/sales-performance-dashboard.png"
                  alt="overall traffic"
                  className="img-fluid"
                  style={{ width: "90%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />
    </>
  );
};

export default SeoCaseStudies;
