import React, { useEffect, useState } from 'react';
import jsonData from "../../data/db.json";

const AgencyCards = ({ pageName }) => {
  const [agencyCards, setAgencyCards] = useState([]);

  useEffect(() => {
    if (jsonData[pageName] && jsonData[pageName]["agency-cards"]) {
      setAgencyCards(jsonData[pageName]["agency-cards"]);
    }
  }, [pageName]);

  return (
    <div className="row">
      {agencyCards.map((card, index) => (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-3" key={index}>
          <div className="">
            <div className="card-img-top text-center AgencyCardsIconContainer">
              <img src={`/img/${card.image}`} style={{ width: "100px", height: "100px", borderRadius: "50%", margin: "0 auto" }} alt="icon" />
            </div>
            <div className="card-body text-center">
              <h5 className="card-title" style={{ fontSize: "18px" }}>{card.heading}</h5>
              <p className="card-text">
                {card.content}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgencyCards;
