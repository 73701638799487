import React from "react";
import AgencyCards from "./AgencyCards";

const AgencyCardsSection = ({ pageName }) => {
  return (
    <>
    <div className="container text-center mt-5 mb-3">
      <div className="mb-5">
        <h3>Why Choose Us As Your Web Development USA Agency?</h3>
        <div className="d-flex justify-content-center my-3">
          <hr style={{ border: "2px solid #faa738", width: "10%" }} />
        </div>
        <p>
          Investing in Aaban solution’s web design & development services
          ensures your business reaches a target audience and drives the best
          results cost-effectively. We’ll help you create custom digital
          solutions that look great, enhance the user experience, generate
          leads, drive conversions, and boost revenue for your business.
        </p>
      </div>
    </div>
    <div className='container d-flex' style={{gap: "10px"}}>
    <AgencyCards pageName={pageName}/>
    </div>
    </>
  );
};

export default AgencyCardsSection;
