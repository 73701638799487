export const navigationMenu = [
  {
    id: 1,
    name: "Services",
    menu: [
      {
        id: 1,
        menuName: "Digital Marketing",
        subMenu: [
          { id: 1, subMenuName: "Search Engine Optimization", link: "/digital-marketing/seo-services-usa" },
          { id: 2, subMenuName: "App Store Optimization", link: "/digital-marketing/app-store-optimization-services" },
          { id: 3, subMenuName: "Online Reputation Management", link: "/digital-marketing/online-reputation-management-services" },
          { id: 4, subMenuName: "Small Business SEO", link: "/digital-marketing/affordable-seo-services-for-small-business" },
          { id: 5, subMenuName: "E-commerce SEO", link: "/digital-marketing/ecommerce-seo-services" },
          { id: 6, subMenuName: "Local SEO Services", link: "/digital-marketing/local-seo-services" },
          { id: 7, subMenuName: "White Label SEO", link: "/digital-marketing/app-store-optimization-services" },
          { id: 8, subMenuName: "YouTube Optimization", link: "/digital-marketing/youtube-seo-services" },
        ],
      },
      {
        id: 2,
        menuName: "Web Development",
        subMenu: [
          { id: 1, subMenuName: "Ecommerce Development", link: "/digital-marketing/ecommerce-website-design" },
          { id: 2, subMenuName: "Magento Web Development", link: "/digital-marketing/magento-website-development" },
          { id: 3, subMenuName: "Joomla Web Development", link: "/digital-marketing/joomla-website-design" },
          { id: 4, subMenuName: "Mobile App Development", link: "/digital-marketing/mobile-app-development" },
          { id: 5, subMenuName: "Payment Gateway", link: "/digital-marketing/payment-gateway-integration" },
          { id: 6, subMenuName: "Shopify Web Development", link: "/digital-marketing/shopify-website-design" },
          { id: 7, subMenuName: "Woocommerce Development", link: "/digital-marketing/woocommerce-web-design" },
          { id: 8, subMenuName: "WordPress Development", link: "/digital-marketing/wordpress-website-design" },
          { id: 9, subMenuName: "Website Re-Designing", link: "/digital-marketing/website-redesign-service" },

        ],
      },
      {
        id: 3,
        menuName: "Digital Advertising",
        subMenu: [
          { id: 1, subMenuName: "Pay Per Click", link: "/digital-marketing/pay-per-click-ppc" },
          { id: 2, subMenuName: "Facebook Marketing", link: "/digital-marketing/facebook-advertising-services" },
          { id: 3, subMenuName: "Instagram Marketing", link: "/digital-marketing/instagram-advertising" },
          { id: 4, subMenuName: "Linkedin Marketing", link: "/digital-marketing/linkedin-advertising-services" },
          { id: 5, subMenuName: "X Marketing", link: "/digital-marketing/x-twitter-advertising-services" },
          { id: 6, subMenuName: "Content Marketing", link: "/digital-marketing/content-marketing-services" },
          { id: 7, subMenuName: "Pinterest Marketing", link: "/digital-marketing/pinterest-advertising-agency" },
          { id: 8, subMenuName: "GMB Optimization", link: "/digital-marketing/google-business-profile-optimization" },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Market",
    menu: [
      {
        id: 1,
        menuName: "Automobile & Home ",
        subMenu: [
          { id: 1, subMenuName: "Aviation Industry", link: '/seo-market/aviation-seo-services' },
          { id: 2, subMenuName: "Automotive Industry", link: '/seo-market/automotive-seo-services' },
          { id: 3, subMenuName: "Home Remodeler", link: '/seo-market/home-remodeling-seo-services' },
          { id: 4, subMenuName: "House Cleaning", link: '/seo-market/cleaning-seo-services' },
          { id: 5, subMenuName: "Construction", link: '/seo-market/construction' },
          // { id: 6, subMenuName: "Insurance", link: '/seo-market/insurance-marketing' },
          { id: 7, subMenuName: "Furniture Industry", link: '/seo-market/seo-for-furniture-stores' },
          { id: 8, subMenuName: "Entertainment Industry", link: '/seo-market/entertainment-marketing' },
          { id: 9, subMenuName: "Logistic SEO", link: '/seo-market/logistic-seo' },
          { id: 10, subMenuName: "Real Estate SEO", link: '/seo-market/real-estate-seo' },

        ],
      },
      {
        id: 2,
        menuName: "Food & Health ",
        subMenu: [
          { id: 1, subMenuName: "SEO for Restaurant", link: '/seo-market/restaurant-marketing' },
          { id: 2, subMenuName: "Agriculture Industry", link: '/seo-market/agriculture-seo-agency' },
          { id: 3, subMenuName: "Fitness Industry", link: '/seo-market/fitness-seo-services' },
          { id: 4, subMenuName: "Kitchen Remodeler", link: '/seo-market/seo-for-kitchen-designers' },
          { id: 5, subMenuName: "Healthcare SEO", link: '/seo-market/healthcare-seo-services' },
          { id: 6, subMenuName: "Catering Business", link: '/seo-market/catering-seo' },
          { id: 7, subMenuName: "Yoga SEO", link: '/seo-market/yoga-marketing' },
          { id: 8, subMenuName: "Dental SEO", link: '/seo-market/dental-seo' },
          { id: 9, subMenuName: "Pharma SEO", link: '/seo-market/pharma-seo' },
        ],
      },
      {
        id: 3,
        menuName: "Service Sector",
        subMenu: [
          { id: 1, subMenuName: "Daycare SEO", link: '/seo-market/daycare-seo' },
          { id: 2, subMenuName: "HVAC SEO", link: '/seo-market/hvac-digital-marketing' },
          { id: 3, subMenuName: "SEO for Contractors", link: '/seo-market/seo-for-contractors' },
          { id: 4, subMenuName: "Fire Protection", link: '/seo-market/fire-protection' },
          // { id: 5, subMenuName: "Financial SEO", link: '/seo-market/financial-seo' },
          { id: 6, subMenuName: "Pest Control SEO", link: '/seo-market/pest-control-seo' },
          { id: 7, subMenuName: "SEO for Sallons", link: '/seo-market/seo-for-salons' },
          { id: 8, subMenuName: "Veterinary SEO", link: '/seo-market/veterinary-seo' },
          { id: 9, subMenuName: "Appliance Repair", link: '/seo-market/appliance-repair' },
          { id: 10, subMenuName: "Plumbing SEO", link: '/seo-market/plumbing-seo' },
        ],
      },
      {
        id: 4,
        menuName: "Others",

        subMenu: [
          { id: 1, subMenuName: "Cannabis SEO", link: '/seo-market/cannabis-seo' },
          { id: 2, subMenuName: "Education Industry", link: '/seo-market/education-marketing' },
          { id: 3, subMenuName: "Jewelry Industry", link: '/seo-market/jewelry-marketing' },
          { id: 4, subMenuName: "Retail SEO", link: '/seo-market/retail-seo' },
          { id: 5, subMenuName: "Travel SEO", link: '/seo-market/travel-seo' },
          { id: 6, subMenuName: "Hospitality SEO", link: '/seo-market/hospitality-seo' },
          { id: 7, subMenuName: "Tree Services", link: '/seo-market/tree-services' },
          { id: 8, subMenuName: "Photographers", link: '/seo-market/seo-for-photographers' },
          { id: 9, subMenuName: "Packers & Movers", link: '/seo-market/packers-movers' },
          { id: 10, subMenuName: "Lawyer SEO", link: '/seo-market/lawyer-seo' },
        ],
      },
    ],
  },
  // {
  //   id: 3,
  //   name: "Pricing",
  //   menu: [
  //     {
  //       id: 1,
  //       menuName: "SEO Packages",
  //     },
  //     {
  //       id: 2,
  //       menuName: "SMO Packages",
  //       subMenu: [
  //         { id: 1, subMenuName: "Maintenance Plans", link: '/pricing/maintenance' },
  //         { id: 2, subMenuName: "Structural Setup", link: '/pricing/smo-packages/one-time-structural-set-up' },
  //       ]
  //     },
  //     {
  //       id: 3,
  //       menuName: "PPC Packages",
  //     },
  //     {
  //       id: 4,
  //       menuName: "Amzon Ads",
  //     },
  //     {
  //       id: 5,
  //       menuName: "Web Packages",
  //     },
  //   ]
  // }

  // {

  //   id: 4,
  //   name: "App",
  //   menu: [
  //     {
  //       id: 1,
  //       menuName: "Food Delivery",

  //     },
  //     {
  //       id: 2,
  //       menuName: "Real Estate",
  //     }
  //   ]
  // },
  // {
  //   id: 5,
  //   name: "Portfolio",
  // },
  // {
  //   id: 6,
  //   name: "About Us",

  // },
  // {
  //   id: 7,
  //   name: "Testimonial",

  // },
  // {
  //   id: 8,
  //   name: "Blog",
  // },
];