import React from "react";
import { FaUsers, FaTools, FaHotel, FaRegWindowMaximize } from "react-icons/fa";

const RealEstateCards = () => {
  return (
    <>
      {/* <section className='bg-grey'>
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <h2>STAKE HOLDERS</h2>
          <div className='container mt-5'>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaUsers size={55} color="#faa738" />
                    <a href="#" class="text-black">
                      <strong>Go somewhere</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaTools size={52} color="#faa738" />
                    <a href="#" class="text-black">
                      <strong>Drivers</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** chnaged One *****  */}

      <section
        className="bg-grey"
        style={{
          backgroundImage: "url(/img/dotted.png)",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div
          className="container-fluid flex-column d-flex justify-content-center align-items-center pt-4"
        >
          <h2>STAKE HOLDERS</h2>
          <div
            className="container-fluid mt-4"
          >
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                <div class="card" style={{ width: "100%", height: "250px" }}>
                  <div
                    class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded"
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: "60px 0px",
                    }}
                  >
                    <FaUsers
                      size={70}
                      color="#faa738"
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #faa738",
                        borderRadius: "50px",
                        padding: "5px",
                      }}
                    />
                    <a href="#" class="text-black">
                      <strong>Go somewhere</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                <div class="card" style={{ width: "100%", height: "250px" }}>
                  <div
                    class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded"
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: "60px",
                    }}
                  >
                    <FaTools
                      size={70}
                      color="#faa738"
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #faa738",
                        borderRadius: "50px",
                        padding: "5px",
                      }}
                    />
                    <a href="#" class="text-black">
                      <strong>Drivers</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RealEstateCards;
