import React from 'react';
import WebCard from './WebCard';

const WebDevelopmentCardsection = ({pageName}) => {
  return (
    <div className='container text-center'>
      <div className='row'>
       <WebCard pageName={pageName}/>
      </div>
    </div>
  );
};

export default WebDevelopmentCardsection;
