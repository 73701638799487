import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BreadcrumbForServicepage = (props) => {
    const { heading, path, title, desc, image, children } = props;
    const navigation = useNavigate()

    const sectionStyle = image ? {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    } : {};

    const isImage = image ? "isImage-style" : "";

    return (
        <section className={`section breadcrumb-area overlay-dark ${isImage} d-flex align-items-center`} style={{height: "95px", backgroundColor: "black"}}>
    
        </section>
    );
};

export default BreadcrumbForServicepage;
