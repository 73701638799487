import React, { Children } from "react";
import Form from "./Form";

const FormSection = (props) => {
  const { children, formHeading } = props;

  return (
    <>
      {/* <section id="contact" className="contact-area bg-grey  ptb_50">
        <div className="container pt-4">
          <div className="row justify-content-between align-items-center form-section-gap">
            {children}
            <Form  formHeading={formHeading}/>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section
      
        id="contact"
        className="contact-area bg-grey ptb_50"
        style={{
          position: "relative",
          overflow: "hidden",
          // backgroundImage: `
          // linear-gradient(

          //   rgb(150 14 14 / 0%), white
          // ),
          // url(/img/form-back.png)`,
          backgroundImage: `
          linear-gradient(
            145deg,
            white,
            #df30c824
          ), 
          url(/img/form-back.png)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div
          className="container pt-4"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div className="row justify-content-between align-items-center form-section-gap">
            {children}
            <Form formHeading={formHeading} />
          </div>
        </div>
      </section>
    </>
  );
};

export default FormSection;
