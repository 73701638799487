import React from 'react';
import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderOne';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbOne';
import ServiceSection from '../../Services/ServiceSeven';
import ReviewSection from '../../Reviews/ReviewOne';
import ContactSection from '../../Contact/ContactOne';
import CtaSection from '../../Cta/Cta';
import FooterSection from '../../Footer/FooterOne';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';
import BreadcrumbThree from '../../Breadcrumb/BreadcrumbThree';

const Services = () => {
    return (
        <div>
            <ScrollupSection />
            <div className="main overflow-hidden">
                <Header  imageData={"/img/aaban-text-logo.png"} />
                <BreadcrumbThree
                    heading={"What We Offer"}
                    path={"What We Offer"}
                    title={"What We Offer"}
                    image={"/img/pexels-tara-winstead-8386440.jpg"}
                    />
                <ServiceSection />
                <ReviewSection />
                <ContactSection />
                <CtaSection />
                <FooterSection />
                <ModalSearchSection />
                <ModalMenuSection />
            </div>
        </div>
    );
}

export default Services;
