import React, { useState } from "react";
import App from "../../App.css";

const Banner5 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [locationShowData, setLocationShowData] = useState(true);
  const [postPropShowData, seTpostPropShowData] = useState(false);
  const [emptyPropShowData, setEmptyPropShowData] = useState(false);

  const locationToggle = () => {
    setLocationShowData(!locationShowData);
    seTpostPropShowData(false);
    setEmptyPropShowData(false);
  };

  const postPropToggle = () => {
    seTpostPropShowData(!postPropShowData);
    setLocationShowData(false);
    setEmptyPropShowData(false);
  };

  const emptyPropToggle = () => {
    setEmptyPropShowData(!emptyPropShowData);
    setLocationShowData(false);
    seTpostPropShowData(false);
  };
  const styles = {
    locationPopup: {
      backgroundColor: locationShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    postPropPopup: {
      backgroundColor: postPropShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    emptyPropPopup: {
      backgroundColor: emptyPropShowData ? "#6CB4EE" : null,
      padding: 5,
      
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-13.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-14.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-15.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`locationPopup ${locationShowData ? 'active' : ''}`} onClick={locationToggle} style={styles.locationPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {locationShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Location</span>
                  </div>
                  {locationShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>By clicking on use current location button, it allow the app to access the device’s location. It will give 3 options:</p>
                      <p>1. While using the app</p>
                      <p>2. Only this time</p>
                      <p>3. Don’t allow</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`postPropPopup ${postPropShowData ? 'active' : ''}`} onClick={postPropToggle} style={styles.postPropPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{postPropShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Post Your Property</span>
                  </div>
                  {postPropShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In this screen, users can post their properties, location & the type of property to proceed with the property search result.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`emptyPropPopup ${emptyPropShowData ? 'active' : ''}`} onClick={emptyPropToggle} style={styles.emptyPropPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{emptyPropShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Empty Property</span>
                  </div>
                  {emptyPropShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In case of empty property, users need to fill in the below details:</p>
                      <p>1. Number of rooms & bedrooms</p>
                      <p>2. Amenities</p>
                      <p>a. Bedroom/bathrooms</p>
                      <p>b. Living room</p>
                      <p>c. Kitchen</p>
                      <p>d. Parking area</p>
                      <p>e. Front/back yard</p>
                      <p>f. Balcony</p>
                      <p>g. Gym</p>
                      <p>3. Preferred tenants</p>
                      <p>a. Family</p>
                      <p>b. Bachelor</p>
                      <p>c. Company</p>
                      <p>4. Enter your budget</p>
                      <p>5. Proceed CTA</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** changed One ***** */}
      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="  align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-13.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-14.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-15.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`locationPopup ${
                        locationShowData ? "active" : ""
                      }`}
                      onClick={locationToggle}
                      style={styles.locationPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {" "}
                        {locationShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Location</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(locationShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          By clicking on use current location button, it allow
                          the app to access the device’s location. It will give
                          3 options:
                        </p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          1. While using the app
                        </p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          2. Only this time
                        </p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          3. Don’t allow
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`postPropPopup ${
                        postPropShowData ? "active" : ""
                      }`}
                      onClick={postPropToggle}
                      style={styles.postPropPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {postPropShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>
                        Post Your Property
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(postPropShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In this screen, users can post their properties,
                          location & the type of property to proceed with the
                          property search result.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`emptyPropPopup ${
                        emptyPropShowData ? "active" : ""
                      }`}
                      onClick={emptyPropToggle}
                      style={styles.emptyPropPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {emptyPropShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Empty Property</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(emptyPropShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In case of empty property, users need to fill in the
                          below details:
                        </p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }} >1. Number of rooms & bedrooms</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>2. Amenities</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>a. Bedroom/bathrooms</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>b. Living room</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>c. Kitchen</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>d. Parking area</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>e. Front/back yard</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>f. Balcony</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>g. Gym</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>3. Preferred tenants</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>a. Family</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>b. Bachelor</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>c. Company</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>4. Enter your budget</p>
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>5. Proceed CTA</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner5;
