import React, { useState } from "react";
import App from "../../App.css";

const Banner9 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [propShowData, setPropShowData] = useState(true);
  const [contactShowData, setContactShowData] = useState(false);
  const [feedbackShowData, setFeedbackShowData] = useState(false);

  const propToggle = () => {
    setPropShowData(!propShowData);
    setContactShowData(false);
    setFeedbackShowData(false);
  };

  const contactToggle = () => {
    setContactShowData(!contactShowData);
    setPropShowData(false);
    setFeedbackShowData(false);
  };

  const feedbackToggle = () => {
    setFeedbackShowData(!feedbackShowData);
    setPropShowData(false);
    setContactShowData(false);
  };
  const styles = {
    propPopup: {
      backgroundColor: propShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    contactPopup: {
      backgroundColor: contactShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    feedbackPopup: {
      backgroundColor: feedbackShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey'  >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-25.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-26.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-27.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`propPopup ${propShowData ? 'active' : ''}`} onClick={propToggle} style={styles.propPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {propShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Property Saved</span>
                  </div>
                  {propShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>To save a property within your app, you first need to get the details of the property. From here, go into the property details. Now click on the bookmark icon at the top right of the property details & tap on it. This will now indicate that the property has been saved. Once a property has been saved, it will reflect on the property saved page of the mobile app.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`contactPopup ${contactShowData ? 'active' : ''}`} onClick={contactToggle} style={styles.contactPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{contactShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Property Contacted</span>
                  </div>
                  {contactShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>To contact a property owner, you first need to get the details of the property along with the contact details of the owner. From here, go into the contact details. When you will come in the contact with the property owner then this will indicate that the property has been contacted. Once a property has been contacted, it will reflect on the property contacted page of the mobile app.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`feedbackPopup ${feedbackShowData ? 'active' : ''}`} onClick={feedbackToggle} style={styles.feedbackPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{feedbackShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Feedback</span>
                  </div>
                  {feedbackShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Feedback act as a tool through which customers feel that they have importance and value. One needs to rectify their shortcomings, and that is possible only by analysis of genuine feedback from customers.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** changed one *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0 ">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-25.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-26.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-27.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`propPopup ${propShowData ? "active" : ""}`}
                      onClick={propToggle}
                      style={styles.propPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {propShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Property Saved</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(propShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          To save a property within your app, you first need to
                          get the details of the property. From here, go into
                          the property details. Now click on the bookmark icon
                          at the top right of the property details & tap on it.
                          This will now indicate that the property has been
                          saved. Once a property has been saved, it will reflect
                          on the property saved page of the mobile app.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`contactPopup ${
                        contactShowData ? "active" : ""
                      }`}
                      onClick={contactToggle}
                      style={styles.contactPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {contactShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>
                        Property Contacted
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(contactShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          To contact a property owner, you first need to get the
                          details of the property along with the contact details
                          of the owner. From here, go into the contact details.
                          When you will come in the contact with the property
                          owner then this will indicate that the property has
                          been contacted. Once a property has been contacted, it
                          will reflect on the property contacted page of the
                          mobile app.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`feedbackPopup ${
                        feedbackShowData ? "active" : ""
                      }`}
                      onClick={feedbackToggle}
                      style={styles.feedbackPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {feedbackShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Feedback</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(feedbackShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Feedback act as a tool through which customers feel
                          that they have importance and value. One needs to
                          rectify their shortcomings, and that is possible only
                          by analysis of genuine feedback from customers.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner9;
