import React from 'react';
import jsonData from '../../data/db.json'; 

const Industries = ({pageName}) => {
  const industriesData = jsonData[pageName]['Industries'];

  return (
    <div className="container my-5"  >
      <div className="container text-center mt-5">
        <div className="">
          <h3 className="m-2">Some Of The Industries We Work With</h3>
          <div className="d-flex justify-content-center ">
            <hr style={{ border: "2px solid #faa738", width: "10%" }} />
          </div>
        </div>
      </div>
      <div className="row"  >
        <div className="col">
          <div className="row" >
            {industriesData.map((industry, index) => (
              <div key={index} className="col-md-3 " style={{border: "1px solid rgb(233, 233, 233)"}}>
                <div className="d-flex flex-column justify-content-center align-items-center p-4">
                  <img src={`/img/${industry.image}`} alt={`Industry ${index + 1}`} style={{ maxWidth: '100%' }} />
                  <p style={{fontWeight: "800", padding: "5px"}}>{industry.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
