import React from 'react';
import MyRouts from './routers/routes';

// importing MyRouts where we located all of our theme

function App() {
  return (
    <div>
      <MyRouts />
    </div>
  );
}

export default App;