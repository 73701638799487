import React from "react";
import data from "../../data/db.json";

const WeUsedAdvancedSEO = ({ pageName }) => {
  const { WeUsedAdvancedSEO } = data[pageName];

  return (
    <div
      className="container mt-5 mb-5 flex-column d-flex justify-content-center align-items-center text-center"
    
    >
      <>
        <h3 className="m-2" style={{ color: "#4BAEF6", fontWeight: 800 }}>
          We Use Advance SEO Tools
        </h3>
        <div >
          <img
            src={"/img/usedAdv-1.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-2.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-3.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-4.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-5.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-6.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-7.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-8.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-9.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-10.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-11.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
          <img
            src={"/img/usedAdv-12.webp"}
            alt="Image"
            className="col-lg-2 m-3"
            style={{ height: "5rem", width: "22rem" }}
          />
        </div>
      </>
    </div>
  );
};

export default WeUsedAdvancedSEO;
