import React, { useEffect, useState } from 'react'
import HeaderOne from '../components/Header/HeaderOne'
import BreadcrumbThree from '../components/Breadcrumb/BreadcrumbThree'
import FoodDelivery from '../components/NavApp/FoodDelivery'
import FooterOne from '../components/Footer/FooterOne'
import RealEstate from '../components/NavApp/RealEstate'

const AppSection = ({ pageName }) => {
  const [coverImage, setCoverImage] = useState();

  let renderPage1;
   if (pageName === "food-delivery-application") {
    renderPage1 = <FoodDelivery pageName={pageName} />
  } else {
    renderPage1 = <RealEstate />
  }

  const [path, setPath] = useState("")
  
  useEffect(() => {
    if (pageName === "food-delivery-application") {
      setPath("Food delivery application");
      setCoverImage("/img/food-delivery-companies.jpg");
    } else {
      setPath("Real Estate Application")
      setCoverImage("/img/real-estate-cover.png");
    }
  }, []) 

  return (
    <>
      <HeaderOne
        imageData={"/img/aaban-text-logo.png"}
      />
      <BreadcrumbThree
        heading={pageName}
        path={path}
        title={pageName}
        image={coverImage}
      />
      <div style={{backgroundColor: "rgb(5 22 67)" , height: "60px"}}>Hello</div>
      {renderPage1}
      <FooterOne />
    </>
  )
  }

export default AppSection