import React, { useState, useEffect } from "react";
import axios from "axios";
import data from "../../data/db.json";
console.log("themeOnePricingSection", data.themeOnePricingSection.pricingData);

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOnePricingSection";

const initData = {
  text_1: "Haven't find suitable answer?",
  text_2: "Tell us what you need.",
};

const PricingForHomePage = () => {
  const [initData, setInitData] = useState({});
  const [pricingData, setPricingData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [faqData_1, setFaqData_1] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setInitData(initData);
        setPricingData(res.data.pricingData);
        setFaqData(faqData);
        setFaqData_1(faqData_1);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    // <section
    //   id="pricing"
    //   className="section price-plan-area overflow-hidden ptb_50 home-pricing-boxes "
    //
    // >
    //   <div>
    //     <div
    //       className="row justify-content-center"

    //       >
    //       <div className="col-12 col-md-10 col-lg-7">
    //         {/* Section Heading */}
    //         <div className="section-heading text-center">
    //           <h2>Our Plans</h2>
    //           <p className="d-none d-sm-block mt-4">
    //             Experience exponential growth with Aaban Solution's
    //             result-driven digital marketing strategies. Experience
    //             unparalleled growth like never before!{" "}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div></div>
    //     <div className="d-flex justify-content-center align-items-center px-5">
    //       <div className="row price-plan-wrapper justify-content-center">
    //         {data.themeOnePricingSection.pricingData.map((item, idx) => (
    //           <div key={`pro_${idx}`} className={item.colClass}>
    //             <div
    //               className="single-price-plan color-1 bg-hover hover-top text-center p-4"
    //               style={{
    //                 width: "318px",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 alignItems: "center",
    //                 border: `8px solid ${item.borderColor}`,
    //               }}
    //             >
    //               <div className="plan-title mb-2 mb-sm-3">
    //                 <div className="d-flex align-items-center">
    //                   <h3 style={{ color: item.titleColor }} className="mb-2">
    //                     {item.title}
    //                   </h3>
    //                   {item.price_badge && (
    //                     <img
    //                       className=""
    //                       width={100}
    //                       src="/img/best-saller.jpg"
    //                       alt="image not found"
    //                     />
    //                   )}
    //                 </div>
    //               </div>
    //               <div className="plan-price pb-2 pb-sm-3">
    //                 <span
    //                   className="color-primary fw-7 "
    //                   style={{ color: item.priceDigitStyle }}
    //                 >
    //                   {item.price_before}
    //                 </span>
    //                 <span
    //                   className="h1 fw-7"
    //                   style={{ color: item.priceDigitStyle }}
    //                 >
    //                   {item.price}
    //                 </span>
    //                 <sub className="validity text-muted fw-5">
    //                   {item.price_after}
    //                 </sub>
    //               </div>
    //               <div className="plan-description">
    //                 <ul className="plan-features">
    //                   <li className={item.listClass_1}>{item.li_1}</li>
    //                   <li className={item.listClass_1}>{item.li_2}</li>
    //                   <li className={item.listClass_2}>{item.li_3}</li>
    //                   <li className={item.listClass_2}>{item.li_4}</li>
    //                   <li className={item.listClass_2}>{item.li_5}</li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </section>

    // ================== changed one (1) ================

    // <section
    //   // id="pricing"
    //   className="section price-plan-area overflow-hidden home-pricing-boxes "
    //   style={{
    //     position: "relative",
    //     width: "100%=",
    //     paddingBottom: "100px",
    //     border:"1px solid red"
    //   }}
    // >
    //   <div
    //     className="our-plans-background"
    //     style={{
    //       position: "absolute",
    //       width: "100%",
    //       height: "100%",
    //       filter: "blur(3px)",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <img
    //       src="/img/background1.jpg"
    //       alt="img"
    //       style={{ width: "100%", height: "100%", objectFit: "cover" }}
    //     />
    //   </div>
    //   <div>
    //     <div
    //       className="row justify-content-center mb-5"
    //       style={{
    //         position: "relative",
    //         paddingTop: "50px",
    //       }}
    //     >
    //       <div className="col-12 col-md-10 col-lg-7 b-">
    //         {/* Section Heading */}
    //         <div className="" style={{ textAlign: "center" }}>
    //           <h2 style={{ color: "white" }}>Our Plans</h2>
    //           {/* <p className="d-none d-sm-block mt-4" style={{color:"white" , fontWeight:"1rem",fontSize:"1.3rem"}}>
    //            - Experience exponential growth with Aaban Solution's
    //             result-driven digital marketing strategies. Experience
    //             unparalleled growth like never before!{" "}
    //           </p> */}

    //           <p
    //             className="d-none d-sm-block mt-2"
    //             style={{
    //               color: "white",
    //               fontWeight: "1rem",
    //               fontSize: "1.3rem",
    //             }}
    //           >
    //             Choose the plan that right for you and let’s get started{" "}
    //           </p>
    //           <p
    //             className="d-none d-sm-block mt-2"
    //             style={{
    //               color: "white",
    //               fontWeight: "1rem",
    //               fontSize: "1.3rem",
    //             }}
    //           >
    //             Proven Results : Our data-driven approach ensures measurable and
    //             impactful outcomes.{" "}
    //           </p>
    //           <p
    //             className="d-none d-sm-block mt-2"
    //             style={{
    //               color: "white",
    //               fontWeight: "1rem",
    //               fontSize: "1.3rem",
    //             }}
    //           >
    //             Expert Team : Leverage the expertise of our seasoned
    //             professionals dedicated to your success.{" "}
    //           </p>
    //           <p
    //             className="d-none d-sm-block mt-2"
    //             style={{
    //               color: "white",
    //               fontWeight: "1rem",
    //               fontSize: "1.3rem",
    //             }}
    //           >
    //             Innovative Solutions : Stay ahead of the curve with cutting-edge
    //             marketing techniques and tools.{" "}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div></div>
    //     <div className="d-flex justify-content-center align-items-center" style={{border:"1px solid green" , width:"100%",zIndex:10}}>
    //       <div
    //         className="row price-plan-wrapper justify-content-center  "
    //         style={
    //           {
    //             // width: "100%",
    //           }
    //         }
    //       >
    //         {data.themeOnePricingSection.pricingData.map((item, idx) => (
    //           <div key={`pro_${idx}`} className={item.colClass}>
    //             <div
    //               className="single-price-plan color-1 bg-hover hover-top text-center p-4 "
    //               style={{
    //                 width: "318px",
    //                 display: "flex",
    //                 flexDirection: "column",

    //                 alignItems: "center",
    //                 border: `8px solid ${item.borderColor}`,
    //               }}
    //             >
    //               <div className="plan-title mb-2 mb-sm-3">
    //                 <div className="d-flex align-items-center">
    //                   <h3 style={{ color: item.titleColor }} className="mb-2">
    //                     {item.title}
    //                   </h3>
    //                   {item.price_badge && (
    //                     <img
    //                       className=""
    //                       width={100}
    //                       src="/img/best-saller.jpg"
    //                       alt="image not found"
    //                     />
    //                   )}
    //                 </div>
    //               </div>
    //               <div className="plan-price pb-2 pb-sm-3">
    //                 <span
    //                   className="color-primary fw-7 "
    //                   style={{ color: item.priceDigitStyle }}
    //                 >
    //                   {item.price_before}
    //                 </span>
    //                 <span
    //                   className="h1 fw-7"
    //                   style={{ color: item.priceDigitStyle }}
    //                 >
    //                   {item.price}
    //                 </span>
    //                 <sub className="validity text-muted fw-5">
    //                   {item.price_after}
    //                 </sub>
    //               </div>
    //               <div className="plan-description">
    //                 <ul className="plan-features">
    //                   <li className={item.listClass_1}>{item.li_1}</li>
    //                   <li className={item.listClass_1}>{item.li_2}</li>
    //                   <li className={item.listClass_2}>{item.li_3}</li>
    //                   <li className={item.listClass_2}>{item.li_4}</li>
    //                   <li className={item.listClass_2}>{item.li_5}</li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </section>
    // ================== changed one (2) ================
    <section
      id="pricing"
      className="section price-plan-area overflow-hidden home-pricing-boxes"
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <div
        className="our-plans-background"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(5, 22, 67)",
          overflow: "hidden",
        }}
      >
        <img
          src="/img/background1.jpg"
          alt="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="row justify-content-center mb-5"
          style={{
            position: "relative",
          }}
        >
          <div className="col-12 col-md-10 col-lg-12 b-">
            {/* Section Heading */}
            <div className="" style={{ textAlign: "center"  }}>
              <h2 style={{ color: "white",}}>Our Plans</h2>
              {/* <p
                className="d-none d-sm-block mt-2"
                style={{
                  color: "white",
                  fontWeight: "1rem",
                  fontSize: "1.3rem",
                  textAlign:"center"
                }}
              >
                Choose the plan that's right for you and let’s get started{" "}
              </p> */}
              <p
                className="d-none d-sm-block mt-2"
                style={{
                  color: "white",
                  fontWeight: "1rem",
                  fontSize: "1.3rem",
                }}
              >
                Proven Results: Our data-driven approach ensures measurable and
                impactful outcomes.
              </p>
              <p
                className="d-none d-sm-block mt-2"
                style={{
                  color: "white",
                  fontWeight: "1rem",
                  fontSize: "1.3rem",
                }}
              >
                Expert Team: Leverage the expertise of our seasoned
                professionals dedicated to your success.
              </p>
              <p
                className="d-none d-sm-block mt-2"
                style={{
                  color: "white",
                  fontWeight: "1rem",
                  fontSize: "1.3rem",
                }}
              >
                Innovative Solutions: Stay ahead of the curve with cutting-edge
                marketing techniques and tools.
              </p>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center  "
          style={{ width: "100%" }}
        >
          <div
            className="row price-plan-wrapper justify-content-center "
            style={
              {
                // width: "100%",
                // border: "1px solid black",
              }
            }
          >
            {data.themeOnePricingSection.pricingData.map((item, idx) => (
              <div
                key={`pro_${idx}`}
                className="col-12 col-md-6 col-lg-3 mb-4 "
              >
                <div
                  className="single-price-plan color-1 bg-hover hover-top text-center "
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: `8px solid ${item.borderColor}`,
                    padding: "5px 20px",
                  }}
                >
                  <div className="plan-title mb-2 mb-sm-3">
                    <div className="d-flex align-items-center">
                      <h3 style={{ color: item.titleColor }} className="mb-2">
                        {item.title}
                      </h3>
                      {item.price_badge && (
                        <img
                          className=""
                          width={100}
                          src="/img/best-saller.jpg"
                          alt="img not found"
                        />
                      )}
                    </div>
                  </div>
                  <div className="plan-price pb-2 pb-sm-3">
                    <span
                      className="color-primary fw-7"
                      style={{ color: item.priceDigitStyle }}
                    >
                      {item.price_before}
                    </span>
                    <span
                      className="h1 fw-7"
                      style={{ color: item.priceDigitStyle }}
                    >
                      {item.price}
                    </span>
                    <sub className="validity text-muted fw-5">
                      {item.price_after}
                    </sub>
                  </div>
                  <div className="plan-description">
                    <ul className="plan-features">
                      <li className={item.listClass_1}>{item.li_1}</li>
                      <li className={item.listClass_1}>{item.li_2}</li>
                      <li className={item.listClass_2}>{item.li_3}</li>
                      <li className={item.listClass_2}>{item.li_4}</li>
                      <li className={item.listClass_2}>{item.li_5}</li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingForHomePage;
