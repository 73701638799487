import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaChartLine } from "react-icons/fa";
import { FaChartColumn } from "react-icons/fa6";
import { FaHourglassHalf } from "react-icons/fa";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/digimax-json/themeOnePromoSection";

const PromoOne = () => {
  const [promoData, setPromoData] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL)
      .then((res) => {
        setPromoData(res.data.promoData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    // <div className="pt-4">
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-md-8 text-center">
    //         <h3>Tech-Enabled Result-Driven Digital Marketing Agency</h3>
    //         <p className="mt-4" style={{ fontSize: "22px" }}>
    //           We make sure your money is well spent and the campaigns elevate
    //           your brand to the next level.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <section className="section promo-area pt_100">
    //     <div className="container" style={{ marginTop: 30 }}>
    //       <div className="row d-flex">
    //         <div className="col-12 col-md-6 col-lg-4 res-margin">
    //           <div
    //             className="single-promo color-1 bg-hover hover-bottom text-center p-5"
    //             style={{ height: "100%" }}
    //           >
    //             <div>
    //               <FaChartLine size={42} color="#ffa737" />
    //               <h3 className="mb-3 mt-4">Online Reputation Management</h3>
    //             </div>
    //             <p>
    //               <strong>
    //                 Negative press and feedback can cause a great deal of harm
    //                 to your business. If you don’t address it quickly, your
    //                 business might never recover. Our expert team knows how to
    //                 improve positive.
    //               </strong>
    //             </p>
    //           </div>
    //         </div>

    //         <div className="col-12 col-md-6 col-lg-4 res-margin">
    //           <div
    //             className="single-promo color-1 bg-hover hover-bottom text-center p-5"
    //             style={{ height: "100%" }}
    //           >
    //             <div>
    //               <FaChartColumn size={42} color="#ffa737" />
    //               <h3 className="mb-3 mt-4">Conversions</h3>
    //             </div>
    //             <p>
    //               <strong>
    //                 The end goal of all marketing campaigns is the conversion
    //                 rate. Many inexperienced marketers lose sight of this and
    //                 focus too much on experimentation. We always work to create
    //                 lean and straightforward campaigns that focus on gaining
    //                 conversions.
    //               </strong>
    //             </p>
    //           </div>
    //         </div>

    //         <div className="col-12 col-md-6 col-lg-4 res-margin">
    //           <div
    //             className="single-promo color-1 bg-hover hover-bottom text-center p-5"
    //             style={{ height: "100%" }}
    //           >
    //             <div>
    //               <FaHourglassHalf size={42} color="#ffa737" />
    //               <h3 className="mb-3 mt-4">Real Time Analytics</h3>
    //             </div>
    //             <p>
    //               <strong>
    //                 Our team keeps a keen eye on all marketing campaigns to
    //                 ensure they work smoothly. This allows them to catch any
    //                 negative shifts in trends promptly and react without delay.
    //                 Quick reaction can help preserve your website’s ranking and
    //                 your company’s reputation.
    //               </strong>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>

    // ****** changed one *******

    <div className="pt-4 pb-4 bg-grey" style={{}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center ">
            <h3 style={{}}>
              Tech-Enabled Result-Driven Digital Marketing Agency
            </h3>
            <p className="mt-4 " style={{ fontSize: "22px" }}>
              We make sure your money is well spent and the campaigns elevate
              your brand to the next level.
            </p>
          </div>
        </div>
      </div>
      <section className="section promo-area pt_100">
        <div className="container" style={{ marginTop: 30 }}>
          <div className="row d-flex">
            <div className="col-12 col-md-6 col-lg-4 res-margin">
              <div
                className="single-promo color-1 bg-hover hover-bottom text-center p-5"
                style={{ height: "100%", backgroundColor: "white" }}
              >
                <div>
                  <FaChartLine size={42} color="#ffa737" />
                  <h3 className="mb-3 mt-4">Online Reputation Management</h3>
                </div>
                <p>
                  <strong>
                    Negative press and feedback can cause a great deal of harm
                    to your business. If you don’t address it quickly, your
                    business might never recover. Our expert team knows how to
                    improve positive.
                  </strong>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 res-margin">
              <div
                className="single-promo color-1 bg-hover hover-bottom text-center p-5"
                style={{ height: "100%", backgroundColor: "white" }}
              >
                <div>
                  <FaChartColumn size={42} color="#ffa737" />
                  <h3 className="mb-3 mt-4">Conversions</h3>
                </div>
                <p>
                  <strong>
                    The end goal of all marketing campaigns is the conversion
                    rate. Many inexperienced marketers lose sight of this and
                    focus too much on experimentation. We always work to create
                    lean and straightforward campaigns that focus on gaining
                    conversions.
                  </strong>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 res-margin">
              <div
                className="single-promo color-1 bg-hover hover-bottom text-center p-5"
                style={{ height: "100%", backgroundColor: "white" }}
              >
                <div>
                  <FaHourglassHalf size={42} color="#ffa737" />
                  <h3 className="mb-3 mt-4">Real Time Analytics</h3>
                </div>
                <p>
                  <strong>
                    Our team keeps a keen eye on all marketing campaigns to
                    ensure they work smoothly. This allows them to catch any
                    negative shifts in trends promptly and react without delay.
                    Quick reaction can help preserve your website’s ranking and
                    your company’s reputation.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PromoOne;
