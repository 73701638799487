import React from 'react';

const Imageframe = () => {
  return (
    <div className='d-flex justify-content-center my-5'>
      <span style={{ marginTop: 20 }}>
        <img
          height="320"
          width="730"
          src='/img/home-page-digital.png'
          alt='imageframe'
          style={{ imageRendering: 'auto', maxWidth: '100%', height: 'auto' }}
        />
      </span>
    </div>
  );
};

export default Imageframe;
