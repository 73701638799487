import React, { useEffect, useState } from 'react'
import HeaderOne from '../Header/HeaderOne'
import BreadcrumbSection from '../Breadcrumb/BreadcrumbTwo'
import QuestionSection from './QuestionSection';
import WebDevelopmentCardsection from './WebDevelopmentCardsection';
import ReviewOne from '../Reviews/ReviewOne';
import AgencyCardsSection from './AgencyCardsSection';
import DevelopmentProcess from './DevelopmentProcess';
import DevelopmentServices from './DevelopmentServices';
import Industries from './Industries';
import Banner2 from '../Banners/Banner2';
import FooterOne from '../Footer/FooterOne';
import CustomerQuestion from './CustomerQuestion';


const WebDevelopment = ({pageName}) => {

  return (
    <>
        <HeaderOne imageData={"/img/aaban-text-logo.png"}  backgroundColor="#8835dc"/>
        <BreadcrumbSection pageName={pageName}/>
        <QuestionSection pageName={pageName} />
        <WebDevelopmentCardsection pageName={pageName} />
        <ReviewOne/>
        <AgencyCardsSection pageName={pageName}/>
        <section className='p-5 d-flex justify-content-center align-items-center webDev-banner-style' style={{ backgroundColor: "#2f2f2f", gap: "50px" }}>
          <h4 className='text-white'>You Have A Vision. We Can Help Make It Happen</h4>
          <button className='btn'>Let’s Get Started</button>
        </section>
        <DevelopmentProcess pageName={pageName}/>
        <DevelopmentServices pageName={pageName}/>
        <Industries pageName={pageName} />
        <Banner2  textColor= "text-black"/>
        <CustomerQuestion pageName={ pageName }/>
        <FooterOne />
    </>
  )
}

export default WebDevelopment