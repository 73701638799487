import React, { useState } from "react";
import App from "../../App.css";

const Banner11 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [changePassShowData, setChangePassShowData] = useState(true);
  const [clrHistoryShowData, setClrHistoryShowData] = useState(false);
  const [clrFavShowData, setClrFavShowData] = useState(false);

  const chngPassToggle = () => {
    setChangePassShowData(!changePassShowData);
    setClrHistoryShowData(false);
    setClrFavShowData(false);
  };

  const clrHistoryToggle = () => {
    setClrHistoryShowData(!clrHistoryShowData);
    setChangePassShowData(false);
    setClrFavShowData(false);
  };

  const clrFavToggle = () => {
    setClrFavShowData(!clrFavShowData);
    setChangePassShowData(false);
    setClrHistoryShowData(false);
  };
  const styles = {
    changePassPopup: {
      backgroundColor: changePassShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    clrHistoryPopup: {
      backgroundColor: clrHistoryShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    clrFavPopup: {
      backgroundColor: clrFavShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-4' src='/img/realEstateImg-31.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-32.webp' alt='image' />
                  <img className='col-lg-4' src='/img/realEstateImg-33.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`changePassPopup ${changePassShowData ? 'active' : ''}`} onClick={chngPassToggle} style={styles.changePassPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {changePassShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Change Password</span>
                  </div>
                  {changePassShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>You can change your password for security reasons or reset it if you forget it. Just need to enter your registered email ID to reset your current password. An OTP will sent to the registered mobile number to verify. Then enter your new password & confirm the same.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`clrHistoryPopup ${clrHistoryShowData ? 'active' : ''}`} onClick={clrHistoryToggle} style={styles.clrHistoryPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{clrHistoryShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Clear History</span>
                  </div>
                  {clrHistoryShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>While clicking on clear history option, all the past history from the app will be erased. If you don’t want a record of properties that you visited in an app, you can delete all or some of your history.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`clrFavPopup ${clrFavShowData ? 'active' : ''}`} onClick={clrFavToggle} style={styles.clrFavPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{clrFavShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Clear Favorites</span>
                  </div>
                  {clrFavShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>While clicking on clear favourite’s option, all the favourites’ properties from the app will be erased. If you don’t want a record of properties that you marked favourite in an app, you can delete all or some of your favourites.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-31.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-32.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/realEstateImg-33.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`changePassPopup ${
                        changePassShowData ? "active" : ""
                      }`}
                      onClick={chngPassToggle}
                      style={styles.changePassPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {changePassShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>
                        Change Password
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2 backColor"
                      style={styles.content(changePassShowData)}
                    >
                      <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                        You can change your password for security reasons or
                        reset it if you forget it. Just need to enter your
                        registered email ID to reset your current password. An
                        OTP will be sent to the registered mobile number to
                        verify. Then enter your new password & confirm the same.
                      </p>
                    </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`clrHistoryPopup ${
                        clrHistoryShowData ? "active" : ""
                      }`}
                      onClick={clrHistoryToggle}
                      style={styles.clrHistoryPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {clrHistoryShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Clear History</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(clrHistoryShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          While clicking on clear history option, all the past
                          history from the app will be erased. If you don’t want
                          a record of properties that you visited in an app, you
                          can delete all or some of your history.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`clrFavPopup ${
                        clrFavShowData ? "active" : ""
                      }`}
                      onClick={clrFavToggle}
                      style={styles.clrFavPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {clrFavShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>
                        Clear Favorites
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(clrFavShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          While clicking on clear favourite’s option, all the
                          favourites’ properties from the app will be erased. If
                          you don’t want a record of properties that you marked
                          favourite in an app, you can delete all or some of
                          your favourites.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner11;
