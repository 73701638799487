import React from "react";

const WhyChooseUs = ({ Heading, title }) => {
  const containerStyle = {
    borderRadius: "15px",
    padding: "20px",
    // backgroundColor: "#f0f8ff",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const headingStyle = {
    // fontFamily: "'Arial, sans-serif'",
    color: "#333",
    textAlign: "center",
    margin: "20px 0",
    textTransform: "uppercase",
    letterSpacing: "2px",
  };

  const itemContainerStyle = {
    transition: "transform 0.3s ease-in-out",
    cursor: "text",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const itemHoverStyle = {
    transform: "scale(1.04)",
  };

  const itemInnerStyle = {
    borderLeft: "5px solid #03A9F4",
    paddingLeft: "15px",
  };

  const titleStyle = {
    fontSize: "22px",
    fontFamily: "'Arial, sans-serif'",
    color: "#03A9F4       ",
    marginBottom: "10px",
  };

  const descriptionStyle = {
    fontFamily: "'Arial, sans-serif'",
    color: "#666",
    marginTop: "10px",
    fontSize: "1.1rem",
  };

  const rowStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  };

  return (
    // <div className='container '>
    //   <h2 className='text-center my-4'>{Heading}</h2>
    //   <div className='row'>
    //     {title && title.map((item, index) => (
    //       <div key={index} className='col-md-6 mb-4'>
    //         <div className='mb-2'>
    //           <p style={{fontSize: "24px"}}><strong>{item.title}</strong></p>
    //           <p className='mt-3'>{item.disc}</p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    // ***** changed one *****
    <div style={containerStyle} className="container-fluid">
      <h2 style={headingStyle}>{Heading}</h2>
      <div className="row" style={rowStyle}>
        {title &&
          title.map((item, index) => (
            <div
              key={index}
              className="col-md-6 col-lg-4"
              style={itemContainerStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = itemHoverStyle.transform)
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <div style={itemInnerStyle}>
                <p style={titleStyle}>
                  <strong>{item.title}</strong>
                </p>

                <p style={descriptionStyle}>{item.disc}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
