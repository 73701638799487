import React from "react";
import Card from "../Cards/card";
import RealEstateCards from "../Cards/realEstateCards";
import Banner1 from "../RealEstateBanners/Banners1";
import Banner2 from "../RealEstateBanners/Banners2";
import Banner3 from "../RealEstateBanners/Banners3";
import Banner4 from "../RealEstateBanners/Banners4";
import Banner5 from "../RealEstateBanners/Banners5";
import Banner6 from "../RealEstateBanners/Banners6";
import Banner7 from "../RealEstateBanners/Banners7";
import Banner8 from "../RealEstateBanners/Banners8";
import Banner9 from "../RealEstateBanners/Banners9";
import Banner10 from "../RealEstateBanners/Banners10";
import Banner11 from "../RealEstateBanners/Banners11";
import Banner12 from "../RealEstateBanners/Banners12";
import Banner13 from "../RealEstateBanners/Banners13";
import Banner14 from "../RealEstateBanners/Banners14";
import Banner15 from "../RealEstateBanners/Banners15";
import Banner16 from "../RealEstateBanners/Banners16";
import Banner17 from "../RealEstateBanners/Banners17";
import ReviewOne from "../Reviews/ReviewOne";

const RealEstate = () => {
  return (
    <>
      {/* <section className="bg-grey">
        <div
          className="container flex-column d-flex justify-content-center align-items-center pt-5"
        >
          <h2>INTRODUCTION</h2>
          <div
            className="container justify-content-around align-items-center d-flex mt-5"
          >
            <div
              className="row"
            >
              <div className="col-md-7 col-sm-12">
                <div className="d-flex flex-column m-2 align-items-center">
                  <p>
                    The Real Estate Web Application is an interactive, effective
                    and revenue-generating website designed for the Real Estate
                    Industry. The main objective of this application is to help
                    the Real Estate Company to display unlimited number of
                    property listings for rent, sell purposes on the mobile app.
                  </p>
                  <p className="my-4">
                    There are large numbers of commercial real estate
                    information offering a suite of commercial properties and
                    services tailored to the national and local needs of the
                    commercial investments industry. These online marketplaces
                    have thousands of commercial real estate properties for sale
                    and lease under various categories including commercial
                    office space, industrial, single – family, multi – family,
                    land, etc. both for sale and lease as well. Their purpose is
                    to attract community of industry professionals including
                    investors, property managers, and landlords, local and
                    national buyers to select the properties with desired
                    features.
                  </p>
                  <p>
                    In this increasingly demanding scenario for a platform which
                    could help buyers to have a look at the available property
                    listings with all its photographs, necessary specification
                    and description, this application is an effort to provide
                    such a web interface with some attractive and innovative
                    features using the latest technologies.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="d-flex m-2 align-items-center ">
                  <img src="/img/realEstate.webp" alt="RealEstate" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <h2>SYSTEM DESIGN</h2>
          <div className="container text-center align-items-center mt-5">
            <p>
              In this project, we decided to make a real estate app where there
              is an admin, who will provide the id and password to the agent
              then the agent contacts with the admin and receives the id and
              password. They can start doing their job i.e. adding property
              listing with their actual price, agents contact with admin
              regarding property sell or rent purpose etc. Here all the
              customers can visit the page and make a purchase.
            </p>

            <p className="my-4">
              We will have one home page where multiple options allow the user
              to choose different property listings with the actual price. There
              will be a system administrator who will have the right to add and
              manage user accounts.
            </p>
          </div>
        </div>
        </section> */}

      {/* ***** Changed One ***** */}

      <section className="bg-grey">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center">
          <h2>INTRODUCTION</h2>
          <div className="container-fluid justify-content-around align-items-center d-flex mt-3 px-lg-0 px-sm-0">
            <div className="row">
              <div
                className="col-md-7 col-sm-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="d-flex flex-column m-2 align-items-center px-lg-5 px-sm-0">
                  <p
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      textAlign: "justify",
                    }}
                  >
                    The Real Estate Web Application is an interactive, effective
                    and revenue-generating website designed for the Real Estate
                    Industry. The main objective of this application is to help
                    the Real Estate Company to display unlimited number of
                    property listings for rent, sell purposes on the mobile app.
                  </p>
                  <p
                    className="my-4"
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      textAlign: "justify",
                    }}
                  >
                    There are large numbers of commercial real estate
                    information offering a suite of commercial properties and
                    services tailored to the national and local needs of the
                    commercial investments industry. These online marketplaces
                    have thousands of commercial real estate properties for sale
                    and lease under various categories including commercial
                    office space, industrial, single – family, multi – family,
                    land, etc. both for sale and lease as well. Their purpose is
                    to attract community of industry professionals including
                    investors, property managers, and landlords, local and
                    national buyers to select the properties with desired
                    features.
                  </p>
                  <p
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      textAlign: "justify",
                    }}
                  >
                    In this increasingly demanding scenario for a platform which
                    could help buyers to have a look at the available property
                    listings with all its photographs, necessary specification
                    and description, this application is an effort to provide
                    such a web interface with some attractive and innovative
                    features using the latest technologies.
                  </p>
                </div>
              </div>
              <div
                className="col-md-5 col-sm-12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src="/img/realEstate.webp"
                    alt="RealEstate"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5 px-lg-4 px-sm-0">
          <h2 style={{ textAlign: "center" }}>SYSTEM DESIGN</h2>
          <div
            className="container-fluid text-left align-items-center mt-4 px-lg-5 px-sm-0"
            style={{  width: "100%" }}
          >
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: "500",
                textAlign: "justify",
              }}
            >
              In this project, we decided to make a real estate app where there
              is an admin, who will provide the id and password to the agent
              then the agent contacts with the admin and receives the id and
              password. They can start doing their job i.e. adding property
              listing with their actual price, agents contact with admin
              regarding property sell or rent purpose etc. Here all the
              customers can visit the page and make a purchase.
            </p>

            <p
              className="my-4"
              style={{
                fontSize: "1.3rem",
                fontWeight: "500",
                textAlign: "justify",
              }}
            >
              We will have one home page where multiple options allow the user
              to choose different property listings with the actual price. There
              will be a system administrator who will have the right to add and
              manage user accounts.
            </p>
          </div>
        </div>
      </section>

      <section>
        <RealEstateCards />
        <Banner1 />
        <Banner2 />
        <Banner3 />
        <Banner4 />
        <Banner5 />
        <Banner6 />
        <Banner7 />
        <Banner8 />
        <Banner9 />
        <Banner10 />
        <Banner11 />
        <Banner12 />
        <Banner13 />
        <Banner14 />
        <Banner15 />
        <Banner16 />
        <Banner17 />
        <ReviewOne />
      </section>
    </>
  );
};

export default RealEstate;
