import React, { useState } from "react";
import App from "../../App.css";

const Banner12 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [advertiseShowData, setAdvertiseShowData] = useState(true);
  const [chatShowData, setChatShowData] = useState(false);
  const [logoutShowData, setLogoutShowData] = useState(false);

  const advertiseToggle = () => {
    setAdvertiseShowData(!advertiseShowData);
    setChatShowData(false);
    setLogoutShowData(false);
  };

  const chatToggle = () => {
    setChatShowData(!chatShowData);
    setAdvertiseShowData(false);
    setLogoutShowData(false);
  };

  const logoutToggle = () => {
    setLogoutShowData(!logoutShowData);
    setAdvertiseShowData(false);
    setChatShowData(false);
  };
  const styles = {
    advertisePopup: {
      backgroundColor: advertiseShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    chatPopup: {
      backgroundColor: chatShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    logoutPopup: {
      backgroundColor: logoutShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-3'>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-6' src='/img/realEstateImg-34.webp' alt='image' />
                  <img className='col-lg-6' src='/img/realEstateImg-35.webp' alt='image' />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`changePassPopup ${advertiseShowData ? 'active' : ''}`} onClick={advertiseToggle} style={styles.changePassPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {advertiseShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Advertise</span>
                  </div>
                  {advertiseShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>The main purpose of display advertising is to support brand awareness and it also helps to increase the purchase intention of consumers. We have the option to call or send an email to the commercial department for advertising.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`chatPopup ${chatShowData ? 'active' : ''}`} onClick={chatToggle} style={styles.chatPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{chatShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Chat</span>
                  </div>
                  {chatShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Chatting app allows you to communicate with your customers through chat. It enables you to send and receive messages. Chatting apps make it easier, simpler and faster to connect with everyone and it is also easy to use.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`logoutPopup ${logoutShowData ? 'active' : ''}`} onClick={logoutToggle} style={styles.logoutPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{logoutShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Logout</span>
                  </div>
                  {logoutShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Logging out means ending access to an app or a website. Logging out informs the app or website that the current user wishes to end the login session. Log out is also known as log off, sign off or sign out.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Chaanged One  */}
      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-5 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-34.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-35.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-5 col-md-12 px-lg-5 px-sm-0"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`advertisePopup ${
                        advertiseShowData ? "active" : ""
                      }`}
                      onClick={advertiseToggle}
                      style={styles.advertisePopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {advertiseShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Advertise</span>
                    </div>
                      <div
                        className="content mt-2 mb-2 backColor"
                        style={styles.content(advertiseShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          The main purpose of display advertising is to support
                          brand awareness and it also helps to increase the
                          purchase intention of consumers. We have the option to
                          call or send an email to the commercial department for
                          advertising.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`chatPopup ${chatShowData ? "active" : ""}`}
                      onClick={chatToggle}
                      style={styles.chatPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {chatShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Chat</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(chatShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Chatting app allows you to communicate with your
                          customers through chat. It enables you to send and
                          receive messages. Chatting apps make it easier,
                          simpler and faster to connect with everyone and it is
                          also easy to use.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`logoutPopup ${
                        logoutShowData ? "active" : ""
                      }`}
                      onClick={logoutToggle}
                      style={styles.logoutPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {logoutShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>Logout</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(logoutShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Logging out means ending access to an app or a
                          website. Logging out informs the app or website that
                          the current user wishes to end the login session. Log
                          out is also known as log off, sign off or sign out.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner12;
