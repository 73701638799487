import React, { useEffect, useRef, useState } from "react";
import data from "../../data/db.json";
import { FaPlus, FaMinus } from "react-icons/fa";
import "../../App.css";

const ServiceFAQs = ({ pageName }) => {
  // const { ServiceFAQs } = data[pageName];
  // const [expandedIndex, setExpandedIndex] = useState(-1);

  // const toggleItem = (index) => {
  //   setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  // };

  const { ServiceFAQs } = data[pageName];
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [maxHeight, setMaxHeight] = useState({});
  const [contentOpacity, setContentOpacity] = useState({});

  const answerRefs = useRef([]);

  const toggleAnswerVisibility = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    const newMaxHeights = {};
    const newOpacities = {};
    answerRefs.current.forEach((el, index) => {
      if (expandedIndex === index) {
        newMaxHeights[index] = el.scrollHeight;
        newOpacities[index] = 1; // Fully visible
      } else {
        newMaxHeights[index] = 0;
        newOpacities[index] = 0; // Fully hidden
      }
    });
    setMaxHeight(newMaxHeights);
    setContentOpacity(newOpacities);
  }, [expandedIndex]);

  return (
    <>
      {/* <section className="" >
        <div className="container py-3 ">
          <h3
            className="text-center"
            style={{ color: "#4BAEF6", fontWeight: "bold" }}
          >
            {ServiceFAQs?.heading}
          </h3>
          {ServiceFAQs?.data?.map((item, index) => (
            <div key={index} className="faq-item-container">
              <div style={{ textAlign: "left" }}>
                <div className="faq-toggle" onClick={() => toggleItem(index)}>
                  {expandedIndex === index ? (
                    <span className="toggle-icon minus pr-3 badge ">
                      <FaMinus />
                    </span>
                  ) : (
                    <span className="toggle-icon plus pr-3 badge ">
                      <FaPlus />
                    </span>
                  )}
                  <h4>{item.toggle}</h4>
                </div>
                <div
                  className={`faq-content ${
                    expandedIndex === index ? "open" : ""
                  }`}
                >
                  <p>{item.data}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section> */}

      {/* ***** Chnaged one *****  */}
      <section className="container py-3">
        <h3
          className="text-center"
          style={{ color: "#4BAEF6", fontWeight: "bold" }}
        >
          {ServiceFAQs?.heading}
        </h3>
        {ServiceFAQs?.data?.map((item, index) => (
          <div key={index} className="faq-item-container">
            <div style={{ textAlign: "left" }}>
              <div
                className="faq-toggle"
                onClick={() => toggleAnswerVisibility(index)}
              >
                {expandedIndex === index ? (
                  <span className="toggle-icon minus pr-3 badge">
                    <FaMinus />
                  </span>
                ) : (
                  <span className="toggle-icon plus pr-3 badge">
                    <FaPlus />
                  </span>
                )}
                <h4>{item.toggle}</h4>
              </div>
              <div
                ref={(el) => (answerRefs.current[index] = el)}
                className={`faq-content ${
                  expandedIndex === index ? "open" : ""
                }`}
                style={{
                  maxHeight: `${maxHeight[index]}px`,
                  overflow: "hidden",
                  transition: "max-height 0.2s ease",
                  border: "1px solid #eeeeee",
                  opacity: contentOpacity[index],
                  paddingTop: expandedIndex === index ? "10px" : "0",
                  paddingBottom: expandedIndex === index ? "10px" : "0",
                }}
              >
                <p>{item.data}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default ServiceFAQs;
