import React, { useState } from "react";
import App from "../../App.css";

const Banner15 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [propShowData, setPropShowData] = useState(true);
  const [allUsersShowData, setAllUsersShowData] = useState(false);

  const propToggle = () => {
    setPropShowData(!propShowData);
    setAllUsersShowData(false);
  };

  const allUsersToggle = () => {
    setAllUsersShowData(!allUsersShowData);
    setPropShowData(false);
  };
  const styles = {
    propPopup: {
      backgroundColor: propShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    allUsersPopup: {
      backgroundColor: allUsersShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className='bg-grey' >
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <div className='container  align-items-center justify-content-center d-flex mt-5'>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`propPopup ${propShowData ? 'active' : ''}`} onClick={propToggle} style={styles.propPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}> {propShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>Types of Properties</span>
                  </div>
                  {propShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>Admin can view property listings types: home, apartment & commercial. On selecting among sale/rent, types, empty/furnished types the admin can view the property listing search results & view their property details.</p>
                    </div>
                  )}
                </div>
                <div className="m-2 align-items-center" style={{ borderBottom: '0.5px solid #D3D3D3', cursor: 'pointer' }}>
                  <div className={`allUsersPopup ${allUsersShowData ? 'active' : ''}`} onClick={allUsersToggle} style={styles.allUsersPopup}>
                    <button style={{ backgroundColor: '#FFAD01', color: 'white', fontSize: 17, height: 30, width: 30, border: 0, margin: 5 }}>{allUsersShowData ? '-' : '+'}</button><span style={{ fontWeight: 'bold' }}>All Users</span>
                  </div>
                  {allUsersShowData && (
                    <div className="content mt-2 mb-2 backColor">
                      <p>In user list section, all the registered users list will display along with their details. While clicking on View button, user profile screen will appear.</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className='image-container'>
                  <img className='col-lg-6' src='/img/realEstateImg-39.webp' alt='image' />
                  <img className='col-lg-6' src='/img/realEstateImg-41.webp' alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ***** Changed One *****  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-4 col-md-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="m-2 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`propPopup ${propShowData ? "active" : ""}`}
                      onClick={propToggle}
                      style={styles.propPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {propShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>
                        Types of Properties
                      </span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(propShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          Admin can view property listings types: home,
                          apartment & commercial. On selecting among sale/rent,
                          types, empty/furnished types the admin can view the
                          property listing search results & view their property
                          details.
                        </p>
                      </div>
                  </div>
                  <div
                    className="m-2 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`allUsersPopup ${
                        allUsersShowData ? "active" : ""
                      }`}
                      onClick={allUsersToggle}
                      style={styles.allUsersPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#FFAD01",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {allUsersShowData ? "-" : "+"}
                      </button>
                      <span style={{ fontWeight: "bold" }}>All Users</span>
                    </div>
                      <div className="content mt-2 mb-2 backColor"
                      style={styles.content(allUsersShowData)}
                      >
                        <p style={{ fontWeight: "450", fontSize: "1.2rem" }}>
                          In user list section, all the registered users list
                          will display along with their details. While clicking
                          on View button, user profile screen will appear.
                        </p>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-39.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-6"
                    src="/img/realEstateImg-41.webp"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner15;
