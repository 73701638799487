import React from 'react';
import data from '../../data/db.json';

const ReasonToChooseUs = ({ pageName }) => {
    const { ReasonToChooseUs } = data[pageName];

    return (
        <section className='bg-grey p-2'>
            <div className="container mt-5 mb-5 flex-column d-flex justify-content-center align-items-center ">
                {ReasonToChooseUs?.map((item, index) => {
                    return (
                        <>
                            <h3 style={{ color: '#4BAEF6', fontWeight: 800 }}>{item?.heading}</h3>
                            <p className='text-center'>{item?.desc}</p>
                            <div className="col-lg-12  pr-lg-5" key={index}>
                                <div className="blog blog-right">
                                    <div className="main overflow-hidden">
                                        <div className="row bg-grey">
                                            {item?.list?.map((list, index) => (
                                                <div key={index} className="col-lg-4 col-md-12 d-flex ">
                                                    <div className='card-body '>
                                                        <img src={`/img/${list?.image}`} alt="Image" />
                                                        <p style={{ fontWeight: 'bold' }} className='pt-3 pb-3'>{list?.heading}</p>
                                                        <p>{list?.desc}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </section>
    );
};

export default ReasonToChooseUs;
