import React, { useEffect, useState } from "react";
import tableData from "../../data/db.json";

const PricingTwo = ({ pageName }) => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (pageName && tableData[pageName] && tableData[pageName].plans) {
      setPlans(tableData[pageName].plans);
    }
  }, [pageName]);

  // Array of background colors for column titles
  const columnColors = ["#3a993a", "#2ea3e8", "red", "#fcb900"];
  const columnColors1 = ["#3a993a", "red", "#fcb900"];

  return (
    <section className="bg-grey py-5 pb-5">
      <div className="container mt-3 pricing-div-style text-center">
        <div className="mb-5">
          <h2>{tableData[pageName]?.ourPlan}</h2>
          <p style={{fontSize:"1.4rem", fontWeight:"450" ,color:"#000000"}}>Choose the plan that fits right for you and let’s get started</p>
        </div>
        <div className="row justify-content-center">
          {plans.map((plan, index) => (
            <div key={index} className="col-md-3" style={{ minWidth: "20px" }}>
              {plan.features && (
                <div className="card text-center">
                  {plan.features.map((feature, idx) => (
                    <div key={idx} className="">
                      <p
                        className="card-title py-3"
                        style={{ fontWeight: "800", color: "white", backgroundColor: tableData[pageName].columnNumber == 3 ? columnColors1[index % columnColors1.length] : columnColors[index % columnColors.length], fontSize: "1.5rem" }}
                      >
                        {feature.title}
                      </p>
                      <p style={{ fontFamily: "monospace", fontWeight: "500", marginTop: "20px", marginBottom: "20px" }}>{feature.tech}</p>

                      {/* Check if feature.items exists and is an array */}
                      {feature.items && Array.isArray(feature.items) && (
                        <ul className="list-unstyled" style={{ whiteSpace: "pre-wrap" }}>
                          {feature.items.map((item, i) => (
                            <>
                              <li key={i} style={{ fontSize: "0.9rem" }}>{item}</li><hr />
                            </>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {/* Conditionally render price div based on availability of price */}
              {plan.price && (
                <div className="card text-center mt-3" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", transition: "box-shadow 0.3s", borderTop: `15px solid ${tableData[pageName].columnNumber == 3 ? columnColors1[index % columnColors1.length] : columnColors[index % columnColors.length]}` }}>
                  <div className="pb-4">
                    <div className="card-body"></div>

                    <h3 className="card-text text-grey" style={{ fontWeight: "800", fontSize: "xxx-large" }}>
                      <span className="price">$ {plan.price}</span> {/* Display price here */}
                    </h3>
                    <p style={{ fontWeight: "800", color: "#C3C3C3" }}>
                      {pageName === 'web-packages' ? 'Price one time' : 'MONTHLY'}
                    </p>
                  </div>
                  <p className="mt-4">
                    <a
                      href="/contact"
                      className={`start-today-link plan-${index}`}
                      style={{
                        fontWeight: "600",
                        backgroundColor: tableData[pageName].columnNumber == 3 ? columnColors1[index % columnColors1.length] : columnColors[index % columnColors.length],
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        textDecoration: 'none',
                        transition: 'background-color 0.3s',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.8)'
                      }}
                    >
                      START TODAY
                    </a>

                  </p>
                </div>
              )}

            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingTwo;
