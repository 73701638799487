import React, { useState } from "react";
import App from "../../App.css";

const Banners3 = ({
  banner1Heading,
  banner1Desc,
  image,
  flexReverse,
  button,
}) => {
  const flexItemReverse = flexReverse ? flexReverse : "";
  const [homeShowData, setHomeShowData] = useState(true);
  const [favoritesShowData, setFavoritesShowData] = useState(false);
  const [ordersShowData, setOrdersShowData] = useState(false);

  const homeToggle = () => {
    setHomeShowData(!homeShowData);
    setFavoritesShowData(false);
    setOrdersShowData(false);
  };

  const favoriteToggle = () => {
    setFavoritesShowData(!favoritesShowData);
    setHomeShowData(false);
    setOrdersShowData(false);
  };

  const ordersToggle = () => {
    setOrdersShowData(!ordersShowData);
    setHomeShowData(false);
    setFavoritesShowData(false);
  };
  const styles = {
    splashPopup: {
      backgroundColor: homeShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    loginPopup: {
      backgroundColor: favoritesShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    fpwordPopup: {
      backgroundColor: ordersShowData ? "#6CB4EE" : null,
      padding: 5,
    },
    content: (show) => ({
      maxHeight: show ? "500px" : "0",
      overflow: "hidden",
      transition: "max-height 0.5s  ",
    }),
  };
  return (
    <>
      {/* <section className="bg-grey">
        <div className="container flex-column d-flex justify-content-center align-items-center pt-5">
          <div className="container  align-items-center justify-content-center d-flex mt-5">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-7.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-8.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-9.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`splashPopup ${homeShowData ? "active" : ""}`}
                    onClick={homeToggle}
                    style={styles.splashPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {" "}
                      {homeShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Home Screen</span>
                  </div>
                  {homeShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        This is the main screen around which the entire app
                        revolves. The home screen is the place where the user
                        keeps coming back to, so having a screen that is easy to
                        navigate is of the utmost importance. There is no set
                        template that follows here, which means the home screen
                        will differ based on the purpose of the app.
                      </p>
                      <br />
                      <p style={{ color: "black" }}>
                        In the above screen, customers can search the
                        restaurants on the basis of their location. Customers
                        can view categories, recent orders, top restaurants etc.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`loginPopup ${
                      favoritesShowData ? "active" : ""
                    }`}
                    onClick={favoriteToggle}
                    style={styles.loginPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {favoritesShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Favorites Screen</span>
                  </div>
                  {favoritesShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In this screen, customers can add their favorite
                        restaurants so that when he wants to order any food item
                        he won’t need to search the restaurant, he can directly
                        go to favorite screen & order food.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className="m-2 align-items-center"
                  style={{
                    borderBottom: "0.5px solid #D3D3D3",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={`fpwordPopup ${ordersShowData ? "active" : ""}`}
                    onClick={ordersToggle}
                    style={styles.fpwordPopup}
                  >
                    <button
                      style={{
                        backgroundColor: "#318CE7",
                        color: "white",
                        fontSize: 17,
                        height: 30,
                        width: 30,
                        border: 0,
                        margin: 5,
                      }}
                    >
                      {ordersShowData ? "-" : "+"}
                    </button>
                    <span style={{ fontWeight: "bold" }}>Orders</span>
                  </div>
                  {ordersShowData && (
                    <div className="content mt-2 mb-2">
                      <p style={{ color: "black" }}>
                        In this screen, all the customers’ orders will reﬂect
                        here. He can view every information about the order like
                        the ordered items, price, date etc.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Changed One  */}

      <section className="bg-grey d-flex justify-content-center">
        <div className="container-fluid flex-column d-flex justify-content-center align-items-center pt-5">
          <div
            className="align-items-center justify-content-center d-flex mt-5"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="row px-lg-5 px-sm-0">
              <div className="col-lg-6 col-md-12">
                <div className="image-container">
                  <img
                    className="col-lg-4"
                    src="/img/customImg-7.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-8.webp"
                    alt="image"
                  />
                  <img
                    className="col-lg-4"
                    src="/img/customImg-9.webp"
                    alt="image"
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 px-lg-5 px-sm-0"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`splashPopup ${homeShowData ? "active" : ""}`}
                      onClick={homeToggle}
                      style={styles.splashPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {homeShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Home Screen
                      </span>
                    </div>

                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(homeShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        This is the main screen around which the entire app
                        revolves. The home screen is the place where the user
                        keeps coming back to, so having a screen that is easy to
                        navigate is of the utmost importance. There is no set
                        template that follows here, which means the home screen
                        will differ based on the purpose of the app.
                      </p>
                      <br />
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In the above screen, customers can search the
                        restaurants on the basis of their location. Customers
                        can view categories, recent orders, top restaurants etc.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`loginPopup ${
                        favoritesShowData ? "active" : ""
                      }`}
                      onClick={favoriteToggle}
                      style={styles.loginPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {favoritesShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Favorites Screen
                      </span>
                    </div>
                    <div
                      className="content mt-2 mb-2"
                      style={styles.content(favoritesShowData)}
                    >
                      <p
                        style={{
                          color: "black",
                          fontWeight: "450",
                          fontSize: "1.2rem",
                        }}
                      >
                        In this screen, customers can add their favorite
                        restaurants so that when he wants to order any food item
                        he won’t need to search the restaurant, he can directly
                        go to favorite screen & order food.
                      </p>
                    </div>
                  </div>

                  <div
                    className="m-lg-4 m-sm-0 align-items-center"
                    style={{
                      borderBottom: "0.5px solid #D3D3D3",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`fpwordPopup ${
                        ordersShowData ? "active" : ""
                      }`}
                      onClick={ordersToggle}
                      style={styles.fpwordPopup}
                    >
                      <button
                        style={{
                          backgroundColor: "#318CE7",
                          color: "white",
                          fontSize: 17,
                          height: 30,
                          width: 30,
                          border: 0,
                          margin: 5,
                        }}
                      >
                        {ordersShowData ? "-" : "+"}
                      </button>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          marginLeft: "12px",
                        }}
                      >
                        Orders
                      </span>
                    </div>
                    {ordersShowData && (
                      <div
                        className="content mt-2 mb-2"
                        style={styles.content(ordersShowData)}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "450",
                            fontSize: "1.2rem",
                          }}
                        >
                          In this screen, all the customers’ orders will reﬂect
                          here. He can view every information about the order
                          like the ordered items, price, date etc.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banners3;
