import React from "react";
import HeaderOne from "../components/Header/HeaderOne";
import BreadcrumbFour from "../components/Breadcrumb/BreadcrumbFour";
import Form from "../components/formSection.js/Form";
import ReviewOne from "../components/Reviews/ReviewOne";
import FooterOne from "../components/Footer/FooterOne";



const FreeConsultaion = () => {
  return (
    <>
      <HeaderOne imageData={"/img/aaban-text-logo.png"} />
      <BreadcrumbFour path={"Free Consultation"} title={"Free Consultation"} />
      <section className="">
        <div className="container p-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-5">
              <img src="/img/office-meeting.png"/>
            </div>
            <Form   children={
                <>
                <h3 className="mb-4">Schedule Your Appointment</h3>
                </>
            }/>
          </div>
        </div>
      </section>
      <ReviewOne/>
      <FooterOne />
    </>
  );
};

export default FreeConsultaion;
