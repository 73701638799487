import React from "react";
import { FaUsers, FaTools, FaHotel, FaRegWindowMaximize } from "react-icons/fa";

const Card = () => {
  const iconContainerStyle = {
    position: "relative",
    overflow: "hidden",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    border: "2px solid #faa738",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  };

  const iconStyle = {
    position: "absolute",
    transform: "translateX(0)",
    transition: "transform ",
    padding: "12px",
  };

  const iconAnimation = `
    @keyframes slide {
      0% {
        transform: translateX(0%);
      }
      50% {
        transform: translateX(100%);
      }
      55% {
        transform: translateX(-100%);
        opacity:0;
      }
      70% {
        transform: translateX(0%);
        background-color:"red"
      }
    }
  `;

  const handleMouseEnter = (e) => {
    const icon = e.currentTarget.querySelector("svg");
    icon.style.animation = "slide 0.5s forwards";
  };

  const handleMouseLeave = (e) => {
    const icon = e.currentTarget.querySelector("svg");
    icon.style.animation = "none";
  };
  return (
    <>
      {/* <section className='bg-grey'>
        <div className='container flex-column d-flex justify-content-center align-items-center pt-5'>
          <h2>STAKE HOLDERS</h2>
          <div className='container t-center justify-content-center align-item-center '>
            <div className="row ">
              <div className="fusion-layout-column fusion_builder_column fusion-builder-column-3 fusion_builder_column_1_2 1_2 fusion-flex-column m-3">
                <div class="card mt-5" style={{ width: '14rem' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaUsers size={55} color="#faa738" />
                    <a href="#" class="text-black">
                      <strong>Go somewhere</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="fusion-layout-column fusion_builder_column fusion-builder-column-3 fusion_builder_column_1_2 1_2 fusion-flex-column m-3">
                <div class="card mt-5" style={{ width: '14rem' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaTools size={52} color="#faa738" />
                    <a href="#" class="text-black">
                      <strong>Drivers</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="fusion-layout-column fusion_builder_column fusion-builder-column-3 fusion_builder_column_1_2 1_2 fusion-flex-column m-3">
                <div class="card mt-5" style={{ width: '14rem' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaHotel size={55} color="#faa738" />
                    <a href="#" class="text-black">
                      <strong>Restaurants</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div className="fusion-layout-column fusion_builder_column fusion-builder-column-3 fusion_builder_column_1_2 1_2 fusion-flex-column m-3">
                <div class="card mt-5" style={{ width: '14rem' }}>
                  <div class="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <FaRegWindowMaximize size={55} color="#faa738" />
                    <a href="#" class="text-black ">
                      <strong >Admin panel</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* ***** Changed One ***** */}
      <section
        className="bg-grey"
        style={{
          width: "100%",
          padding: "2rem 0",
          backgroundImage: "url(/img/dotted.png)",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <style>{iconAnimation}</style>
        <div className="d-flex flex-column justify-content-center align-items-center" >
          <h2 style={{marginBottom:"0px" , textAlign:"center"}}>STAKE HOLDERS</h2>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <div className="row w-100 d-flex flex-wrap justify-content-center">
              <div
                className="col-12 col-md-6 col-lg-3 d-flex justify-content-center"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <div
                  className="card"
                  style={{ width: "90%", maxWidth: "350px", height: "250px" }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <div
                      style={iconContainerStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FaUsers size={75} color="#faa738" style={iconStyle} />
                    </div>
                    <a href="#" className="text-black">
                      <strong style={{ fontSize: "1.2rem" }}>
                        Go somewhere
                      </strong>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-3 d-flex justify-content-center"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <div
                  className="card"
                  style={{ width: "90%", maxWidth: "350px", height: "250px" }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <div
                      style={iconContainerStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FaTools size={75} color="#faa738" style={iconStyle} />
                    </div>
                    <a href="#" className="text-black">
                      <strong style={{ fontSize: "1.2rem" }}>Drivers</strong>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-3 d-flex justify-content-center"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <div
                  className="card"
                  style={{ width: "90%", maxWidth: "350px", height: "250px" }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <div
                      style={iconContainerStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FaHotel size={75} color="#faa738" style={iconStyle} />
                    </div>
                    <a href="#" className="text-black">
                      <strong style={{ fontSize: "1.2rem" }}>
                        Restaurants
                      </strong>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-3 d-flex justify-content-center"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <div
                  className="card"
                  style={{ width: "90%", maxWidth: "350px", height: "250px" }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center p-5 shadow bg-body rounded">
                    <div
                      style={iconContainerStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <FaRegWindowMaximize
                        size={75}
                        color="#faa738"
                        style={iconStyle}
                      />
                    </div>
                    <a href="#" className="text-black">
                      <strong style={{ fontSize: "1.2rem" }}>
                        Admin panel
                      </strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Card;
