import React from "react";
import ReviewOne from "../Reviews/ReviewOne";
import "../../App.css";
import data from "../../data/db.json";

const Table = ({ pageName }) => {
  const table = data[pageName];
  const tableData = table.table;
  const tableStyle = {
    borderCollapse: "collapse",
    borderSpacing: 0,
  };
  console.log(".........tableData......", tableData);
  const headerRowStyle = {
    backgroundColor: "#F9A920",
  };

  const headerCellStyle = {
    padding: "1rem",
    color: "#fff",
    fontWeight: "1",
    fontSize: "20px",
  };
  const rowStyle = {
    borderBottom: "1px solid #ccc",
  };
  const cellStyle = {
    padding: "0.5rem 1rem",
    wordWrap: "break-word",
    whiteSpace: "normal",
  };
  const containerStyle = {
    overflowX: "auto",
  };

  return (
    <div className="container mt-5">
      <h3
        className="text-center"
        style={{ color: "#4BAEF6", fontWeight: "bold" }}
      >
        {tableData?.heading}
      </h3>
      <p className="text-center">{tableData?.desc}</p>
      <div
        className="flex-column d-flex justify-content-center align-items-center text-center col-lg-12 col-md-12"
        style={containerStyle}
      >
        <table width="100%" style={tableStyle}>
          <thead>
            <tr style={headerRowStyle}>
              <th
                className="common-heading-table-1 text-left"
                style={headerCellStyle}
              >
                SEO PACKAGES
              </th>
              <th
                className="common-heading-table"
                align="center"
                style={headerCellStyle}
              >
                BASIC
              </th>
              <th
                className="common-heading-table"
                align="center"
                style={headerCellStyle}
              >
                SILVER
              </th>
              <th
                className="common-heading-table"
                align="center"
                style={headerCellStyle}
              >
                GOLD
              </th>
              <th
                className="common-heading-table"
                align="center"
                style={headerCellStyle}
              >
                PREMIUM
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={rowStyle}>
              <td
                style={cellStyle}
                className={`common-heading-1 ${
                  window.innerWidth <= 430 ? "cell-small-screen" : ""
                }`}
                align="left"
              >
                No. of Keywords
              </td>
              <td style={cellStyle} align="center">
                <strong>30</strong>
              </td>
              <td style={cellStyle} align="center">
                <strong>40</strong>
              </td>
              <td style={cellStyle} align="center">
                <strong>50</strong>
              </td>
              <td style={cellStyle} align="center">
                <strong>100</strong>
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Google My Business/Profile Management
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12237"
                  src={`/img/${tableData?.crossTik}`}
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Google Analytics, Google Search Console Setup
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Meta Data Updates
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Technical Website Analysis &amp; Opportunity Report
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Structured Data Setup
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12237"
                  src={`/img/${tableData?.crossTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12237"
                  src={`/img/${tableData?.crossTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                Hyperlink Optimization
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12237"
                  src={`/img/${tableData?.crossTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12237"
                  src={`/img/${tableData?.crossTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
            <tr style={rowStyle}>
              <td style={cellStyle} className="common-heading-1" align="left">
                NAP Syndication
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
              <td style={cellStyle} align="center">
                <img
                  className="alignnone size-full wp-image-12244"
                  src={`/img/${tableData?.rightTik}`}
                  alt
                  width="40"
                  height="40"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex-column d-flex justify-content-center align-items-center text-center">
        <div
          className="p-3 mt-3 badge"
          style={{ backgroundColor: "#F9A920", borderRadius: "15px" }}
        >
          {tableData?.linkButton}
        </div>
      </div>
    </div>
  );
};

export default Table;
