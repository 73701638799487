import React, { useState, useEffect } from "react";
import { MdStarRate } from "react-icons/md";

const initData = {
  heading: "Our clients say",
  headingText_1:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  headingText_2:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
};

const reviewData = [
  {
    id: 1,
    quote: "/img/quote.png",
    avatar: "/img/avatar_1.png",
    reviewer: "Junaid Hasan",
    post: "CEO, Themeland",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam est modi amet error earum aperiam, fuga labore facere rem ab nemo possimus cum excepturi expedita. Culpa rerum, quaerat qui non.",
  },
  {
    id: 2,
    quote: "/img/quote.png",
    avatar: "/img/avatar_2.png",
    reviewer: "Yasmin Akter",
    post: "Founder, Themeland",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam est modi amet error earum aperiam, fuga labore facere rem ab nemo possimus cum excepturi expedita. Culpa rerum, quaerat qui non.",
  },
  {
    id: 3,
    quote: "/img/quote.png",
    avatar: "/img/avatar_3.png",
    reviewer: "Md. Arham",
    post: "CEO, Themeland",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam est modi amet error earum aperiam, fuga labore facere rem ab nemo possimus cum excepturi expedita. Culpa rerum, quaerat qui non.",
  },
];

const videoData = [
  {
    id: 1,
    videoUrl: "https://www.youtube.com/embed/HZ-Ki-NgyLU",
  },
  {
    id: 2,
    videoUrl: "https://www.youtube.com/embed/lY6vnUMgsAQ",
  },
  {
    id: 3,
    videoUrl: "https://www.youtube.com/embed/K15Zk3pbyK0",
  },
  {
    id: 4,
    videoUrl: "https://www.youtube.com/embed/UlFVtbBziCw",
  },
];

const ReviewOne = () => {
  const [initDataState, setInitDataState] = useState({});
  const [reviewState, setReviewState] = useState([]);
  const [videoState, setVideoState] = useState([]);

  useEffect(() => {
    setInitDataState(initData);
    setReviewState(reviewData);
    setVideoState(videoData);
  }, []);

  return (

  <>
  

    {/* <section id="review" className="section review-area bg-overlay ptb_50">
      <div className="container">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>
            <img width={180} src="/img/aaban-text-logo.png" alt="image" />
          </div>
          <div className="ml-3">
            <h6 style={{ color: "white" }}>Aaban Solutions</h6>
            <MdStarRate color="gold" />
            <MdStarRate color="gold" />
            <MdStarRate color="gold" />
            <MdStarRate color="gold" />
            <p style={{ color: "white" }}>140 Google reviews</p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="client-reviews owl-carousel">
            {reviewState.map((item) => (
              <div key={`review_${item.id}`} className="single-review p-5">
                <div className="review-content">
                  <div className="review-text">
                    <p>{item.text}</p>
                  </div>
                  <div className="quot-icon">
                    <img className="avatar-sm" src={item.quote} alt="" />
                  </div>
                </div>
                <div className="reviewer media mt-3">
                  <div className="reviewer-thumb">
                    <img
                      className="avatar-lg radius-100"
                      src={item.avatar}
                      alt=""
                    />
                  </div>
                  <div className="reviewer-meta media-body align-self-center ml-4">
                    <h5 className="reviewer-name color-primary mb-2">
                      {item.reviewer}
                    </h5>
                    <h6 className="text-secondary fw-6">{item.post}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-5">
          <div className="client-reviews owl-carousel" >
            {videoState.map((item) => (
              <div key={`video_${item.id}`} className="single-video">
                <iframe
                  title={`video_${item.id}`}
                  width="100%" 
                  height="315" 
                  src={item.videoUrl}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>

      </div>
    </section> */}
    </>
  );
};

export default ReviewOne;