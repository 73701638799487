import React from 'react'
import ProcessCards from './ProcessCards'

const DevelopmentProcess = ({pageName}) => {
  return (
    <>
    <div className="container text-center mt-5 mb-3">
      <div className="">
        <h3>Web Design & Development Process</h3>
        <div className="d-flex justify-content-center my-3">
          <hr style={{ border: "2px solid #faa738", width: "10%" }} />
        </div>
      </div>
    </div>
    <div className='container d-flex' style={{gap: "10px"}}>
    <ProcessCards pageName={pageName}/>
    </div>
    </>
  )
}

export default DevelopmentProcess