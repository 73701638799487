import React, { useState } from "react";
import data from "../../data/db.json";

const UnmatchedExpertise = ({ pageName }) => {
  const { UnmatchedExpertise, list } = data[pageName];

  return (
    <>
      {/* <section className='' >
                <div className='container flex-column  justify-content-center align-items-center pt-5' >
                    <div className='container  align-items-center justify-content-center  mt-3' >
                        <div className="row" >
                            <div className="col-lg-6 col-md-12 lh-lg">
                                <div className="m-3 align-items-center" style={{ width: '60%' ,  }}>
                                    <div className="content mt-2 mb-2" >
                                        <h2 style={{ fontWeight: 'bold', lineHeight: 1.2 }}>{UnmatchedExpertise?.heading}</h2>
                                        <p style={{ color: 'black', lineHeight: 2 }}>{UnmatchedExpertise?.desc}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12" >
                                {UnmatchedExpertise?.progress?.map((item, index) => {
                                    return (
                                        <>
                                            <div className='pb-4' key={index}>
                                                <div className='d-flex lh-sm ' style={{ justifyContent: 'space-between' }}>
                                                    <p className='t-black pb-2'>{item?.title}</p>
                                                    <p>{item?.perssantage}</p>
                                                </div>
                                                <div className="progress-bar" style={{ width: `${item?.width}%` }} />
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'10%'}} >
                            {UnmatchedExpertise?.parteners?.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-lg-2 col-md-2 lh-lg d-flex">
                                            <div className=''>
                                                <img src={`/img/${item}`} />
                                            </div>
                                        </div>
                                    </>)
                            })}
                        </div>
                    </div>
                </div>
            </section> */}

      <section className="" >
        <div
          className="container  flex-column  justify-content-center align-items-center pt-5"
        >
          <div
            className="container  align-items-center justify-content-center  mt-3"
          >
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="col-lg-6 col-md-12 lh-lg">
                <div
                  className="m-0 align-items-center"
                  style={{ width: "80%", textAlign: "justify" }}
                >
                  <div className="content mt-2 mb-2">
                    <h2 style={{ fontWeight: "bold", lineHeight: 1.2 }}>
                      {UnmatchedExpertise?.heading}
                    </h2>
                    <p
                      className="mt-3 mb-3"
                      style={{
                        color: "black",
                        lineHeight: 2,
                        fontWeight: "550",
                      }}
                    >
                      {UnmatchedExpertise?.desc}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                {UnmatchedExpertise?.progress?.map((item, index) => {
                  return (
                    <>
                      <div className="pb-4" key={index}>
                        <div
                          className="d-flex lh-sm "
                          style={{ justifyContent: "space-between" }}
                        >
                          <p
                            className="t-black pb-2"
                            style={{ fontWeight: "600" }}
                          >
                            {item?.title}
                          </p>
                          <p>{item?.perssantage}</p>
                        </div>
                        <div
                          className="progress-bar"
                          style={{ width: `${item?.width}%` }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="row" style={{ marginTop: "10%" }}>
              {UnmatchedExpertise?.parteners?.map((item, index) => {
                return (
                  <>
                    <div className="col-lg-2 col-md-2 lh-lg d-flex">
                      <div className="">
                        <img src={`/img/${item}`} />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UnmatchedExpertise;
