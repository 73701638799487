import React from "react";
import { FaUsers, FaTools, FaHotel, FaRegWindowMaximize } from "react-icons/fa";
import data from '../../data/db.json';

const AdditionalOffer = ({ pageName }) => {
  const AdditionalOffer = data[pageName];
  const Data = AdditionalOffer?.AdditionalOffer;

  return (
    <>
      <section className='' >
        <div className='container flex-column d-flex justify-content-center align-items-center  text-center'>
          <h3 className='text-center' style={{ color: '#4BAEF6', fontWeight: 'bold', }}>{Data?.heading} </h3>
          <div
            style={{
              borderBottom: "3px solid #faa738",
              width: "5%",
              marginBottom: "1.5rem",
            }}
          ></div>
          <div className='container t-center justify-content-center align-item-center'>
            <div className="row d-flex justify-content-center align-items-center">
              {Data?.data?.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mt-3 mb-3" >
                  <div className='card' style={{ width: '20rem', height: '23rem', borderRadius: '20px' }}>
                    <div className='card-body d-flex flex-column justify-content-between align-items-center text-center shadow p-3' style={{ height: '100%', borderRadius: '20px' }}>
                      <div>
                        <ul style={{ listStyle: 'none' }}>
                          {item?.list.map((listItem, listIndex) => (
                            <li key={listIndex} style={{ padding: '10px', textAlign: 'left' }}>{listItem}</li>
                          ))}
                        </ul>
                      </div>
                      <div className='p-3 mt-3' style={{ backgroundColor: '#F9A920', borderRadius: '15px', width: '100%' }}>{item?.linkText}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdditionalOffer;
