import React, { useEffect, useState } from "react";
import data from "../../data/db.json";
import CustomerQuestion from "./CustomerQuestion";

const DevelopmentServices2 = ({ pageName }) => {
  const { BussinessCards } = data[pageName];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getGridTemplateColumns = () => {
    if (windowWidth >= 1900) {
      return "repeat(5, 1fr)"; // 6 columns for large screens
    }
    if (windowWidth >= 1350) {
      return "repeat(4, 1fr)"; // 4 columns for medium screens
    }
    if (windowWidth >= 768) {
      return "repeat(2, 1fr)"; // 2 columns for tablets
    }
    return "1fr"; // 1 column for mobile screens
  };

  return (
    // <div className="container mt-5 mb-5 flex-column d-flex justify-content-center align-items-center ">
    //   <div className="container  mt-5">
    //     <div className="">
    //       <h3 className="m-2" style={{ color: "rgb(3, 169, 244)" }}>
    //         {BussinessCards[0]?.heading}
    //       </h3>
    //       {BussinessCards[0]?.heading2 && (
    //         <h4 className="m-2" style={{ color: "rgb(3, 169, 244)" }}>
    //           {BussinessCards[0]?.heading2}
    //         </h4>
    //       )}
    //       <div className="d-flex justify-content-center ">
    //         <hr style={{ border: "2px solid #faa738", width: "10%" }} />
    //       </div>
    //       {BussinessCards[0]?.heading2 && (
    //         <>
    //           <p className="mt-4">{BussinessCards[0]?.paragraph}</p>
    //           <h4 className="my-4">We Offer a Range of Best SEO Services</h4>
    //           <p>
    //             To match your requirements and needs in terms of maximizing the
    //             SEO, we deliver premium SEO Services. As a leading{" "}
    //             <strong>SEO service agency </strong> , we know what our
    //             customers want, and that’s why we lead this market in the US. We
    //             also deliver SEO consultation and{" "}
    //             <strong>small business SEO services</strong> to our clients,
    //             making SEO easy to access. With our{" "}
    //             <strong>website SEO services</strong> , you can help your
    //             website stay on top of the search engine results.
    //           </p>
    //         </>
    //       )}
    //     </div>
    //   </div>
    //   <div className="row">
    //     {BussinessCards[1]?.list.map((item, index) => (
    //       <div
    //         key={index}
    //         className="col-lg-4 col-md-12 d-flex justify-content-center mt-3 mb-3"
    //       >
    //         <div className="card" style={{ borderTop: "10px solid #25ABFC" }}>
    //           <img src={`/img/${item?.image}`} alt="Image" />
    //           <div className="card-body align-items-center text-center shadow">
    //             <p style={{ fontWeight: "bold" }}>{item?.heading}</p>
    //             <p>{item?.desc}</p>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>

    //   <div className="d-flex flex-column mt-5" style={{ gap: "10px" }}>
    //     <p>
    //       Digital Marketing Advertising Agency - The growth of your business is
    //       our first objective. We make sure that your website gets constant
    //       traffic and helps you get revenue. As a renowned Digital Marketing
    //       Advertising Agency, along with reliable SEO optimization, we also
    //       provide digital marketing with absolute transparency.
    //     </p>
    //     <p>
    //       Search Engine Optimization Company - We are a reliable{" "}
    //       <strong>SEO service agency</strong> that can help you with all your
    //       SEO needs without any hassle.{" "}
    //     </p>
    //     <p>
    //       SEO Services Packages - Get <strong>affordable SEO services</strong>{" "}
    //       packages with us at aabansolution at a very reasonable price.
    //     </p>
    //     <p>
    //       SEO Services Pricing - Our <strong>professional SEO Services</strong>{" "}
    //       Pricing is cost-effective and reasonable. Meant for all kinds of
    //       business across the United States.
    //     </p>
    //   </div>

    //   <div className="mt-5">
    //     <h3>Discover The Expertise And Experience Our Services Entail!</h3>
    //     <p>
    //       Here at <strong>aabansolution LLC</strong>, we work with a team of
    //       expert professionals comprising hands-on experience in the field as an{" "}
    //       <strong>SEO agency</strong>. Hence, your brand marketing is never
    //       going to be behind the curtain with us. Get ready for a firm online
    //       presence of your business! Discover more by reading further.{" "}
    //     </p>
    //   </div>

    //   <div class="container mt-5 d-flex flex-row seo-services-usa-style">
    //     <div class="column col-md-6">
    //       <div class="">
    //         <div class="d-flex flex-column">
    //           <h5>1. 5 Years Of Experience In The Market</h5>
    //           <p>
    //             We are a 5-year-old happy family of professionals with great
    //             enthusiasm and vigor to serve our customers best with our{" "}
    //             <strong>SEO services</strong>. Our constant toiling to dig deep
    //             into the SEO best practices and drive out the prime outcomes for
    //             our clients has made us keep moving forward.
    //           </p>
    //         </div>
    //       </div>
    //       <div class=" mt-3">
    //         <div class="d-flex flex-column">
    //           <h5>2. Another Heading</h5>
    //           <p>
    //             This is another paragraph about your services or any other
    //             information you want to display.
    //           </p>
    //         </div>
    //       </div>
    //       <div class=" mt-3">
    //         <div class="d-flex flex-column">
    //           <h5>3. Every Update Is Always On Our Desk</h5>
    //           <p>
    //             Our experience and a great team of skilled SEO professionals
    //             have made us well-adjusted with every update that may come the
    //             way of our{" "}
    //           </p>
    //         </div>
    //       </div>
    //     </div>

    //     <div class="col-md-6">
    //       <img
    //         class="img-fluid"
    //         src="/img/pexels-fauxels-3184291 (1).jpg"
    //         alt="SEO Services Image"
    //       />
    //     </div>
    //   </div>

    //   <div className="mt-5">
    //     <div>
    //       <h4>The Fruit Of Your Trust In Us - Our Backpack Of Accolades!</h4>
    //       <p>
    //         We are the flag bearers of the SEO best practices recognized by the
    //         clutch and many more awards. Are you ready to dive into the details
    //         of our accolades? Here is the list:
    //       </p>
    //     </div>
    //     <div className="mt-3">
    //       <h4>
    //         We Never Let Your Trust Retreat - Here Is How Our SEO Differs!
    //       </h4>
    //       <p>
    //         Are you trusting us as your SEO company for SEO services? We will
    //         seldom let that trust break. In this direction, we work with
    //         uniquely and precisely planned SEO best strategies. Continue reading
    //         to know what makes us different from other agencies.
    //       </p>
    //     </div>
    //   </div>

    //   <div className="mt-5">
    //     <div>
    //       <h5>1. A Friendly And Valuable Partnership Offered By Us</h5>
    //       <p>
    //         We can't say enough about the capabilities of our team of SEO
    //         professionals. They are experts with good experience who never fail
    //         to drive the desired results at the right time. Their strength lies
    //         in their willingness to remain updated with the latest SEO updates
    //         and their technical skills.
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>2. An Expert Team</h5>
    //       <p>
    //         It's not a client and SEO service provider relationship but a
    //         partnership about to take off your business's digital marketing. You
    //         join hands with us, and we nail the collaboration to the highest.
    //         Apart from our technical expertise, one of the contributing factors
    //         is our friendly, helpful, and attentive approach to providing the
    //         best possible solution with your chosen{" "}
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>3. We Work With Strategy And Plan</h5>
    //       <p>
    //         We do not boost the expertise but bring it to the ground reality. It
    //         involves making thorough strategies and plans to work on them
    //         consistently. Here, we consistently work on a planned road map
    //         proven to bring prime outcomes for your selected{" "}
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>4. You Are Bound To Get The Outcomes The Fastest</h5>
    //       <p>
    //         We are always on our tiptoe to deliver the apex of our services and
    //         bring the top results. In this direction, we make impactful
    //         strategies and work on them with a friendly as well as attentive
    //         approach.{" "}
    //       </p>
    //     </div>
    //   </div>

    //   <div className="mt-5">
    //     <h3>Learn How We Go Above And Beyond For Impactful SEO!</h3>
    //     <p>
    //       We are the masters of what we do. To not let this tag shake off our
    //       shoulders, we go above and beyond to serve our clients who choose our
    //       SEO plans with the desired results. Are you curious to know about the
    //       process we follow? Here you go with the list:
    //     </p>
    //   </div>

    //   <div className="mt-5">
    //     <div>
    //       <h5>1. Keyword Research</h5>
    //       <p>
    //         Our skilled team of SEO experts monitors your competitors and the
    //         ranking keywords. They hunt the right keywords for your website and
    //         provide a list of potential keywords for you to select. Those
    //         keywords will have a huge contribution in increasing your website
    //         ranking.
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>2. Keyword Mapping</h5>
    //       <p>
    //         Now, it's time to take a step further after the keyword research.
    //         This step includes assigning the keywords to the appropriate pages
    //         of your website. This significant step will have a big role in being
    //         the flag bearer of the whole SEO campaign.
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>3. Optimizing Websites</h5>
    //       <p>
    //         Do you require experts for website SEO optimization? You can't have
    //         it done better other than <strong>Aaban Solutions LLC</strong>. This
    //         is the core of our service. In this direction, we start by checking
    //         if anything on the code on the website requires fixing. Further, we
    //         move ahead with optimizing page titles, meta descriptions, and meta
    //         keywords. Moving on, our other responsibilities for website
    //         optimization include content indexing, preventing pages from being
    //         blocked, and checking on links being followed. Ultimately, we ensure
    //         that all of these services follow Google guidelines.
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>4. Content Search Optimization</h5>
    //       <p>
    //         This process is simple and straightforward. We just take the
    //         keywords from the keyword assignment and start working on that page
    //         copy. Furthermore, we will employ the requisite technicalities to
    //         provide Google with the signal to rank your website on these
    //         keywords. This section of the service listed in our{" "}
    //         <strong>SEO monthly packages</strong> also involves ensuring that
    //         every H1 and H2 of the page titles on your website is SEO optimized.{" "}
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>5. Website Internal Link Structure Creation</h5>
    //       <p>
    //         Here comes the technical aspect of our SEO services! It comprises
    //         linking the pages of your website through a well-formed link
    //         structure. A website that has a shattered internal linking structure
    //         can neither remain functional for a long time nor rank on the top
    //         pages. We understand that and work our best to eliminate issues like
    //         these.{" "}
    //       </p>
    //     </div>

    //     <div className="mt-3">
    //       <h5>6. External Linking</h5>
    //       <p>
    //         We have a technically advanced team that takes that extra leap to
    //         create impactful external linking for your website. This way, you
    //         will be able to maximize greater benefits by partnering with us for
    //         SEO.
    //       </p>
    //     </div>
    //   </div>

    //   <div className="mt-5">
    //     <h3>The Ins Of Partnering With Us!</h3>
    //     <p>
    //       Our SEO strategies bring outcomes. What are those outcomes? What do I
    //       get after partnering with them? In case you have these questions in
    //       mind, we have created a list talking about the benefits you can reap
    //       after availing of our services. Have a great read!
    //     </p>
    //   </div>

    //   <div class="container mt-5 d-flex flex-row seo-services-usa-style">
    //     <div class="col-md-6">
    //       <img
    //         class="img-fluid"
    //         src="/img/myseo.jpg"
    //         alt="SEO Services Image"
    //       />
    //     </div>

    //     <div class="column col-md-6">
    //       <div class="">
    //         <div class="d-flex flex-column">
    //           <h5>1. S.M.A.R.T Goal Setting And Execution</h5>
    //           <p>
    //             With us you get a really impactful execution of your website
    //             digital marketing endeavor. In this direction, we use the
    //             S.M.A.R.T. goal-setting method to drive the campaign to the
    //             pinnacle of success in the given amount of time.{" "}
    //           </p>
    //         </div>
    //       </div>
    //       <div class=" mt-3">
    //         <div class="d-flex flex-column">
    //           <h5>2. Targeted Approach</h5>
    //           <p>
    //             We understand that every business's needs are unique, with
    //             different target audiences. Hence, we are always adept at
    //             introducing targeted solutions through our local SEO services.
    //             In this direction, we limit your website reach to your target
    //             audience, driving actions.{" "}
    //           </p>
    //         </div>
    //       </div>
    //       <div class=" mt-3">
    //         <div class="d-flex flex-column">
    //           <h5>3. Flexible And Scalable</h5>
    //           <p>
    //             Our reach is wide enough to capture every requirement of your
    //             website that may come under our SEO services responsibilities.
    //             Here, our only goal is to improve your website performance using
    //             our expertise. Besides, we never shy away from giving extended
    //             support in times of need.
    //           </p>
    //         </div>
    //       </div>
    //       <div class=" mt-3">
    //         <div class="d-flex flex-column">
    //           <h5>4. Affordable</h5>
    //           <p>
    //             Every business comes with a set budget. We understand that you
    //             might also have one. Fret not; we have a curated list of
    //             services most likely to fall under your business budget.
    //             Affordability, along with quality, is what makes us different
    //             from other digital marketing agencies.{" "}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // ***** Changed one *****

    <div className="container-fluid mt-5 mb-5 flex-column d-flex justify-content-center align-items-center ">
      <div
        className="container-fluid mt-5 mb-5 flex-column d-flex justify-content-center align-items-center px-lg-5 px-sm-0"
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <div className="container-fluid px-lg-5 px-sm-0 mt-5">
          <div>
            <h3 className="m-2" style={{ color: "rgb(3, 169, 244)" }}>
              {BussinessCards[0]?.heading}
            </h3>
            {BussinessCards[0]?.heading2 && (
              <h4 className="m-2" style={{ color: "rgb(3, 169, 244)" }}>
                {BussinessCards[0]?.heading2}
              </h4>
            )}
            <div className="d-flex justify-content-center">
              <hr style={{ border: "2px solid #faa738", width: "10%" }} />
            </div>
            {BussinessCards[0]?.heading2 && (
              <>
                <p className="mt-4" style={{ fontSize: "1.2rem" }}>
                  {BussinessCards[0]?.paragraph}
                </p>
                <h4 className="my-4">We Offer a Range of Best SEO Services</h4>
                <p style={{ fontSize: "1.2rem" }}>
                  To match your requirements and needs in terms of maximizing
                  the SEO, we deliver premium SEO Services. As a leading{" "}
                  <strong>SEO service agency</strong>, we know what our
                  customers want, and that’s why we lead this market in the US.
                  We also deliver SEO consultation and{" "}
                  <strong>small business SEO services</strong> to our clients,
                  making SEO easy to access. With our{" "}
                  <strong>website SEO services</strong>, you can help your
                  website stay on top of the search engine results.
                </p>
              </>
            )}
          </div>
        </div>
        <div
          className="px-lg-5 px-sm-0"
          style={{
            display: "grid",
            gridTemplateColumns: getGridTemplateColumns(),
            gap: "20px",
          }}
        >
          {BussinessCards[1]?.list.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div className="card" style={{ borderTop: "10px solid #25ABFC" }}>
                <img src={`/img/${item?.image}`} alt="Image" />
                <div className="card-body align-items-center text-center shadow">
                  <p style={{ fontWeight: "bold" }}>{item?.heading}</p>
                  <p>{item?.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className="d-flex flex-column mt-5 px-lg-5 px-sm-0"
          style={{ gap: "10px" }}
        >
          <p style={{ fontSize: "1.2rem" }}>
            Digital Marketing Advertising Agency - The growth of your business
            is our first objective. We make sure that your website gets constant
            traffic and helps you get revenue. As a renowned Digital Marketing
            Advertising Agency, along with reliable SEO optimization, we also
            provide digital marketing with absolute transparency.
          </p>
          <p style={{ fontSize: "1.2rem" }}>
            Search Engine Optimization Company - We are a reliable{" "}
            <strong>SEO service agency</strong> that can help you with all your
            SEO needs without any hassle.{" "}
          </p>
          <p style={{ fontSize: "1.2rem" }}>
            SEO Services Packages - Get <strong>affordable SEO services</strong>{" "}
            packages with us at aabansolution at a very reasonable price.
          </p>
          <p style={{ fontSize: "1.2rem" }}>
            SEO Services Pricing - Our{" "}
            <strong>professional SEO Services</strong> Pricing is cost-effective
            and reasonable. Meant for all kinds of business across the United
            States.
          </p>
        </div>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <h3>Discover The Expertise And Experience Our Services Entail!</h3>
        <p style={{ fontSize: "1.2rem" }}>
          Here at <strong>aabansolution LLC</strong>, we work with a team of
          expert professionals comprising hands-on experience in the field as an{" "}
          <strong>SEO agency</strong>. Hence, your brand marketing is never
          going to be behind the curtain with us. Get ready for a firm online
          presence of your business! Discover more by reading further.{" "}
        </p>
      </div>

      <div class="container mt-5 d-flex flex-row seo-services-usa-style">
        <div class="column col-md-6">
          <div class="">
            <div class="d-flex flex-column">
              <h5>1. 5 Years Of Experience In The Market</h5>
              <p style={{ fontSize: "1.2rem", textAlign: "justify" }}>
                We are a 5-year-old happy family of professionals with great
                enthusiasm and vigor to serve our customers best with our{" "}
                <strong>SEO services</strong>. Our constant toiling to dig deep
                into the SEO best practices and drive out the prime outcomes for
                our clients has made us keep moving forward.
              </p>
            </div>
          </div>
          <div class=" mt-3">
            <div class="d-flex flex-column">
              <h5>2. Another Heading</h5>
              <p style={{ fontSize: "1.2rem", textAlign: "justify" }}>
                This is another paragraph about your services or any other
                information you want to display.
              </p>
            </div>
          </div>
          <div class=" mt-3">
            <div class="d-flex flex-column">
              <h5>3. Every Update Is Always On Our Desk</h5>
              <p style={{ fontSize: "1.2rem", textAlign: "justify" }}>
                Our experience and a great team of skilled SEO professionals
                have made us well-adjusted with every update that may come the
                way of our{" "}
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <img
            class="img-fluid"
            src="/img/pexels-fauxels-3184291 (1).jpg"
            alt="SEO Services Image"
          />
        </div>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <div>
          <h4>The Fruit Of Your Trust In Us - Our Backpack Of Accolades!</h4>
          <p style={{ fontSize: "1.2rem" }}>
            We are the flag bearers of the SEO best practices recognized by the
            clutch and many more awards. Are you ready to dive into the details
            of our accolades? Here is the list:
          </p>
        </div>
        <div className="mt-3 ">
          <h4>
            We Never Let Your Trust Retreat - Here Is How Our SEO Differs!
          </h4>
          <p style={{ fontSize: "1.2rem" }}>
            Are you trusting us as your SEO company for SEO services? We will
            seldom let that trust break. In this direction, we work with
            uniquely and precisely planned SEO best strategies. Continue reading
            to know what makes us different from other agencies.
          </p>
        </div>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <div>
          <h5>1. A Friendly And Valuable Partnership Offered By Us</h5>
          <p style={{ fontSize: "1.2rem" }}>
            We can't say enough about the capabilities of our team of SEO
            professionals. They are experts with good experience who never fail
            to drive the desired results at the right time. Their strength lies
            in their willingness to remain updated with the latest SEO updates
            and their technical skills.
          </p>
        </div>

        <div className="mt-3">
          <h5>2. An Expert Team</h5>
          <p style={{ fontSize: "1.2rem" }}>
            It's not a client and SEO service provider relationship but a
            partnership about to take off your business's digital marketing. You
            join hands with us, and we nail the collaboration to the highest.
            Apart from our technical expertise, one of the contributing factors
            is our friendly, helpful, and attentive approach to providing the
            best possible solution with your chosen{" "}
          </p>
        </div>

        <div className="mt-3">
          <h5>3. We Work With Strategy And Plan</h5>
          <p style={{ fontSize: "1.2rem" }}>
            We do not boost the expertise but bring it to the ground reality. It
            involves making thorough strategies and plans to work on them
            consistently. Here, we consistently work on a planned road map
            proven to bring prime outcomes for your selected{" "}
          </p>
        </div>

        <div className="mt-3">
          <h5>4. You Are Bound To Get The Outcomes The Fastest</h5>
          <p style={{ fontSize: "1.2rem" }}>
            We are always on our tiptoe to deliver the apex of our services and
            bring the top results. In this direction, we make impactful
            strategies and work on them with a friendly as well as attentive
            approach.{" "}
          </p>
        </div>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <h3>Learn How We Go Above And Beyond For Impactful SEO!</h3>
        <p style={{ fontSize: "1.2rem" }}>
          We are the masters of what we do. To not let this tag shake off our
          shoulders, we go above and beyond to serve our clients who choose our
          SEO plans with the desired results. Are you curious to know about the
          process we follow? Here you go with the list:
        </p>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <div>
          <h5>1. Keyword Research</h5>
          <p style={{ fontSize: "1.2rem" }}>
            Our skilled team of SEO experts monitors your competitors and the
            ranking keywords. They hunt the right keywords for your website and
            provide a list of potential keywords for you to select. Those
            keywords will have a huge contribution in increasing your website
            ranking.
          </p>
        </div>

        <div className="mt-3">
          <h5>2. Keyword Mapping</h5>
          <p style={{ fontSize: "1.2rem" }}>
            Now, it's time to take a step further after the keyword research.
            This step includes assigning the keywords to the appropriate pages
            of your website. This significant step will have a big role in being
            the flag bearer of the whole SEO campaign.
          </p>
        </div>

        <div className="mt-3">
          <h5>3. Optimizing Websites</h5>
          <p style={{ fontSize: "1.2rem" }}>
            Do you require experts for website SEO optimization? You can't have
            it done better other than <strong>Aaban Solutions LLC</strong>. This
            is the core of our service. In this direction, we start by checking
            if anything on the code on the website requires fixing. Further, we
            move ahead with optimizing page titles, meta descriptions, and meta
            keywords. Moving on, our other responsibilities for website
            optimization include content indexing, preventing pages from being
            blocked, and checking on links being followed. Ultimately, we ensure
            that all of these services follow Google guidelines.
          </p>
        </div>

        <div className="mt-3">
          <h5>4. Content Search Optimization</h5>
          <p style={{ fontSize: "1.2rem" }}>
            This process is simple and straightforward. We just take the
            keywords from the keyword assignment and start working on that page
            copy. Furthermore, we will employ the requisite technicalities to
            provide Google with the signal to rank your website on these
            keywords. This section of the service listed in our{" "}
            <strong>SEO monthly packages</strong> also involves ensuring that
            every H1 and H2 of the page titles on your website is SEO optimized.{" "}
          </p>
        </div>

        <div className="mt-3">
          <h5>5. Website Internal Link Structure Creation</h5>
          <p style={{ fontSize: "1.2rem" }}>
            Here comes the technical aspect of our SEO services! It comprises
            linking the pages of your website through a well-formed link
            structure. A website that has a shattered internal linking structure
            can neither remain functional for a long time nor rank on the top
            pages. We understand that and work our best to eliminate issues like
            these.{" "}
          </p>
        </div>

        <div className="mt-3">
          <h5>6. External Linking</h5>
          <p style={{ fontSize: "1.2rem" }}>
            We have a technically advanced team that takes that extra leap to
            create impactful external linking for your website. This way, you
            will be able to maximize greater benefits by partnering with us for
            SEO.
          </p>
        </div>
      </div>

      <div className="mt-5 px-lg-5 px-sm-0">
        <h3>The Ins Of Partnering With Us!</h3>
        <p style={{ fontSize: "1.2rem" }}>
          Our SEO strategies bring outcomes. What are those outcomes? What do I
          get after partnering with them? In case you have these questions in
          mind, we have created a list talking about the benefits you can reap
          after availing of our services. Have a great read!
        </p>
      </div>

      <div class="container mt-5 d-flex flex-row seo-services-usa-style">
        <div class="col-md-6">
          <img
            class="img-fluid"
            src="/img/myseo.jpg"
            alt="SEO Services Image"
          />
        </div>

        <div class="column col-md-6">
          <div class="">
            <div class="d-flex flex-column">
              <h5>1. S.M.A.R.T Goal Setting And Execution</h5>
              <p style={{ fontSize: "1.2rem" }}>
                With us you get a really impactful execution of your website
                digital marketing endeavor. In this direction, we use the
                S.M.A.R.T. goal-setting method to drive the campaign to the
                pinnacle of success in the given amount of time.{" "}
              </p>
            </div>
          </div>
          <div class=" mt-3">
            <div class="d-flex flex-column">
              <h5>2. Targeted Approach</h5>
              <p style={{ fontSize: "1.2rem" }}>
                We understand that every business's needs are unique, with
                different target audiences. Hence, we are always adept at
                introducing targeted solutions through our local SEO services.
                In this direction, we limit your website reach to your target
                audience, driving actions.{" "}
              </p>
            </div>
          </div>
          <div class=" mt-3">
            <div class="d-flex flex-column">
              <h5>3. Flexible And Scalable</h5>
              <p style={{ fontSize: "1.2rem" }}>
                Our reach is wide enough to capture every requirement of your
                website that may come under our SEO services responsibilities.
                Here, our only goal is to improve your website performance using
                our expertise. Besides, we never shy away from giving extended
                support in times of need.
              </p>
            </div>
          </div>
          <div class=" mt-3">
            <div class="d-flex flex-column">
              <h5>4. Affordable</h5>
              <p style={{ fontSize: "1.2rem" }}>
                Every business comes with a set budget. We understand that you
                might also have one. Fret not; we have a curated list of
                services most likely to fall under your business budget.
                Affordability, along with quality, is what makes us different
                from other digital marketing agencies.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentServices2;
