import React, { useState, useEffect } from "react";

const initData = {
  heading: "Tech-Enabled Result-Driven Digital Marketing Agency ",
  content:
    "We make sure your money is well spent and the campaigns elevate your brand to the next level.",
  // heroThumb_1: "/img/342-400.png",
  // heroThumb_2: "/img/131-300.png",
  // heroThumb_3: "/img/139-300.png",
  // heroThumb_4: "/img/150-112.png",
  // heroThumb_5: "/img/150-90.png",
  // heroThumb_6: "/img/60-63.png",
  btnText_1: "Start a Project",
  btnText_2: "Get Your Free Proposal",
};

const HeroOne = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(initData);
  }, []);

  return (
    <section
      id="home"
      className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
    >
      <div className="video-background">
        <video autoPlay loop muted className="video-bg">
          <source src="/img/backVideo1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div
          className="row align-items-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className="col-12 col-md-7"
            style={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <div className="welcome-intro">
              <h1 className="text-white">{data.heading}</h1>
              <p className="text-white my-4">{data.content}</p>
              <div className="button-group">
                <a
                  href="/free-consultation"
                  className="btn btn-bordered-white mb-2"
                >
                  {data.btnText_1}
                </a>
                <a
                  href="/contact"
                  className="btn btn-bordered-white d-sm-inline-block"
                >
                  {data.btnText_2}
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-12 col-md-5">
                        
                        <div className="welcome-thumb-wrapper mt-5 mt-md-0">
                            <span className="welcome-thumb-1">
                                <img className="welcome-animation d-block ml-auto" src={data.heroThumb_1} alt="" />
                            </span>
                            <span className="welcome-thumb-2">
                                <img className="welcome-animation d-block" src={data.heroThumb_2} alt="" />
                            </span>
                            <span className="welcome-thumb-3">
                                <img className="welcome-animation d-block" src={data.heroThumb_3} alt="" />
                            </span>
                            <span className="welcome-thumb-4">
                                <img className="welcome-animation d-block" src={data.heroThumb_4} alt="" />
                            </span>
                            <span className="welcome-thumb-5">
                                <img className="welcome-animation d-block" src={data.heroThumb_5} alt="" />
                            </span>
                            <span className="welcome-thumb-6">
                                <img className="welcome-animation d-block" src={data.heroThumb_6} alt="" />
                            </span>
                        </div>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroOne;
