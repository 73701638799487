import React from "react";
import { MdArrowForwardIos } from "react-icons/md";
import sidebarData from "../../data/db.json"; // Importing the data directly from db.json
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaSearch,
  FaShoppingCart,
  FaMapMarkerAlt,
  FaWordpress,
  FaMobileAlt,
  FaShoppingBag,
  FaMoneyBill,
  FaGoogle,
  FaFileAlt,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa"; // Import icons from react-icons library

const Sidebar = ({ pageName }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const questionAnswers = Object.values(sidebarData[pageName]).filter(
    (item) => typeof item === "object" && item.hasOwnProperty("question")
  );
  const moreResources = sidebarData[pageName]?.sidebar["more-resourses"]; // Corrected accessing more resources data

  //  ***** for the redirecting *****

  const handleServiceClick = (path) => {
    window.location.href = `/${path}`;
  };
  const selectIcon = (index) => {
    const icons = [
      FaSearch,
      FaShoppingCart,
      FaMapMarkerAlt,
      FaWordpress,
      FaMobileAlt,
      FaShoppingBag,
      FaMoneyBill,
      FaGoogle,
      FaFileAlt,
      FaFacebook,
      FaLinkedin,
      FaInstagram,
    ];
    return icons[index % icons.length]; // Loop through icons if more services than icons
  };

  return (
    <aside className="sidebar mb-5 mb-lg-0">
      {sidebarData && (
        <>
          {/* <div
            className="single-widget pt-4"
          >
            <div className="widget catagory-widget">
              <h5 className="text-uppercase d-block py-3">Our Services</h5>
              <div className="widget-content">
                <ul className="widget-items">
                  {sidebarData[pageName].sidebar["our-services"].map(
                    (service, index) => (
                      <li key={index}>
                        <a href="/#" className="d-flex py-3">
                          <span>{service}</span>
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div> */}

          {/* ***** Changed One *****  */}

          <div
            className="single-widget pt-4"
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="widget catagory-widget">
              <h5
                className="text-uppercase d-block py-3"
                style={{
                  background: "linear-gradient(135deg, #8cdfee, #1579b4)",
                  color: "#ffffff",
                  textAlign: "left",
                  margin: "0",
                  fontWeight: "bold",
                  paddingLeft: "15px",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                Our Services
              </h5>
              <div className="widget-content">
                <ul
                  className="widget-items list-unstyled mb-0"
                  style={{ padding: "0" }}
                >
                  {sidebarData[pageName].sidebar["our-services"].map(
                    (service, index) => (
                      <li
                        key={index}
                        style={{
                          listStyleType: "none",
                          padding: "12px 12px",
                          borderBottom: "1px solid #eee",
                          backgroundColor: "rgba(255, 255, 255, 0.9)",
                          transition: "background-color 0.3s, transform 0.3s",
                          color: "#333",
                          cursor: "pointer",
                          borderRadius: "8px",
                          position: "relative",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "#f0f0f0";
                          e.currentTarget.style.transform = "scale(1.02)";
                          e.currentTarget.style.color = "#1579b4";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor =
                            "rgba(255, 255, 255, 0.9)";
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.color = "#333";
                        }}
                        onClick={() => handleServiceClick(service.path)}
                      >
                        <span
                          style={{ fontWeight: "500", marginRight: "10px" }}
                        >
                          {service.name}
                        </span>
                        {/* Design element 2 for service - Icon */}
                        <div
                          className="service-icon"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            fontSize: "20px",
                            color: "#1579b4",
                          }}
                        >
                          {React.createElement(selectIcon(index))}
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="single-widget">
            <div className="widget post-widget">
              <h5 className="text-uppercase d-block py-3">More Resources</h5>
              <div className="widget-content">
                <ul className="widget-items">
                  {moreResources.map((resource, index) => (
                    <li key={index}>
                      <a href="/#" className="single-post media py-3">
                        <div>
                          <p
                            style={{
                              color: "#3cb0e9 ",
                              fontWeight: 900,
                              marginBottom: "1rem",
                            }}
                          >
                            {resource.title}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{resource["more-res-date"]}</div>
                            <div style={{ color: "#ffa737" }}>
                              {" "}
                              <span>Read More</span>
                              <MdArrowForwardIos />
                            </div>
                          </div>
                          <hr />
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="single-widget">
            <div className="widget tags-widget">
              <h5 className="text-uppercase d-block py-3">Contents</h5>
              <ul>
                {sidebarData[pageName].sidebar.contents.map(
                  (content, index) => (
                    <li key={index}>
                      <a
                        href="/#"
                        className="d-inline-block mt-2 mr-1 px-2 py-1"
                      >
                        {content}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </>
      )}
    </aside>
  );
};

export default Sidebar;
